<!-- page title -->
<h1>Offer Tag Management</h1>

<form [formGroup]="filtersForm" novalidate>
    <!-- actions bar -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
        <button *ngIf="searchInput" type="button" mat-raised-button color="accent" matTooltip="Add new record" [routerLink]="[0]" [queryParams]="{search: searchInput.value ? searchInput.value : null}">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Offer Tag
        </button>

        <button type="button"
        *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0"
        mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
        <mat-icon>more_vert</mat-icon>Actions
      </button>
      <mat-menu #menu="matMenu">
        <button type="button" mat-menu-item [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
        <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
        <button type="button" mat-menu-item (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>          
        <button type="button" mat-menu-item>
                {{getSelectedSectionRecords().length}}
            Selected</button>           
      </mat-menu>
      <mat-menu #Download="matMenu">
        <button mat-menu-item (click)="downloadJSON()">JSON</button>
        <button mat-menu-item (click)="downloadCSV()">CSV</button>
      </mat-menu>

      
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search" #searchInput />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
            (click)="clearSearch()">
            <mat-icon>close</mat-icon>
        </button>
        </mat-form-field>
    </div>

    <!--table view-->
    <ng-container *ngIf="viewModel$ | async as viewModel">
        <table mat-table [dataSource]="viewModel.offerTags" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" *ngIf="viewModel.offerTags.length">
     <!-- Checkbox Column -->
     <ng-container matColumnDef="select">
        <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
                    Id
                </th>
                <td mat-cell *matCellDef="let record">{{ record.Id }}</td>
            </ng-container>
    
            <!-- OfferTagName Column -->
            <ng-container matColumnDef="OfferTagName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
                    Name
                </th>
                <td mat-cell *matCellDef="let record"> {{ record.OfferTagName }}</td>
            </ng-container>
    
            <!-- ClientKey Column -->
            <ng-container matColumnDef="ClientKey">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey">
                    Client Key
                </th>
                <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
            </ng-container>
    
            <!-- Actions Column -->
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let record">
                    <button type="button" mat-button color="accent" [routerLink]="[record.Id]" [queryParams]="{search: searchInput.value ? searchInput.value : null}">Edit</button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    
        <table *ngIf="!viewModel.offerTags.length">
            <tr>
                <td class="no-records" [attr.colspan]="displayedColumns.length">
                    <h2>No Records Found</h2>
                </td>
            </tr>
        </table>
    
        <!-- pager -->
        <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
            [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offerTags.length"
            showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
        </mat-paginator>
    
    </ng-container>

</form>
