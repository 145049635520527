import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { EventWorkflowService } from '../../../../services/event-workflow.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-workflow-users',
  templateUrl: './add-workflow-users.component.html',
  styleUrls: ['./add-workflow-users.component.scss'],
})
export class AddWorkflowUsersComponent implements OnInit {
  dataSource: any;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'user_role',
    'user_status',
  ];
  subscription$ = new Subscription();

  constructor(
    private ewService: EventWorkflowService,
  ) {}

  ngOnInit(): void {
    this.dataSource = this.getDataSource();
    this.getUsers();
  }

  getUsers() {
    this.ewService.getUsersList().subscribe((userData: any) => {
      this.dataSource = userData;
    });
  }

  onSearch(e: any) {
    console.log(`onsearch: `, e);
  }

  clearSearch() {}

  getDataSource() {
    return [
      {
        first_name: '',
        last_name: '',
        user_role: '',
        user_status: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        user_status: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email_alerts: '',
      },
      {
        first_name: '',
        last_name: '',
        user_role: '',
        email_alerts: '',
      },
    ];
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource?.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected?.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onSave() {
    this.ewService.selectedUsersToAddToWorkflow.next(this.selection.selected);
  }
}
