import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { Logger } from 'src/app/@shared/services/logger.service';
import { ProductDomain, Product, ProductService, OfferProductService, OfferService, Offer, OfferDomain } from 'src/app/modules/standard/v1';
import { ApiValidation, DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('OfferProductUpdateRankComponent');

@Component({
  selector: 'app-offer-product-update-rank',
  templateUrl: './offer-product-update-rank.component.html',
  styleUrls: ['./offer-product-update-rank.component.scss']
})
export class OfferProductUpdateRankComponent<T extends Product, TProductDomain extends ProductDomain<T>, TOffer extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit {
  offerId: any;
  productId: any;
  product$: Observable<T> = of({} as T);
  productDomain$: Observable<any> = of({} as any);
  productName: string = "";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService<T>,
    private offerProductService: OfferProductService<T>,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService,
    private offerService: OfferService<TOffer, TOfferDomain>) { }

  formGroup = new FormGroup({
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(100), Validators.pattern("[a-zA-Z0-9 \\-_#&*\\'/]*")]),
      Id: new FormControl<string>('0', { nonNullable: true }),
      ProductName: new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(100), Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/.,;]*")]),
    }),
    ProductCategoryIds: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    ProductBrandId: new FormControl<string | null>('', [Validators.minLength(1), Validators.maxLength(100)]),
    Rank: new FormControl<number | null>(null, [Validators.min(1), Validators.max(9999999)]),
  });

  ngOnInit(): void {
    log.debug('init');

    this.offerId = this.route.snapshot.params['offerId'];
    this.productId = this.route.snapshot.params['productId'];

    if (this.productId && this.productId !== '0') {
      this.productDomain$ = this.productService.getByOfferIdProductId(this.offerId, this.productId).pipe(
        tap((productDomain) => {
          this.formGroup.patchValue(productDomain);
          this.formGroup.markAllAsTouched();
        })
      );
    }
  }
  
  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  save(): void {
    this.trimControlValues(this.formGroup);
    
    if (this.formGroup.valid) {
      const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerId];
      this.productService.updateOfferHasProductRank(offers, this.formGroup.getRawValue() as TProductDomain).subscribe({
        next: (response) => {
          this.matSnackBar.open(
            `${this.formGroup.controls.Detail.controls.ProductName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.offerProductService.reload();
          this.router.navigate([`../../`, 'products'], { relativeTo: this.route });
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            apiValidations.forEach((validation) => {
              if (this.formGroup.get(validation.PropertyName)) {
                const control = this.formGroup.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          }
        }
      });
    }
  }

  cancel(): void {
    this.formGroup.reset();
    this.offerProductService.reload();
    this.router.navigate([`../../`, 'products'], { relativeTo: this.route });
  }

  isAdmin(): boolean {
    const hasAccess = this.authorizationService.checkAccess('admin||offermang');
    return hasAccess;
  }
}
