import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, Subscription, combineLatest, map } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowService implements OnDestroy {
  public eventName = 'Event Name';
  public templatesSelected = new Subject();
  public authToken = '';
  public headersWithBearerToken: any;
  public organizationsList: any;
  public selectedUsersToAddToWorkflow = new Subject();
  public subscription$ = new Subscription();

  constructor(private httpClient: HttpClient, private auth: AuthService) {
    this.subscription$.add(
      this.auth.getAccessTokenSilently().subscribe((token: any) => {
        this.authToken = token;
        this.headersWithBearerToken = {
          Authorization: `Bearer ${token}`,
        };

        this.createAPICallsWithHeaders();
        this.getOrganizations();
      })
    );
  }

  private masterTemplateAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Workflow/Standard/V1/MasterTemplate/GetMasterTemplate`;

  private getWorkflowAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Workflow/Standard/V1/MasterTemplate/GetWorkflow`;

  private getEndTriggerEndpointAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Admin/Standard/V1/EndTriggers/GetEndTrigger`;

  private getStartTriggerAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Admin/Standard/V1/StartTriggers/GetStartTrigger`;

  private getUserTaskEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Admin/Standard/V1/UserTasks/GetUserTask`;

  private getWorkflowTypeAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Admin/Standard/V1/WorkflowType/GetWorkflowType`;

  private getEmailAlertAPIEndpoint = `https://pr1-std-workflow-${env.environment}-portal-api-app.azurewebsites.net/Private-Admin/Standard/V1/EmailAlerts/GetEmailAlert`;

  private getUsersAPIEndpoint = `https://pr1-std-sysadmin-${
    env.environment
  }-portal-api-app.azurewebsites.net/standard/V1/AuthUsers/GetOrganizationUsers?orgId=${localStorage.getItem(
    'orgId'
  )}`;

  private getOrganizationsAPIEndpoint = `https://pr1-std-sysadmin-${env.environment}-portal-api-app.azurewebsites.net/Private/Standard/V1/Organizations/GetOrganizations`;

  private startingTrigger$: any;
  private endingTrigger$: any;
  private usersTask$: any;
  private workflowType$: any;
  private emailAlert$: any;
  private getUser$: any;
  private getOrganization$: any;

  createAPICallsWithHeaders() {
    this.startingTrigger$ = this.httpClient.get(
      this.getStartTriggerAPIEndpoint,
      { headers: this.headersWithBearerToken }
    );
    this.endingTrigger$ = this.httpClient.get(
      this.getEndTriggerEndpointAPIEndpoint,
      { headers: this.headersWithBearerToken }
    );
    this.usersTask$ = this.httpClient.get(this.getUserTaskEndpoint, {
      headers: this.headersWithBearerToken,
    });
    this.workflowType$ = this.httpClient.get(this.getWorkflowTypeAPIEndpoint, {
      headers: this.headersWithBearerToken,
    });
    this.emailAlert$ = this.httpClient.get(this.getEmailAlertAPIEndpoint, {
      headers: this.headersWithBearerToken,
    });
    this.getUser$ = this.httpClient.get(this.getUsersAPIEndpoint, {
      headers: this.headersWithBearerToken,
    });
    this.getOrganization$ = this.httpClient.get(
      this.getOrganizationsAPIEndpoint,
      {
        headers: this.headersWithBearerToken,
      }
    );
  }

  public getMasterTemplate(token: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.httpClient.get(this.masterTemplateAPIEndpoint, {
      headers,
    });
  }

  public getWorkflow(token: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return this.httpClient
      .get(this.getWorkflowAPIEndpoint, {
        headers,
      })
      .pipe(
        map((data: any) => {
          const wd = data[0];
          const mappedData = {
            template_info: {
              template_name: wd.TemplateName,
              template_owner: wd.UserId,
              event_type: wd.WorkflowType,
              channel: wd.Channel,
            },
            workflow_config: ['ATL', 'Onsite'],
            workflow_stages: wd.stages.map((s: any) => {
              return {
                stage_name: s.name,
                stage_description: '',
                starting_trigger: s.stageStartTrigger,
                ending_trigger: s.stageEndTrigger,
                deadline: '',
                users: s.users.map((u: any) => ({
                  first_name: u.userID,
                  last_name: u.userID,
                  task: u.TaskID,
                  email_alerts: u.AlertID,
                })),
              };
            }),
          };

          return mappedData;
        })
      );
  }

  public getRefDataForEditWorkflowControls() {
    return combineLatest([this.startingTrigger$, this.endingTrigger$]).pipe(
      map((data: any) => {
        let startTriggers = data[0]?.value?.map((s: any) => ({
          ...s,
          label: s.StartTriggerName,
          value: s.StartTriggerName,
        }));

        let endTriggers = data[1]?.value?.map((e: any) => ({
          ...e,
          label: e.EndTriggerName,
          value: e.EndTriggerName,
        }));

        return {
          startTriggers,
          endTriggers,
        };
      })
    );
  }

  public getRefDataForWorkflowUsersTable() {
    return combineLatest([this.usersTask$, this.emailAlert$]).pipe(
      map((data: any) => {
        let tasks = data[0].value;
        let emailAlerts = data[1].value;

        tasks = tasks.map((t: any) => ({
          ...t,
          label: t.UserTaskName,
          value: t.UserTaskName,
        }));

        emailAlerts = emailAlerts.map((e: any) => ({
          ...e,
          label: e.EmailAlertName,
          value: e.EmailAlertName,
        }));

        return {
          tasks,
          emailAlerts,
        };
      })
    );
  }

  getOrganizations() {
    this.subscription$.add(
      this.getOrganization$
        .pipe(
          map((resp: any) => {
            let respArr = resp.value;
            return respArr.map((o: any) => ({
              id: o.Id,
              display_name: o.ShortName,
              name: o.OrganizationName,
            }));
          })
        )
        .subscribe((resp: any) => (this.organizationsList = resp))
    );
  }

  public getUsersList() {
    let orgId;
    if (!this.organizationsList || this.organizationsList.length == 0) {
      orgId = '1b03a728-721b-4734-98e3-c950c21f7c9b';
    } else {
      orgId = this.organizationsList[0]?.id;

      if (localStorage.getItem('organization')) {
        const i = (this.organizationsList as any[]).findIndex((o) =>
          (o.display_name as string)
            .toLowerCase()
            .trim()
            .includes(
              localStorage.getItem('organization')!.toLowerCase().trim()
            )
        );
        orgId = this.organizationsList[i]?.id;
      }
    }

    return this.httpClient
      .get(
        `https://pr1-std-sysadmin-${env.environment}-portal-api-app.azurewebsites.net/Private/standard/V1/AuthUsers/GetOrganizationUsers?orgId=${orgId}`,
        {
          headers: this.headersWithBearerToken,
        }
      )
      .pipe(
        map((userData: any) => {
          if ('value' in userData) {
            userData = userData['value'];
          }
          return (userData as any[]).map((u) => ({
            first_name: u.FirstName,
            last_name: u.LastName,
            user_role: u.Roles,
            user_status: u.Active ? 'Active' : 'Not Active',
          }));
        })
      );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
