<ng-container *ngIf="viewModel$ | async as viewModel">

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
    <h2>Select Promo</h2>

  </div>
  <div class="grid-container">
    <table *ngIf="viewModel.offers.length" mat-table [dataSource]="viewModel.offers" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">

        <!-- Id Column
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
                Id
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
        </ng-container> -->

        <!-- PromoName Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
                Promo Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail?.Name }}</td>
        </ng-container>

        <!-- Headline Column -->
        <ng-container matColumnDef="Headline">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline">
                Headline
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail?.Headline }}</td>
        </ng-container>

        <!-- BodyCopy Column -->
        <ng-container matColumnDef="BodyCopy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy">
                Body Copy
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail?.BodyCopy }}</td>
        </ng-container>


        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let record">
              <mat-radio-button value="{{record.selected}}" (change)="updateSelectedPromo(record)"></mat-radio-button>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.offers.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
  </div>
    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

    <mat-card-actions fxLayout="row" fxLayoutAlign="end">

        <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
            <button mat-stroked-button type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button type="button" color="accent" (click)="saveData()">
                Select
            </button>
        </div>
    </mat-card-actions>

</ng-container>
