import { Component, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Asset, AssetsService } from 'src/app/modules/standard/v1';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('AssetListTableComponent');

@Component({
  selector: 'app-asset-list-table',
  templateUrl: './asset-list-table.component.html',
  styleUrls: ['./asset-list-table.component.scss']
})

export class AssetListTableComponent<T extends Asset> implements OnInit {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.assetsService.assets$,
    this.assetsService.isLoading$,
    this.assetsService.totalRecords$,
    this.assetsService.page$,
    this.organizationService.assetsUrl$
  ]).pipe(
    map(([assets, isLoading, totalRecords, page, assetsUrl]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.assetsService.page(page);
        this.assetsService.reload();
      }
      this.assetsUrl = assetsUrl;
      return { assets, isLoading, totalRecords, page, assetsUrl }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'Image', 'AssetName', 'FileName', 'Type', 'Group', 'DateAdded', 'Actions'];
  assetsList: Asset[] = [];
  selection = new SelectionModel<Asset>(true, []);

  constructor(private mediaObserver: MediaObserver,
    private assetsService: AssetsService<Asset>, private organizationService: OrganizationService) { }

  ngOnInit(): void {
    log.debug('init');
    this.assetsService.assets$.subscribe((data) => {
      this.assetsList = data;
    })
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Group', 'DateAdded', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'FileName', 'Type', 'Group', 'DateAdded', 'Actions'];
        }
      });
  }

  onSort(sortState: Sort): void {
    this.assetsService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.assetsService.page(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = this.assetsUrl + '/' + assets.Detail.FileName;
    }
    return imagesrc;
  }
}
