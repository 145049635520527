import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { EventAssetsService, Offer, OfferAsset, OfferDomain, OfferService, ProductAssetsService } from '../..';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('ProductAssetRankComponent');

interface DialogData {
  offerName: string;
}

@Component({
  selector: 'app-clone-offer',
  templateUrl: './clone-offer.component.html',
  styleUrls: ['./clone-offer.component.scss']
})

export class CloneOfferComponent<T extends Offer, TOfferDomain extends OfferDomain<Offer>,> implements OnInit {

  offer$: Observable<OfferDomain<T>> = of({} as OfferDomain<T>);
  offerName : string = '';
  formGroup = new FormGroup({
    Detail: new FormGroup({
      Name: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    })
    
  });
  private eventId: any = '';
  private assetId: string = '';

  constructor(private eventassetService: EventAssetsService<OfferAsset>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private offerService: OfferService<T, TOfferDomain>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authorizationService: AuthorizeControlService,
    private dialogRef: MatDialogRef<CloneOfferComponent<Offer,OfferDomain<Offer>>>,) { }

  ngOnInit(): void {
    log.debug('init');
    this.eventId = this.route.snapshot.params['eventId'];
    this.assetId = this.route.snapshot.params['assetId'];
    this.eventassetService.eventId = this.eventId;
    this.formGroup.controls['Detail'].controls['Name'].patchValue(this.data.offerName + ' Copy') ;
    this.offerName = this.data.offerName;
  }
 

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  saveData() {
    this.trimControlValues(this.formGroup);
    this.dialogRef.close(this.formGroup.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
