import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { OrganizationService } from '../..';

export interface MediaPreviewDialogData {
  name: string;
  url: string;
  type: string;
}

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit {
  // assetsUrl$: Observable<string> = this.organizationService.assetsUrl$;
  storageUrl: string = '';
  assetUrl: string = '';
  assetType: string = '';
  assetExtenstion: string = '';

  constructor(
    private organizationService: OrganizationService,
    private dialogRef: MatDialogRef<MediaPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaPreviewDialogData,
  ) { }

  ngOnInit(): void {
    this.organizationService.assetsUrl$.subscribe(storageUrl => {
      this.assetUrl = storageUrl + '/' + this.data.url;

      if (this.assetUrl && this.data.url && this.data.type) {
        this.assetExtenstion = this.data.type.split('/')[1];

        if (this.data.type.indexOf('pdf') > -1) {
          this.assetType = 'pdf';
          window.open(this.assetUrl, '_blank');
          this.dialogRef.close();
        } else if (this.data.type.indexOf('audio') > -1) {
          this.assetType = 'audio';
        } else if (this.data.type.indexOf('video') > -1) {
          this.assetType = 'video';
        } else if (this.data.type.indexOf('text') > -1) {
          this.assetType = 'text';
          window.open(this.assetUrl, '_blank');
          this.dialogRef.close();
        } else if (this.data.type.indexOf('image') > -1) {
          this.assetType = 'image';
        }
      } else {
        this.assetType = '';
      }
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
