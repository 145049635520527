import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { EventWorkflowService } from '../../../services/event-workflow.service';
import { EventTypeService } from '../../../services/event-type.service';
import { AuthService } from '@auth0/auth0-angular';
import { EventType } from '../../../models/event-type.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-workflow',
  templateUrl: './add-workflow.component.html',
  styleUrls: ['./add-workflow.component.scss'],
})
export class AddWorkflowComponent<
  TEvent extends Event,
  TEventType extends EventType
> implements OnInit, OnDestroy
{
  displayedColumns: string[] = [
    'select',
    'templateName',
    'eventType',
    'channel',
  ];
  dataSource: any = [];
  showAddTemplateView = false;
  selection = new SelectionModel<any>(true, []);
  masterDataSource: any;
  @Output() templatesSelected = new EventEmitter<any>();
  subscription$ = new Subscription();

  constructor(
    public ewService: EventWorkflowService,
    public eventTypeService: EventTypeService<TEventType>,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getAuthTokenAndGetMasterTemplate();
  }

  getAuthTokenAndGetMasterTemplate() {
    this.subscription$.add(
      this.auth.getAccessTokenSilently().subscribe((token: any) => {
        this.getMasterTemplate(token);
      })
    );
  }

  getMasterTemplate(token: any) {
    this.subscription$.add(
      this.ewService
        .getMasterTemplate(token as string)
        .subscribe((data: any) => {
          this.dataSource = data.map((d: any) => {
            return {
              templateName: d.TemplateName,
              eventType: d.WorkflowType ? d.WorkflowType : '-',
              channel: d.Channel ? d.Channel : '-',
              user: d.UserId,
            };
          });
          this.masterDataSource = this.dataSource;
        })
    );
  }

  onSearch(data: any) {
    const keyword = data.srcElement.value;

    // this.umService.search(event.target.value);
    this.dataSource = this.masterDataSource.filter((x: any) => {
      const keys = Object.keys(x);
      for (let key of keys) {
        if (x[key]?.toLowerCase()?.includes(keyword?.toLowerCase()?.trim())) {
          return true;
        }
      }
      return false;
    });
  }

  clearSearch() {
    // this.userManagementForm.controls.search.setValue('');
    // this.umService.search('');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onSave() {
    this.ewService.templatesSelected.next(this.selection.selected);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
