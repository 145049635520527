import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { Organization } from "..";

export const DEFAULT_PAGING = {
    previousPageIndex: 0,
    pageIndex: 0,
    pageSize: 10,
    length: 0,
};

export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration: 3000,
};

export const ORGANIZATIONS: Organization[] = [
    {
        id: '10c6c737-3dfd-4bb7-8975-2cc3b775cfec',
        name: 'Kroger',
        apiPath: 'Kroger',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp2",
    },
    {
        id: '9b066a1f-635a-43f5-a46e-e55906236388',
        name: 'RiteAid',
        apiPath: 'RiteAid',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp3",
    },
    {
        id: '2f8a9839-112b-48b1-9e20-fd2149a137cf',
        name: 'Standard',
        apiPath: 'Standard',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp1",
    },
    {
        id: '74c9060d-73da-41db-abdd-959cc8c64c74',
        name: 'Walgreens',
        apiPath: 'Walgreens',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp4",
    },
    {
        id: '4a5fdaa8-65f6-45de-a84e-d656ba707713',
        name: 'Demo',
        apiPath: 'Demo',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp5",
    }, {
        id: '4e38d38b-b87d-47df-892d-9053c7966415',
        name: 'QaAutomation',
        apiPath: 'QaAutomation',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp6",
    }, {
        id: 'edc55570-5be0-4c9a-88b5-82bba2faad11',
        name: 'UNFI',
        apiPath: 'UNFI',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp6",
    }, {
        id: '3a190d37-5ffe-4753-bffe-70576bc3064c',
        name: 'Shopify',
        apiPath: 'Shopify',
        version: "v1",
        dwa_api_key: "pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp7",
    },
];
