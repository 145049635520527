import { Injectable } from '@angular/core';
import { Product, ProductDomain } from "..";
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})

export class CouponProductService<T extends Product> {

  // initialize behavior subjects
  private productCouponIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private notCouponProductPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private dialogNotCouponProductPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private notCouponProductLoadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private dialogsortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private notCouponProductReloadBehaviorSubject = new BehaviorSubject<string>('');
  private dialogNotCouponProductReloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public notCouponProductPage$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public dialogsort$ = this.dialogsortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public notCouponProductisLoading$ = this.notCouponProductLoadingBehaviorSubject.asObservable();
  public dialogNotCouponProductisLoading$ = this.dialogNotCouponProductReloadBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public productCouponId$ = this.productCouponIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private dialog: MatDialog) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.productCouponIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([productCouponId, page, sort, search, reload]) => {

      let orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'Rank') {
        orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        $expand: 'Detail',
        couponId: this.productCouponIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: orderby,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogParams$ = combineLatest([
    this.dialogNotCouponProductPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.dialogsortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.dialogNotCouponProductReloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      let orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'Rank') {
        orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        $expand: 'Detail',
        couponId: this.productCouponIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: orderby,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set couponId(productCouponId: string) {
    this.productCouponIdBehaviorSubject.next(productCouponId);
  }

  //get the list of products by couponId
  private couponProductsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/ByCoupon`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //get the list of all products (not based on particular OfferId)
  private allProductsCouponResponse$ = this.dialogParams$.pipe(
    tap(() => this.notCouponProductLoadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>{
      if(_params.has('$search')){
      return this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/SearchWithoutCouponAssociation`,
        { params: _params })
      } else {
        return of([])
      }
    }),
    tap(() => this.notCouponProductLoadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  associateProductToCoupon(couponId : string, product: ProductDomain<T>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/AssociateToCoupon?couponId=${couponId}`;
    return this.httpClient.post(url, product);
  }

  prepareCouponIdString(couponIds: any) {
    let urlParams = '';
    if (couponIds && couponIds.length > 0) {
      for (let index = 0; index <= couponIds.length - 1; index++) {
        urlParams += `couponIds=${couponIds[index]}`;
        if (index != couponIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  disassociateProductToCoupon(couponIds:string,  products: string[]) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/DisAssociateFromCoupon?couponId=${couponIds}`;
    return this.httpClient.post(url, products);
  }

  UpdateCouponAssociationRank(couponId: string, productId: string, rank: number) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/UpdateCouponAssociationRank?couponId=${couponId}&productId=${productId}&rank=${+rank}`;
    return this.httpClient.post(url, {});
  }

  //products listing by couponId
  public products$: Observable<ProductDomain<T>[]> = this.couponProductsResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

  public notOfferProduct$: Observable<ProductDomain<T>[]> = this.allProductsCouponResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

  public totalRecords$: Observable<number> = this.couponProductsResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );

  public totalProductRecords$: Observable<number> = this.allProductsCouponResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );
  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }
  // set the current page
  notCouponProductPage(page: any) {
    this.dialogNotCouponProductPageBehaviorSubject.next(page);
  }
  // reset the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }
  // reset the current page
  notOfferProductResetpage() {
    this.notCouponProductPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the coupon listing
  reload() {
    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }
  // reloads/refreshes the coupon listing
  notOfferProductReload() {
    // reload the Offer data
    this.notCouponProductReloadBehaviorSubject.next(uuidv4());
  }

  // changes the view mode of the products listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  public notCouponProduct$: Observable<ProductDomain<T>[]> = this.allProductsCouponResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

}
