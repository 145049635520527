import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Offer, OfferAsset, OfferAssetsService, OfferDomain, OfferService } from 'src/app/modules/standard/v1';

const log = new Logger('AssetRankComponent');

@Component({
  selector: 'app-asset-rank',
  templateUrl: './asset-rank.component.html',
  styleUrls: ['./asset-rank.component.scss']
})

export class AssetRankComponent<TOffer extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit {
  offer$: Observable<OfferAsset> = of({} as OfferAsset);
  formGroup = new FormGroup({
    Rank: new FormControl<number | null | undefined>(null, [Validators.min(1), Validators.max(99)]),
    AssetName: new FormControl<string | null>(null)
  });
  private offerId: string = '';
  private assetId: string = '';
  constructor(private offerAssetService: OfferAssetsService<OfferAsset>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private offerService: OfferService<TOffer, TOfferDomain>) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerId = this.route.snapshot.params['offerId'];
    this.assetId = this.route.snapshot.params['assetId'];
    this.offerAssetService.offerId = this.offerId;
    this.getRankandAssetData();
  }

  getRankandAssetData() {
    this.offerAssetService.assets$.subscribe(res => {
      if (res && res.length > 0) {
        const assetRecor = res.find(x => x.Detail.Id === this.assetId);
        if (assetRecor) {
          this.formGroup?.patchValue(assetRecor);
          this.formGroup.markAllAsTouched();
        }
      }
    });
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }

  saveData() {
    this.trimControlValues(this.formGroup);
    const datatoSave = {
      Detail: {
        Id: this.assetId
      },
      //OfferId: this.offerId,
      Rank: this.formGroup.controls.Rank.value
    }
    const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerId];
    this.offerAssetService.saveRank(offers, datatoSave).subscribe(res => {
      this.matSnackBar.open(
        ` ${this.formGroup.controls.AssetName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
      );
      this.offerAssetService.reload();
      this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    });

  }
  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route })
  }

}
