<ng-container *ngIf="viewModel$ | async as viewModel">

  <!-- actions bar -->

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
  <h2>Select Product Brand</h2>

</div>
<div class="grid-container">

<table *ngIf="viewModel.allProductBrands.length" mat-table [dataSource]="viewModel.allProductBrands" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'"  class="mat-elevation-z8">

  <!-- ProductName Column -->
  <ng-container matColumnDef="ProductBrandName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
         Product Brand Name
      </th>
      <td mat-cell *matCellDef="let record"> {{ record.Detail?.ProductBrandName }}</td>
  </ng-container>


     <!-- Actions Column -->
<ng-container matColumnDef="Actions">
  <th mat-header-cell *matHeaderCellDef></th>
  <td mat-cell *matCellDef="let record">
    <mat-radio-button value="{{record.selected}}" (change)="updateSelectedProductBrand(record)"></mat-radio-button>
  </td>
</ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<table *ngIf="!viewModel.allProductBrands.length">
  <tr>
      <td class="no-records" [attr.colspan]="displayedColumns.length">
          <h2>No Records Found</h2>
      </td>
  </tr>
</table>
</div>
<!-- pager -->
<mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
  [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.allProductBrands.length"
  showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
</mat-paginator>

<mat-card-actions fxLayout="row" fxLayoutAlign="end">

  <div>
      <button mat-stroked-button type="button" (click)="closeDialog()">
          Cancel
      </button>
      <button mat-raised-button type="button" color="accent" (click)="saveData()">
          Select
      </button>
  </div>
</mat-card-actions>

</ng-container>
