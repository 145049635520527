<div id="users-table">
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 user-management-table"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td class="no-ellipsis" mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>

    <!-- Task Column -->
    <ng-container matColumnDef="task">
      <th mat-header-cell *matHeaderCellDef>Task</th>
      <td mat-cell *matCellDef="let element">
        <!-- Actions drop down -->
        <button
          class="user-status-toggle-button"
          mat-raised-button
          [matMenuTriggerFor]="taskMenu"
        >
          {{ element.task }}
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #taskMenu="matMenu" name="task" ngDefaultControl>
          <button
            mat-menu-item
            *ngFor="let task of tasksRefData"
            (click)="onTaskSelect(task.label, element)"
          >
            {{ task.label }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Email Alerts Column -->
    <ng-container matColumnDef="email_alerts">
      <th mat-header-cell *matHeaderCellDef>Email Alerts</th>
      <td mat-cell *matCellDef="let element">
        <!-- Actions drop down -->
        <button
          class="user-status-toggle-button"
          mat-raised-button
          [matMenuTriggerFor]="emailAlertsMenu"
        >
          {{ element.email_alerts }}
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu
          #emailAlertsMenu="matMenu"
          name="emailAlerts"
          ngDefaultControl
        >
          <button
            mat-menu-item
            *ngFor="let emailAlert of emailAlertsRefData"
            (click)="onEmailAlertSelect(emailAlert.label, element)"
          >
            {{ emailAlert.label }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Edit Link Column -->
    <ng-container matColumnDef="edit_user">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="32px"
          class="user-table-actions"
        >
          <!-- Edit Link -->
          <div>
            <a
              [routerLink]="['/standard/user-management/edit', element.user_id]"
              >Edit</a
            >
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
