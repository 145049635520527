<ng-container *ngIf="viewModel$ | async as viewModel">
  <lib-header (toggleSidenav)="sidenav.toggle()"></lib-header>

  <mat-sidenav-container color="primary">
    <!--fullscreen-->
    <mat-sidenav #sidenav="matSidenav" opened="true" mode="side" class="sidenav" >
      <div class="coredata"><fa-icon [icon]="['fas', 'gear']"></fa-icon> {{appKey}}</div>
      <mat-nav-list>

        <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase()]"
          (click)="isMobile && sidenav.close()" data-testid="Dashboard">
          <fa-icon [icon]="['fas', 'gauge']"></fa-icon>Dashboard
        </a>

        <mat-expansion-panel [class.mat-elevation-z0]="true">
          <mat-expansion-panel-header data-testid="Events">
            <fa-icon [icon]="['fas', 'calendar']"></fa-icon>Events
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item  appAuthorizeControl="view||navbar"   [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/events']"
              (click)="isMobile && sidenav.close()">
              <fa-icon [icon]="['fas', 'calendar']" data-testid="Event Sub-option"></fa-icon>Events
            </a>

            <a mat-list-item  appAuthorizeControl="edit||navbar"   [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/offertags']"
              (click)="isMobile && sidenav.close()" data-testid = "Offer Tags">
              <fa-icon [icon]="['fas', 'tags']"></fa-icon>Offer Tags
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel [class.mat-elevation-z0]="true" appAuthorizeControl="admin||navbar">
          <mat-expansion-panel-header data-testid="Coupons"><fa-icon [icon]="['fas', 'money-check-dollar']" style="margin-right: 10px;"></fa-icon>Coupons
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/coupons']"
            (click)="isMobile && sidenav.close()"   appAuthorizeControl="admin||navbar" data-testid="Coupons">
            <fa-icon [icon]="['fas', 'money-check-dollar']"></fa-icon>Coupons
           </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/shoppers']"
            (click)="isMobile && sidenav.close()"   appAuthorizeControl="admin||navbar" data-testid="Shoppers">
            <mat-icon>shopping_basket</mat-icon> &nbsp; Shoppers
           </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel [class.mat-elevation-z0]="true">
          <mat-expansion-panel-header data-testid="Channels" appAuthorizeControl="view||navbar">
            <fa-icon [icon]="['fas', 'rocket']"></fa-icon>Channels
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/digitalads']"
              (click)="isMobile && sidenav.close()">
              <fa-icon [icon]="['fas', 'tachograph-digital']" data-testid="Digital Ads"></fa-icon>Digital Ads
            </a>
          </mat-nav-list>
        </mat-expansion-panel> -->

        <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/assets']"
         (click)="isMobile && sidenav.close()"   appAuthorizeControl="edit||navbar" data-testid="Assets">
          <fa-icon [icon]="['fas', 'image']"></fa-icon>Assets
        </a>

        <mat-expansion-panel [class.mat-elevation-z0]="true" appAuthorizeControl="admin||navbar">
          <mat-expansion-panel-header data-testid="Products">
            <fa-icon [icon]="['fas', 'barcode']"></fa-icon>Products
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/products']"
              (click)="isMobile && sidenav.close()" data-testid="Products Sub-option">
              <fa-icon [icon]="['fas', 'barcode']"></fa-icon>Products
            </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/product-brands']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid="Brands">
              <fa-icon [icon]="['fas', 'trademark']"></fa-icon>Brands
            </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/product-categories']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid = "Categories">
              <fa-icon [icon]="['fas', 'folder-tree']"></fa-icon>Categories
            </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/vendors']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid = "Vendors">
              <fa-icon [icon]="['fas', 'users']"></fa-icon>Vendors
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel [class.mat-elevation-z0]="true" appAuthorizeControl="admin||navbar">
          <mat-expansion-panel-header  data-testid="Organization">
            <fa-icon [icon]="['fas', 'building']" style="margin-right: 15px;"></fa-icon>Organization
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/divisions']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid="Divisions">
              <fa-icon [icon]="['fas', 'chart-pie']"></fa-icon>Divisions
            </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/eventtypes']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid="Event Types">
              <fa-icon [icon]="['fas', 'receipt']"></fa-icon>Event Types
            </a>
            <a mat-list-item [routerLink]="[viewModel.organization?.name?.toLowerCase() + '/stores']"
              (click)="isMobile && sidenav.close()" appAuthorizeControl="admin||navbar" data-testid="Stores">
              <fa-icon [icon]="['fas', 'shop']"></fa-icon>Stores
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        

        

      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-content-container">
        <div class="breadcrumbs" fxHide.xs>
          <xng-breadcrumb></xng-breadcrumb>
        </div>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-spinner-overlay></app-spinner-overlay>
</ng-container>
