import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap, map, Subject, debounceTime } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  Logger,
  ApiValidation,
  ConfirmDialogComponent,
  OrganizationService,
} from 'src/app/@shared';
import {
  Offer,
  OfferDomain,
  OfferService,
  OfferTag,
  OfferTagService,
  OfferPromoService,
  OfferPromo,
  PromoDomain,
  AssetTagService,
  AssetTag,
  AssetGroup,
  EventService,
  Event,
  EventDomain,
} from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as dayjs from 'dayjs';
import {  } from 'src/app/@shared/services/authorization.service';
import { AuthorizeControlService } from 'pr1-ui-components';
import { CouponService } from '../../services/coupon.service';
import { Coupon } from '../../models/coupon.model';
import { AuthService } from '@auth0/auth0-angular';
import { CouponCodeType } from '../../models/coupon-code-type';

const log = new Logger('CouponDetailsComponent');


@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon.details.component.html',
  styleUrls: ['./coupon.details.component.scss'],
})

export class CouponDetailsComponent<
  T extends Offer,
  Tag extends OfferTag,
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy {
  coupon$: Observable<Coupon> = of({} as Coupon);
  assetsUrl$ = this.organizationService.assetsUrl$;
  assetsforBadge: any;
  assetGroups = [];
  offertypes: any;
  noneValue = null;
  offerTags$: Observable<Tag[]> = this.offerTagService.OfferTagsList$.pipe(
    map((tags) => {
      return tags.filter((tag) => {
        const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);
        return index < 0;
      });
    })
  );
  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/]*"),
  ]);
  OfferTagIds: Tag[] = [];
  couponCodeType = CouponCodeType 
  
  formGroup = new FormGroup({
    Detail: new FormGroup({
      Id: new FormControl<string>(''),
      CouponType: new FormControl<string>('', [
        Validators.required
      ]),
      CouponValue: new FormControl<number>(0, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(10),
      ]),
      Summary: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Description: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      typeOfCode: new FormControl<any>(null),
      ActiveDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      ExpiryDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      RedemptionStartDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      PurchaseUnit: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      RewardUnit: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      ActivationLimit: new FormControl<number>(0, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      FinePrint: new FormControl<string>(''),
      Disclaimer: new FormControl<string>(''),
      FeaturedText: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.required
      ]),
      TargetType: new FormControl<string | null>(null),
      DiscountType: new FormControl<string>('', [
        Validators.required
      ]),
      PurchaseConditions: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      RewardConditions: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      CouponID: new FormControl<string>(''),
      CouponSource: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      IsAutoActivated: new FormControl<boolean>(false, [Validators.required]),
      IsVisible: new FormControl<boolean>(false, [Validators.required]),
      HasExtendedRequirements: new FormControl<boolean>(false, [Validators.required]),
      ClientKey: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Status: new FormControl<string>('Pending'),
      UpdatedOn: new FormControl<string | Date | null>(null),
      PreferredCouponCodeType: new FormControl(CouponCodeType.BarCode)
    }),
  });
  offerId: string = '0';
  eventId!: string;
  maxDate: Date = new Date(9999, 0, 1);
  buttonClicked = new Subject();
  private offerTagsList: Tag[] = [];
  public couponResponse: any;
  offerpromosList: TPromoDomain[] = [];
  versionsCount: number = 0;
  user: any;
  couponId: string = '0'

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public offerService: OfferService<T, TOfferDomain>,
    private offerTagService: OfferTagService<Tag>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private eventService: EventService<TEvent>,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private dialog: MatDialog,
    private authorizationService: AuthorizeControlService,
    private cdRef: ChangeDetectorRef,
    private couponService: CouponService<Coupon>,
    private authService: AuthService
  ) {
    this.formGroup.valueChanges.subscribe(res => {
      console.log(res);
    })
   }

  ngOnInit(): void {
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.couponService.reload();

    this.route.params.subscribe((params) => {
      // get the offer id from the parent route
      this.couponId = params['couponId'];
      this.getDataById()
      this.offerService.saveorCancel$.subscribe((res) => {
        if (res === '1') {
          this.save();
        } else if (res === '0') {
          this.cancel();
        }
      });
    });

    const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(1000));
    buttonClickedDebounced.subscribe(() => {
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  navigateChildOffers(){
    this.router.navigate([`../`, `childversions`], { relativeTo: this.route });
  }

  getDataById() {
    if (this.couponId && this.couponId !== '0') {
      this.OfferTagIds = [];
      this.coupon$ = this.couponService.getCoupon(this.couponId).pipe(
        tap((coupon) => {
          this.couponService.currentRecord = JSON.parse(JSON.stringify(coupon));
          if (coupon && coupon.Detail && coupon.Detail.ActiveDate) {
            coupon.Detail.ActiveDate = new Date(coupon.Detail.ActiveDate);
          }
          if (coupon && coupon.Detail && coupon.Detail.ExpiryDate) {
            coupon.Detail.ExpiryDate = new Date(coupon.Detail.ExpiryDate);
          }
          if (coupon && coupon.Detail && coupon.Detail.UpdatedOn) {
            coupon.Detail.UpdatedOn = new Date(coupon.Detail.UpdatedOn);
          }
          if (coupon && coupon.Detail && coupon.Detail.RedemptionStartDate) {
            coupon.Detail.RedemptionStartDate = new Date(coupon.Detail.RedemptionStartDate);
          }
          this.formGroup.patchValue(coupon);
          this.formGroup.controls.Detail.controls.CouponID.setValue(coupon.Detail.Id)
          this.formGroup.markAllAsTouched();
          this.couponResponse = coupon;
        })
      );
    }
  }

  save() {
    this.buttonClicked.next('');
    const request = this.formGroup.value;
    if(request?.Detail){
      const { UpdatedOn, ActiveDate, ExpiryDate, RedemptionStartDate } = request.Detail;
      request.Detail.UpdatedOn = dayjs(UpdatedOn).format('MM/DD/YYYY');
      request.Detail.ActiveDate = dayjs(ActiveDate).format('MM/DD/YYYY');
      request.Detail.ExpiryDate = dayjs(ExpiryDate).format('MM/DD/YYYY');
      request.Detail.RedemptionStartDate = dayjs(RedemptionStartDate).format('MM/DD/YYYY');
    }
    if(!request.Detail?.Id) {
      delete request?.Detail?.UpdatedOn;
    }
    delete request.Detail?.CouponID;
    delete request.Detail?.typeOfCode;
    this.couponService.saveCoupon(this.formGroup.value as any).subscribe({
      next: response => {
        if (response) {
          const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Saved Successfully',
              //message: 'Saved Successfully',
              confirmText: 'Continue Editing',
              cancelText: 'Back to Coupons',
            },
            disableClose: true,
          });
          confirmDialog.afterClosed().subscribe((confirmResult) => {
            if (confirmResult) {
              this.router
                .navigate([`../../${response.DomainId}/details`], {
                  relativeTo: this.route,
                })
                .then((x) => {
                  this.reloadCurrentRoute();
                });
            } else {
              this.couponService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            }
          });
        }
      },
      error: error => {
        if (error.status === 500) {
          log.error('500 Error saving coupon', error);
        }
        if (error.status === 400) {
          const apiValidations: any = error.error;
          if (Array.isArray(apiValidations)) {
            apiValidations.forEach((validation: any) => {
              if (this.formGroup?.get(validation.PropertyName)) {
                const control = this.formGroup?.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                  this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          } else {
            this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
          }


        }
      }
    })
  }

  clearNulls(offer: OfferDomain<T>): any {
    if (offer.Detail.AdRetailPrice == null) offer.Detail.AdRetailPrice = -1;
    if (offer.Detail.SalePrice == null) offer.Detail.SalePrice = -1;
    if (offer.Detail.Quantity == null) offer.Detail.Quantity = -1;
    if (offer.Detail.PercentOff == null) offer.Detail.PercentOff = -1;
    if (offer.Detail.DollarOff == null) offer.Detail.DollarOff = -1;
    if (offer.Detail.BuyQuantity == null) offer.Detail.BuyQuantity = -1;
    if (offer.Detail.GetQuantity == null) offer.Detail.GetQuantity = -1;
    if (offer.Detail.WhenYouBuy == null) offer.Detail.WhenYouBuy = -1;
    if (offer.Detail.SaveAmount == null) offer.Detail.SaveAmount = -1;

    return offer;
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    });
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  updateStatus(status: string) {
    this.formGroup.controls.Detail.controls.Status.setValue(status);
    this.couponService.updateStatus(status, [this.couponId]).subscribe(res => {
        this.couponService.updateRecordStatus(new Date());
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  cancel(): void {
    if (this.formGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Close without saving?',
          message: 'This offer has unsaved changes',
          confirmText: 'Continue Editing',
          cancelText: 'Close',
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (!confirmResult) {
          this.formGroup.reset();
          this.couponService.reload();
          this.router.navigate([`../../`], { relativeTo: this.route });
        }
      });
    } else {
      this.formGroup.reset();
      this.couponService.reload();
      this.router.navigate([`../../`], { relativeTo: this.route });
    }
  }

  deleteRecord(versionName?: string) {
    if (versionName == 'Base') return;
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.CouponValue}?`,
      },
      disableClose: true,
    });
  }

  deleteBaseRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Summary}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.couponService
          .deleteCoupon(this.couponId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Summary} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.couponService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting offer', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    if (
      this.OfferTagIds &&
      this.OfferTagIds.length > 0 &&
      this.OfferTagIds.includes(event.option.value)
    ) {
      return;
    }
    this.OfferTagIds.push(event.option.value);
    this.tagCtrl.setValue('');
    this.offerTags$ = this.offerTags$.pipe(
      map((tags) => {
        return tags.filter((tag) => {
          const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);
          return index < 0;
        });
      })
    );
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    if (hasAccess) {
      this.tagCtrl.enable();
    } else {
      this.tagCtrl.disable();
    }
    return hasAccess;
  }

  getUpdatedRecordInfo(record: any) {
    const objecttoPass: any = {
      Properties: {}
    };
    const currentOfferInfo = this.offerService.getCurrentRecord;
    if (currentOfferInfo) {
      const properties = Object.keys(record);
      for (let index = 0; index <= properties.length - 1; index++) {
        if(record[properties[index]] != null && record[properties[index]] != undefined) {
          if (typeof (record[properties[index]]) === 'object' && !Array.isArray(record[properties[index]])) {
            const childProperties = Object.keys(record[properties[index]]);
            if (childProperties && childProperties.length > 0) {
              const currentRecord = currentOfferInfo[properties[index]];
              const updatedRecord = record[properties[index]];
              for(let childIndex = 0; childIndex <= childProperties.length - 1; childIndex++) {
                if (currentRecord[childProperties[childIndex]] !== updatedRecord[childProperties[childIndex]] && updatedRecord[childProperties[childIndex]] != null && updatedRecord[childProperties[childIndex]] != undefined) {
                  objecttoPass['Properties'][childProperties[childIndex]] = updatedRecord[childProperties[childIndex]];
                }
              }
            }
          } else {
            if (currentOfferInfo[properties[index]] !== record[properties[index]] && record[properties[index]] != null && record[properties[index]] != undefined) {
              objecttoPass[properties[index]] = record[properties[index]];
            }
          }
        }
      }
    }
    return objecttoPass;
  }

  ngOnDestroy() { }
}
