

<form *ngIf="productDomain$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">

            <h2>{{formGroup.controls.ProductName.value == '' ? "Product" : formGroup.controls.ProductName.value}}</h2>

        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
            <mat-card appearance="outlined" fxFlex="20" fxFlexFill fxLayoutAlign="center">
                <!-- <img *ngIf="T.FileName" src="{{assetsUrl$ | async}}/{{T.FileName}}" class="product-image" alt="product image">
                <img *ngIf="!T.FileName" src="../.././assets/images/default-image.png" class="product-image" alt="product image"> -->
                <ng-container *ngIf="T.FileName && assetsUrl$ | async as assetsUrl">
                    <img mat-card-md-image [src]="getImageSrc(assetsUrl, T)" alt="product image">
                </ng-container>
                <img mat-card-md-image *ngIf="!T.FileName"
                    src="./assets/images/default-image.png" alt="product image">
            </mat-card>
            <div fxLayout="column" fxFlex="auto" class="marginTop">
              <div fxLayout="row" fxLayoutGap.gt-sm="16px">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Product Name</mat-label>
                    <input matInput type="text" formControlName="ProductName" />
                    <mat-error *ngIf="formGroup.controls.ProductName.hasError('required')">
                        Product Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.ProductName.status=='INVALID'">
                        {{formGroup.controls.ProductName.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.ProductName.errors!['maxlength']['requiredLength']+'. '}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Product Display Name</mat-label>
                    <input matInput type="text" formControlName="ProductDisplayName" />
                    <mat-error *ngIf="formGroup.controls.ProductDisplayName.status=='INVALID'">
                        {{formGroup.controls.ProductDisplayName.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.ProductDisplayName.errors!['maxlength']['requiredLength']+'. '}}
                    </mat-error>
                </mat-form-field>
             </div>
                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Product Description</mat-label>
                    <input matInput type="text" formControlName="ProductDescription" />
                    <mat-error *ngIf="formGroup.controls.ProductDescription.status=='INVALID'">
                        {{formGroup.controls.ProductDescription.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.ProductDescription.errors!['maxlength']['requiredLength']+'. '}}
                    </mat-error>
                </mat-form-field>

            </div>
        </div>
            <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                 <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Brand</mat-label>
                    <mat-select formControlName="ProductBrandId" [(value)]="brand">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let brand of productBrands$" [value]="brand.Id">
                            {{brand.ProductBrandName}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="ProductCategoryIds" [(value)]="category">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let category of productCategories$" [value]="category.Id">
                            {{category.ProductCategoryName}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>UPC</mat-label>
                    <input matInput type="text" formControlName="UPC" />
                    <mat-error *ngIf="formGroup.controls.UPC.status=='INVALID'">
                        {{formGroup.controls.UPC.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.UPC.errors!['maxlength']['requiredLength']+'. '}}
                        {{formGroup.controls.UPC.errors!['pattern'] && 'UPC may only contain integers. '}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>SKU</mat-label>
                    <input matInput type="text" formControlName="SKU" />
                    <mat-error *ngIf="formGroup.controls.SKU.status=='INVALID'">
                        {{formGroup.controls.SKU.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.SKU.errors!['maxlength']['requiredLength']+'. '}}
                        {{formGroup.controls.SKU.errors!['pattern'] && 'SKU may only contain alphanumeric and .,;-#&*\'/ characters. '}}
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" fxFlex="32" *ngIf="productId != '0'">
                    <mat-label>Id</mat-label>
                    <input matInput formControlName="Id" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" />
                    <mat-error *ngIf="formGroup.controls.ClientKey.hasError('required')">
                        Client Key is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.ClientKey.status=='INVALID'">
                        {{formGroup.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.ClientKey.errors!['maxlength']['requiredLength']+'. '}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32">
                    <mat-label>Vendor</mat-label>
                    <mat-select formControlName="VendorId" [(value)]="vendor">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let vendor of vendors$" [value]="vendor.Id">
                            {{vendor.VendorName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="productId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="productId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="reset" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>

    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
