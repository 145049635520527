import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Offer, OfferAsset, OfferDomain, OfferService, PromoAssetsService } from '../..';

const log = new Logger('PromoAssetRankComponent');

@Component({
  selector: 'app-promo-asset-rank',
  templateUrl: './promo-asset-rank.component.html',
  styleUrls: ['./promo-asset-rank.component.scss']
})

export class PromoAssetRankComponent<T extends Offer, TOfferDomain extends OfferDomain<Offer>> implements OnInit {
  asset$: Observable<OfferAsset> = of({} as OfferAsset);
  formGroup = new FormGroup({
    Rank: new FormControl<number | null | undefined>(null, [Validators.min(1), Validators.max(99)]),
    AssetName: new FormControl<string | null>(null)
  });
  private promoId: string = '';
  private assetId: string = '';
  constructor(private promoAssetService: PromoAssetsService<OfferAsset>, private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private offerService: OfferService<T, TOfferDomain>

  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.promoId = this.route.snapshot.params['offerId'];
    this.assetId = this.route.snapshot.params['assetId'];
    this.promoAssetService.promoId = this.promoId;
    this.getRankandAssetData();
  }

  getRankandAssetData() {
    this.promoAssetService.assets$.subscribe(res => {
      if(res && res.length > 0) {
        const assetRecor = res.find(x => x.Detail.Id === this.assetId);
        if(assetRecor) {
          this.formGroup?.patchValue(assetRecor);
          this.formGroup.markAllAsTouched();
        }
      }
    });
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    })
  }
  
  saveData() {
    this.trimControlValues(this.formGroup);
    
    const datatoSave = {
      Detail: {
        Id: this.assetId
      },
      //PromoId: (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.promoId],
      Rank: this.formGroup.controls.Rank.value
    }
    const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.promoId];
    this.promoAssetService.saveRank(offers, datatoSave).subscribe(res => {
      this.matSnackBar.open(
        ` ${this.formGroup.controls.AssetName.value} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
      );
      this.promoAssetService.reload();
      this.router.navigate([`../../`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    });

  }

  cancel(): void {
    this.formGroup.reset();
    this.router.navigate([`../../`], { relativeTo: this.route })

  }

}
