import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, map } from 'rxjs';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { AssetGroup, AssetTag, AssetDomain, Asset, AssetTagService, AssetsDomainService, AssetsService } from '../..';
import * as dayjs from 'dayjs';

const log = new Logger('AssetsComponent');

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})

export class AssetsComponent<T extends Asset> implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('assetsTable', { static: false }) assetsTable: any;
  @ViewChild('assetsCards', { static: false }) assetsCards: any;

  maxDate: Date = new Date(9999, 0, 1);

  viewModel$ = combineLatest([
    this.assetsService.isLoading$,
    this.assetsService.viewMode$,
    this.assetsService.filters$,
    this.assetsService.getRecordsForFilters(),    
    this.assetTagService.assetGroups$
  ]).pipe(
    map(([isLoading, viewMode,filters, assetsFilters, assetGroups]) => {
      return { isLoading, viewMode, filters, assetsFilters,  assetGroups }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
    groupName: new FormControl(),
    type: new FormControl(),
    startDate: new FormControl<string | null>(null),
    endDate: new FormControl<string | null>(null),
  });

  constructor(
    private assetsService: AssetsService<Asset>,
    private assetDomainService: AssetsDomainService<AssetDomain>,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.assetsService.reload();
  }

  onSearch(event: any) {
    this.assetsService.search(event.target.value);
  }

  onDateRangeChange() {
    // start date
    if (this.filtersForm.controls.startDate.valid && this.filtersForm.controls.startDate.value) {
      this.assetsService.removeFilterByFieldName('startDate');
      const startDateFilter: Filter = { displayText: 'Date Uploaded', fieldName: 'startDate', value: dayjs(this.filtersForm.controls.startDate.value).format('YYYY-MM-DD') };
      this.assetsService.addFilters([startDateFilter]);

      // end date
      if (this.filtersForm.controls.endDate.valid
        && this.filtersForm.controls.endDate.value
        && new Date(dayjs(this.filtersForm.controls.startDate.value).format('YYYY-MM-DD')) <= new Date(dayjs(this.filtersForm.controls.endDate.value).format('YYYY-MM-DD'))) {
        this.assetsService.removeFilterByFieldName('endDate');
        const endDateFilter: Filter = { displayText: 'Date Uploaded', fieldName: 'endDate', value: dayjs(this.filtersForm.controls.endDate.value).format('YYYY-MM-DD') };
        this.assetsService.addFilters([endDateFilter]);
      }
    }
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.assetsService.search('');
  }

  toggleView(mode: string) {
    this.assetsService.toggleViewMode(mode);
  }

  removeFilter(filter: Filter) {
    if (filter.fieldName === 'type') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        type: this.filtersForm.controls.type.value.filter((item: T) => item.Detail.Id!== filter.value)
      });
    }
    this.assetsService.removeFilter(filter);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.assetsService.clearFilters();
  }

  onFilterChange(event: any) {
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'groupname':
        const groupsValues: AssetGroup[] = event.value;
        this.assetsService.removeFilterByFieldName('assetGroupName');
        const assetGroupsFilters: Filter[] = [];
        groupsValues.forEach(value => {
          const assetGroupsFilter: Filter = { displayText: value.AssetGroupName, fieldName: 'assetGroupName', value: value.Id };
          assetGroupsFilters.push(assetGroupsFilter)
        });
        this.assetsService.addFilters(assetGroupsFilters);
        break;
      case 'type':
        const typeValues: T[] = event.value;
        this.assetsService.removeFilterByFieldName('type');
        const typeFilters: Filter[] = [];
        typeValues.forEach((valueInfo: any) => {
          const typeFilter: Filter = { displayText: valueInfo, fieldName: 'type', value: valueInfo };
          typeFilters.push(typeFilter)
        });
        this.assetsService.addFilters(typeFilters);
        break;
    }
  }

  resetFilters() {
    this.filtersForm.reset();
    this.assetsService.search('')
    this.assetsService.clearFilters();
    this.sidenav.toggle();
  }

  getDistinctRecords(list: any) {
    const allAges = list.value;
    const uniqueSet = new Set(allAges)
    return [...uniqueSet]
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.assetsTable) {
      selectedRecords = this.assetsTable.getSelectedSectionRecords();
    } else if (this.assetsCards) {
      selectedRecords = this.assetsCards.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Asset?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.assetDomainService.deleteAssets(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Asset(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.assetsService.reload();
                if (this.assetsTable) {
                  this.assetsTable.clearSelection();
                } else if (this.assetsCards) {
                  this.assetsCards.clearSelection();
                }
              },
              error: (error) => {
                log.error('Error in deleting asset', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.assetsService.downloadAssetsJSON();
  }

  downloadCSV(){
    this.assetsService.downloadAssetsCSV();
  }
}
