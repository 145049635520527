import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Logger, ApiValidation } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Asset, AssetsService, AssetGroup, AssetTag, AssetTagService, UploadAssetsProductsComponent, UploadAssetsOffersComponent, UploadAssetsEventsComponent, UploadAssetsPromosComponent } from '../..';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpEventType } from '@angular/common/http';
import { UploadAssetsProductBrandsComponent } from '../../components/upload-assets-product-brands/upload-assets-product-brands.component';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('UploadAssetsComponent');
export interface Tag {
  name: string;
}
@Component({
  selector: 'app-upload-assets',
  templateUrl: './upload-assets.component.html',
  styleUrls: ['./upload-assets.component.scss']
})

export class UploadAssetsComponent<Tag extends AssetTag, Group extends AssetGroup> implements OnInit {
  assetTags$: Observable<AssetTag[]> = this.assetTagService.assetTags$.pipe(map(tags => {
    return tags.filter(tag => {
      const index = this.AssetTagIds.findIndex(_tag => _tag.Id == tag.Id);
      return index < 0
    })
  }));
  assetGroups$: AssetGroup[] = [];
  AssetTagIds: Tag[] = [];
  selectedEvent: any;
  selectedOffer: any;
  selectedProduct: any;
  selectedProductBrand: any;
  selectedPromo: any;
  showDone!: boolean;
  showButtons = true;
  assetTagsList: Tag[] = [];
  viewModel$ = combineLatest([
    this.assetsService.assets$,
  ]).pipe(
    map(([assets]) => {
      return {
        assets
      }
    }),
  );
  addOnBlur = true;
  name!: string;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tags: Tag[] = [];
  uploadAssets: any;
  tagCtrl = new FormControl<string>('', [Validators.minLength(1), Validators.maxLength(50), Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/]*")]);
  selectedFiles: any[] = [];
  selectedGroupName!: AssetGroup;
  progressInfos: any[] = [];
  uploadStatuses: boolean[] = [];

  formGroup = new FormGroup({
    groupName: new FormControl(null, [Validators.required]),
    event: new FormControl(''),
    offer: new FormControl(''),
    product: new FormControl(''),
    productbrand: new FormControl(''),
    promo: new FormControl('')
  });

  constructor(private assetsService: AssetsService<Asset>, private assetTagService: AssetTagService<Tag, AssetGroup>, private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService) { }

  ngOnInit(): void {
    log.debug('init');
    this.assetTagService.assetTags$.subscribe(assetTags => {
      this.assetTagsList = assetTags;
    });
    this.assetTagService.assetGroups$.subscribe({
      next: (data) => {
        let assetGroupTemp: any = [];
        const keys = ['products', 'badges', 'brands'];
        data?.forEach((d) => {
          if (keys.includes(d?.AssetGroupName?.toLowerCase())) {
            if (this.isAdmin()) {
              assetGroupTemp.push(d);
            }
          } else {
            assetGroupTemp.push(d);
          }
        });
        this.assetGroups$ = assetGroupTemp;
      },
    });
    this.formGroup.controls.groupName.valueChanges.subscribe(res => {
      if (res) {
        this.selectedGroupName = res;
        this.updateMandFields(res);
      }
    });

    this.formGroup.controls.event.valueChanges.subscribe(res => {
      this.selectedOffer = null;
      this.formGroup.controls.offer.reset();
      this.formGroup.controls.offer.setValidators([Validators.required]);
    });

    this.formGroup.controls.event.valueChanges.subscribe(res => {
      this.selectedPromo = null;
      this.formGroup.controls.promo.reset();
      this.formGroup.controls.promo.setValidators([Validators.required]);
    });
  }


  private updateMandFields(groupValue: Group) {
    this.selectedEvent = null;
    this.selectedOffer = null;
    this.selectedProduct = null;
    this.selectedProductBrand = null;
    this.selectedPromo = null;
    if (groupValue?.AssetGroupName == 'Common' || groupValue?.AssetGroupName == 'Badges') {
      this.formGroup.controls.event.reset();
      this.formGroup.controls.event.clearValidators();
      this.formGroup.controls.event.patchValue('');
      this.formGroup.controls.offer.reset();
      this.formGroup.controls.offer.clearValidators();
      this.formGroup.controls.offer.patchValue('');
      this.formGroup.controls.product.reset();
      this.formGroup.controls.product.clearValidators();
      this.formGroup.controls.product.patchValue('');
      this.formGroup.controls.promo.reset();
      this.formGroup.controls.promo.clearValidators();
      this.formGroup.controls.promo.patchValue('');
      this.formGroup.controls.productbrand.clearValidators();
      this.formGroup.controls.productbrand.patchValue('');
    } else if (groupValue?.AssetGroupName == 'Events') {
      this.formGroup.controls.event.reset();
      this.formGroup.controls.event.setValidators([Validators.required]);
      this.formGroup.controls.product.clearValidators();
      this.formGroup.controls.product.patchValue('');
      this.formGroup.controls.offer.clearValidators();
      this.formGroup.controls.offer.patchValue('');
      this.formGroup.controls.promo.clearValidators();
      this.formGroup.controls.promo.patchValue('');
      this.formGroup.controls.productbrand.clearValidators();
      this.formGroup.controls.productbrand.patchValue('');
    } else if (groupValue?.AssetGroupName == 'Offers') {
      this.formGroup.controls.event.reset();
      this.formGroup.controls.event.setValidators([Validators.required]);
      this.formGroup.controls.offer.reset();
      this.formGroup.controls.offer.setValidators([Validators.required]);
      this.formGroup.controls.product.clearValidators();
      this.formGroup.controls.product.patchValue('');
      this.formGroup.controls.promo.clearValidators();
      this.formGroup.controls.promo.patchValue('');
      this.formGroup.controls.productbrand.clearValidators();
      this.formGroup.controls.productbrand.patchValue('');
    } else if (groupValue?.AssetGroupName == 'Promos') {
      this.formGroup.controls.event.reset();
      this.formGroup.controls.event.setValidators([Validators.required]);
      this.formGroup.controls.promo.reset();
      this.formGroup.controls.promo.setValidators([Validators.required]);
      this.formGroup.controls.product.clearValidators();
      this.formGroup.controls.product.patchValue('');
      this.formGroup.controls.offer.clearValidators();
      this.formGroup.controls.offer.patchValue('');
      this.formGroup.controls.productbrand.clearValidators();
      this.formGroup.controls.productbrand.patchValue('');
    } else if (groupValue?.AssetGroupName == 'Products') {
      this.formGroup.controls.product.setValidators([Validators.required]);
      this.formGroup.controls.event.clearValidators();
      this.formGroup.controls.event.patchValue('');
      this.formGroup.controls.offer.clearValidators();
      this.formGroup.controls.offer.patchValue('');
      this.formGroup.controls.promo.clearValidators();
      this.formGroup.controls.promo.patchValue('');
      this.formGroup.controls.productbrand.clearValidators();
      this.formGroup.controls.productbrand.patchValue('');
    }
    else if  (groupValue?.AssetGroupName == 'Brands') {
      this.formGroup.controls.productbrand.setValidators([Validators.required]);
      this.formGroup.controls.product.clearValidators();
      this.formGroup.controls.product.patchValue('');
      this.formGroup.controls.event.clearValidators();
      this.formGroup.controls.event.patchValue('');
      this.formGroup.controls.offer.clearValidators();
      this.formGroup.controls.offer.patchValue('');
      this.formGroup.controls.promo.clearValidators();
      this.formGroup.controls.promo.patchValue('');
    }
    this.formGroup.markAllAsTouched();
  }

  openProductsDialog(): void {
    const dialogRef = this.dialog.open(UploadAssetsProductsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.selectedProduct }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formGroup.controls.product.patchValue(res.Detail?.ProductName);
        this.formGroup.markAllAsTouched();
        this.selectedProduct = res;
      }
    });
  }

  openProductBrandsDialog(): void {
    const dialogRef = this.dialog.open(UploadAssetsProductBrandsComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.selectedProductBrand }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        console.log(res);

        this.formGroup.controls.productbrand.patchValue(res.Detail?.ProductBrandName);
        this.formGroup.markAllAsTouched();
        this.selectedProductBrand = res;
      }
    });
  }
  openOffersDialog(): void {
    const dialogRef = this.dialog.open(UploadAssetsOffersComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.selectedEvent }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formGroup.controls.offer.patchValue(res.Detail?.Name);
        this.formGroup.markAllAsTouched();
        this.selectedOffer = res;
      }
    });
  }

  openPromosDialog(): void {
    const dialogRef = this.dialog.open(UploadAssetsPromosComponent, {
      width: '60%',
      height: '90%',
      data: { event: this.selectedEvent }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formGroup.controls.promo.patchValue(res.Detail?.Name);
        this.formGroup.markAllAsTouched();
        this.selectedPromo = res;
      }
    });
  }

  openEventsialog() {
    const dialogRef = this.dialog.open(UploadAssetsEventsComponent, {
      width: '60%',
      height: '90%',
      data: { offerId: this.route.snapshot.params['offerId'] }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formGroup.controls.event.patchValue(res.Detail?.EventName);
        this.formGroup.markAllAsTouched();
        this.selectedEvent = res;
      }
    });
  }

  uploadClose() {
    this.router.navigate([`../`], { relativeTo: this.route });
  }

  done() {
    this.assetsService.reload();
    this.router.navigate([`../`], { relativeTo: this.route });

  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.AssetTagIds && this.AssetTagIds.length > 0 && this.AssetTagIds.includes(event.option.value)) {
      return;
    }
    this.AssetTagIds.push(event.option.value);
    this.tagCtrl.setValue('');
    this.assetTags$ = this.assetTags$.pipe(map(tags => {
      return tags.filter(tag => {
        const index = this.AssetTagIds.findIndex(_tag => _tag.Id == tag.Id);
        return index < 0
      })
    }));
  }

  addTag(event: MatChipInputEvent): void {
    event.chipInput!.clear();
    const value = (event.value || '').trim();
    let valid = this.tagCtrl.status !== "INVALID";

    // Add Tag
    if (value && valid) {
      let tag = <Tag>{
        ClientKey: value,
        AssetTagName: value,
      };

      this.saveTag(tag);
    }
  }

  saveTag(tag: Tag) {
    if (!this.assetTagsList) this.assetTagsList = [];
    const tagInfo = this.assetTagsList.find(x => x.AssetTagName && tag.AssetTagName && x.AssetTagName.toLowerCase() === tag.AssetTagName.toLowerCase());
    if (tagInfo ) {
      if (!this.AssetTagIds.find(x => x.AssetTagName == tagInfo.AssetTagName)) {
        this.AssetTagIds.push(tagInfo);
      }
    } else {
      this.assetTagService.saveAssetTag(tag as Tag).subscribe({
        next: (response) => {
          this.AssetTagIds.push(response as Tag);
          this.matSnackBar.open(
            `${tag.AssetTagName} saved`, 'OK', DEFAULT_SNACKBAR_CONFIG
          );
          this.assetTagService.reload();
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            apiValidations.forEach((validation) => {
              if (this.formGroup.get(validation.PropertyName)) {
                const control = this.formGroup.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                  this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          }
        }
      });
    }

  }

  removeTag(tag: Tag): void {
    const index = this.AssetTagIds.findIndex(_tag => _tag.Id == tag.Id);

    if (index >= 0) {
      this.AssetTagIds.splice(index, 1);
    }
    this.assetTags$ = this.assetTags$.pipe(map(tags => {
      return tags.filter(tag => {
        const index = this.AssetTagIds.findIndex(_tag => _tag.Id == tag.Id);
        return index < 0
      })
    }));
  }

  compareGroupOption(c1: Group, c2: Group): boolean {
    return c1 && c2 ? c1.Id === c2.Id : c1 === c2;
  }

  selectFiles(event: Event): void {
    this.progressInfos = [];
    const element = event.currentTarget as HTMLInputElement;
    if (element.files) {
      for (let index = 0; index <= element.files.length - 1; index++) {
        if (element.files[index] && element.files[index].name && element.files[index].name.split('.')[0]) {
          const fileName = element.files[index].name.split('.')[0];
          // if (this.hasSpecialChars(fileName)) {
          //   this.matSnackBar.open(
          //     'Special Characters are not allowed', 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
          //   throw new Error('Special Characters are not allowed');
          // }
          if (this.isFileSizeExceeded(element.files[index])) {
            this.matSnackBar.open(
              'File Size has been exceeded', 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
            throw new Error('File Size has been exceeded');
          }
        }
      }
      this.selectedFiles = element.files as any;
    }
  }

  hasSpecialChars(data: string) {
    const specialCharsSet = new Set("!@#$%^&*();{}[]");
    for (let letter of data) {
      if (specialCharsSet.has(letter)) {
        return true;
      }
    }
    return false;
  }

  isFileSizeExceeded(file: any): boolean {
    let fileSize = file.size;
    let type = file.type;
    if(type=="video/mp4" || type == "application/x-zip-compressed")
    {
      if (fileSize > 256 * 1024*1024) {
        return true;
      }
    }
    else{
      if (fileSize > 5 * 1024 * 1024) {
      return true;
    }
  }
    return false;
  }

  uploadFiles(): void {
    if (this.formGroup.valid && this.selectedFiles) {
      this.upload();
    }
  }

  onClear() {
    this.selectedFiles = [];
    this.progressInfos = [];
    this.showButtons = true;
  }

  upload(): void {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.showDone = false;
      for (let index = 0; index <= this.selectedFiles.length - 1; index++) {
        const formGroupData: any = this.formGroup.controls.groupName.value;
        const assetTagIds = this.AssetTagIds.map(i => i.Id);
        const formData: FormData = new FormData();
        formData.append('file', this.selectedFiles[index]);
        for (let ai = 0; ai <= this.AssetTagIds.length - 1; ai++) {
          formData.append('assetTagIds', assetTagIds[ai]);
        };
        formData.append('assetGroup.assetGroupName', formGroupData?.AssetGroupName);
        formData.append('EventId', this.selectedEvent?.Detail?.Id);
        formData.append('OfferId', this.selectedOffer?.Detail?.Id);
        formData.append('ProductId', this.selectedProduct?.Detail?.Id);
        formData.append('ProductBrandId', this.selectedProductBrand?.Detail?.Id);
        formData.append('PromoId', this.selectedPromo?.Detail?.Id);
        formData.append('assetGroup.id', formGroupData?.Id);

        this.progressInfos[index] = { value: 0, fileName: this.selectedFiles[index].name, status: 'uploading' };
        if (this.selectedFiles[index]) {
          this.assetsService.uploadAsset(formData).subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progressInfos[index].value = Math.round(100 * event.loaded / event.total);

              }
              if (index === this.progressInfos.length - 1) {
                this.showDone = true;
                this.showButtons = false;
              }
              this.progressInfos[index].status = 'success';
              this.progressInfos[index].value = 100;
            },
            error: (err: any) => {
              this.progressInfos[index].value = 0;
              this.progressInfos[index].status = 'failure';
              if (err.status === 500) {
                log.error('500 Error uploading Asset', err);
              }
              if (err.status === 400) {
                const apiValidations: any = err.error;
                if (Array.isArray(apiValidations)) {
                  apiValidations.forEach((validation: any) => {
                    if (this.formGroup?.get(validation.PropertyName)) {
                      const control = this.formGroup?.get(validation.PropertyName);
                      if (control) {
                        control.markAsTouched();
                        control.setErrors({ invalid: validation.ErrorMessage });
                        this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                      }
                    } else {
                      this.matSnackBar.open(validation.ErrorMessage, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                    }
                  });
                } else {
                  this.matSnackBar.open(apiValidations, 'Error', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                }
              }
            }
          });
        }
      }
    }
  }

    
  isAdmin(): boolean {
    const hasAccess = this.authorizationService.checkAccess('admin||offermang');
    return hasAccess;
  }
}
