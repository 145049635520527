import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { FormControl, FormGroup } from '@angular/forms';
import { Store, VersionStoreService } from '../..';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DEFAULT_PAGING, DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';

const log = new Logger('VersionStoreDialogComponent');

@Component({
  selector: 'app-version-store-dialog',
  templateUrl: './version-store-dialog.component.html',
  styleUrls: ['./version-store-dialog.component.scss']
})

export class VersionStoreDialogComponent<T extends Store> implements OnInit {

  viewModel$ = combineLatest([
    this.versionStoreService.dialogstore$,
    this.versionStoreService.isLoading$,
    this.versionStoreService.dialogtotalRecords$,
    this.versionStoreService.storeDialogPage$
  ]).pipe(
    map(([stores, isLoading, totalRecords, page]) => {
      return { stores, isLoading, totalRecords, page }
    }),
  );

  storesList: T[] = [];
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'StoreName', 'ClientKey', 'Address1', 'Address2', 'City', 'State', 'Country', 'PostalCode'];
  versionId: string = '';
  selection = new SelectionModel<T>(true, []);

  constructor(
    private versionStoreService: VersionStoreService<T>,
    private mediaObserver: MediaObserver,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<VersionStoreDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.versionStoreService.reload();
    this.versionStoreService.storeDialogresetpage();
    this.dialogRef.updatePosition({
      top: `30px`,
    });
    this.versionId = this.data.versionId;
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'StoreName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'StoreName', 'ClientKey', 'City'];
        }
        else {
          this.displayedColumns = ['select', 'StoreName', 'ClientKey', 'Address1', 'Address2', 'City', 'State', 'Country', 'PostalCode'];
        }
      });
    // get and set the event id in the version service
    if (this.route.snapshot.parent) {
      this.versionStoreService.versionId = this.versionId;
    }

    this.versionStoreService.dialogstore$.subscribe((data) => {
      this.storesList = data;
    });
  }

  onSearch(event: any) {
    this.versionStoreService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionStoreService.dialogSearch('');
  }
  
  filtersForm = new FormGroup({
    search: new FormControl(),
  });

  onSort(sortState: Sort): void {
    this.versionStoreService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionStoreService.storeDialogPage(pageEvent);
  }

  associateStore() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Add',
        message: `Do you want to add to version?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe(
      confirmResult => {
        if (confirmResult) {
          this.versionStoreService.associateVersionToStore(this.versionId, this.selection.selected).subscribe({
            next: () => {
              this.matSnackBar.open(`Stores added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
              this.selection.clear();
              this.closeDialog();
              this.versionStoreService.resetpage();
              this.versionStoreService.storeDialogresetpage();
              this.versionStoreService.reload();
            },
            error: (error) => {
              if (error.status === 500) {
              log.error('Error adding store to version', error);
              }
              if (error.status === 400) {
                if(error && error.error){
                  this.matSnackBar.open(error.error.Message, 'OK', { verticalPosition: 'top', panelClass: ['snackbar-error'] });
                }                
              }
              this.selection.clear();
            }
          });
        }
      });

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.storesList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.storesList.forEach(row => this.selection.select(row));
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

interface DialogData {
  versionId: string;
}
