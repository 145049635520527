import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Organization, ORGANIZATIONS } from "..";

@Injectable({
    providedIn: 'root',
})

export class OrganizationService {
    // initialize behavior subjects
    private organizationsBehaviorSubject = new BehaviorSubject(null as Organization[] | null | undefined);
    private organizationBehaviorSubject = new BehaviorSubject(null as Organization | null | undefined);
    private assetsUrlBehaviorSubject = new BehaviorSubject('');

    // we do not wish to expose our behavior subjects.  create public observables
    public organizations$ = this.organizationsBehaviorSubject.asObservable();
    public organization$ = this.organizationBehaviorSubject.asObservable();
    public assetsUrl$ = this.assetsUrlBehaviorSubject.asObservable();

    constructor(private router: Router, private authService: AuthService) { }

    // gets the current selected organization
    get organization(): Organization | null | undefined {
        return this.organizationBehaviorSubject.value;
    }

    // sets a new selected orgranization
    setOrganization(organizationId: string | null): void {
        // get the organiztion by id
        const organization: Organization | undefined = this.organizationsBehaviorSubject.value?.find((org) => org.id == organizationId);

        // set the organization
        this.organizationBehaviorSubject.next(organization);

        // if we have an organiztion, set the asset url, local storage, and redirect the user
        if (this.organizationBehaviorSubject.value) {
            const name = this.organizationBehaviorSubject.value.name.toLowerCase();
            this.assetsUrlBehaviorSubject.next(environment.blobStorage[name as keyof typeof environment.blobStorage].assetsUrl);
            localStorage.setItem('organization', name);

            if (this.router.url) {
                let urlSegment: string[] = this.router.url.split('/').filter(segment => segment);
                if (urlSegment?.length) {
                    let orgName = urlSegment[0].toLocaleLowerCase();

                    if (name != orgName) {
                        this.router.navigate([`/${name}`]);
                    }
                }
            }

        } else {
            // no organization matches, clear local storage and log out the user
            localStorage.removeItem('organization');
            this.authService.logout({ returnTo: '/' });
        }
    }

    // gets the current list of organizations the user has access to
    get organizations(): Organization[] | null {
        return this.organizationsBehaviorSubject.value ? this.organizationsBehaviorSubject.value : null;
    }

    // sets the list of organizations the user has access to
    setOrganizations(organizationNames: string[]): void {
        let currentUrl = this.router.url;
        let orgName = '';
        if (currentUrl) {
            let urlSegment: string[] = currentUrl.split('/').filter(segment => segment);
            if (urlSegment?.length) {
                orgName = urlSegment[0].toLocaleLowerCase();
            }
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let _orgName = '';
                let url = event.url;
                let organizations = this.organizationsBehaviorSubject.value;
                if (url) {
                    let urlSegment = url.split('/').filter(segment => segment);
                    if (urlSegment?.length) {
                        _orgName = urlSegment[0].toLocaleLowerCase();
                    }

                    if (_orgName && organizations && organizations.length > 0) {
                        const urlOrganization = organizations.filter((org) => org.name.toLowerCase() == _orgName);
                        const organizationName = localStorage.getItem('organization')?.toLowerCase();

                        if (organizationName != _orgName && urlOrganization.length > 0) {
                            this.setOrganization(urlOrganization[0].id);
                        } else if (organizationName) {
                            const organization = organizations.filter((org) => org.name.toLowerCase() == organizationName);
                            if (organization.length > 0) {
                                this.setOrganization(organization[0].id);
                            }
                        }
                    }
                }

            }
        })

        // validate that organizationNames was passed in 
        if (organizationNames && organizationNames.length > 0) {

            // filter the organizations the user has access to from the complete list of organizations 
            const organizations: Organization[] = ORGANIZATIONS.filter(org => {
                var name: string = (org.name || '').toLowerCase();
                return organizationNames.find(org => org.indexOf(name) > -1);
            });

            // sort the list of organizations
            organizations.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            // set the list of organizations
            this.organizationsBehaviorSubject.next(organizations);

            // set the selected organization and redirect the user
            if (this.organizationsBehaviorSubject.value && this.organizationsBehaviorSubject.value.length > 0) {
                let _urlOrganization = null;
                if (orgName) {
                    // confirm the organization exists in the list of organizations
                    const urlOrganization = organizations.filter((org) => org.name.toLowerCase() == orgName);

                    // if the url org matches an org in the list
                    if (urlOrganization.length > 0) {
                        _urlOrganization = urlOrganization[0];
                    }
                }

                // check if this user has a saved organization
                const organizationName = localStorage.getItem('organization')?.toLowerCase();

                // if user has saved organization
                if (organizationName) {
                    // confirm the organization exists in the list of organizations
                    const organization = organizations.filter((org) => org.name.toLowerCase() == organizationName);

                    // if the saved org matches an org in the list
                    if (organization.length > 0) {
                        if (_urlOrganization) {
                            this.setOrganization(_urlOrganization.id);
                        } else {
                            // set local storage and organization
                            this.setOrganization(organization[0].id);
                        }
                    } else {
                        // set the organization to the first in the list
                        this.setOrganization(this.organizationsBehaviorSubject.value[0].id);
                    }
                } else if (_urlOrganization) {
                    this.setOrganization(_urlOrganization.id);
                } else {
                    // set the organization to the first in the list
                    this.setOrganization(this.organizationsBehaviorSubject.value[0].id);
                }
            } else {
                // the user does not have access to any organizations
                localStorage.removeItem('organization');
                this.authService.logout({ returnTo: '/' });
            }
        } else {
            // no organization matches, clear local storage and log out the user
            localStorage.removeItem('organization');
            this.authService.logout({ returnTo: '/' });
        }
    }

}
