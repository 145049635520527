import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { OfferPromo, PromoDomain } from '..';
import { Status } from '../models/offer-promo-status';
import { StatusCount } from '../models/status-count.model';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
  providedIn: 'root',
})

export class OfferPromoService<TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> {

  private saveorCancel = new BehaviorSubject<string>('');

  // initialize behavior subjects
  private eventIdBehaviorSubject = new BehaviorSubject<string>('');
  private offerIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'Rank', direction: 'asc', });
  private childVersionsSortBehaviorSubject = new BehaviorSubject({ active: 'Name', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private totalRecordsCount = new BehaviorSubject<number>(0);
  private totalPromoRecordsCount = new BehaviorSubject<number>(0);
  private statusUpdated = new BehaviorSubject<Date>(new Date());

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public childVersionsSort$ = this.childVersionsSortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public eventId$ = this.eventIdBehaviorSubject.asObservable();
  public offerId$ = this.offerIdBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public saveorCancel$ = this.saveorCancel.asObservable();
  public totalRecordsCount$ = this.totalRecordsCount.asObservable();
  public totalPromoRecordsCount$ = this.totalPromoRecordsCount.asObservable();
  public statusUpdated$ = this.statusUpdated.asObservable();
  public selection = new SelectionModel<OfferPromo>(true, []);

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, filters, reload]) => {
      let _orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'EventType') {
        _orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: this.eventIdBehaviorSubject.value,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Versions',
          $orderby: _orderby,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
       params = this.buildFilterParam(filters, params);
      }
      return params;
    })
  );

  public promoVersionsparams$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.childVersionsSortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, filters, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        key: offerId,
        // mode: viewMode,
        // $skip: page.pageIndex * page.pageSize,
        // $top: page.pageSize,
        $expand: 'Detail',
        $orderby: `Detail/${sort.active} ${sort.direction}`,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );


  public filterParams$ = combineLatest([
    this.eventIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)),
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([eventId, page, sort, search, filters, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          eventId: this.eventIdBehaviorSubject.value,
          // mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
          $filter: `EventType eq 'PROMO'`,
          types: `PROMO`
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParam(filters, params);
      }
      return params;
    })
  );

  set eventId(eventId: string) {
    this.eventIdBehaviorSubject.next(eventId);
  }

  set offerId(offerId: string) {
    this.offerIdBehaviorSubject.next(offerId);
  }

  public setSaveorCancel(currentAction: string) {
    this.saveorCancel.next(currentAction);
  }

  public updateRecordStatus(date: Date) {
    this.statusUpdated.next(date);
  }

  // get the offers by eventId
  private offerPromoResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetByEventId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

    // get the offers by eventId
    private offersPromosResponse$ = this.params$.pipe(
      tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
      switchMap((_params) =>
        this.httpClient.get(
          `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetOffersAndPromosByEventId`,
          { params: _params })
      ),
      tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
      shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
    );




  // offerpromo listing
  public offerPromos$: Observable<TPromoDomain[]> = this.offerPromoResponse$.pipe(
    map((res: any) => {
      this.totalRecordsCount.next(res['@odata.count'])
      return res.value
    })
  );

    // offerpromo listing
    public getoffersPromos$: Observable<TPromoDomain[]> = this.offersPromosResponse$.pipe(
      map((res: any) => {
        this.totalRecordsCount.next(res['@odata.count'])
        return res.value
      })
    );

  // total number of offer records based on filtering
  public totalRecords$: Observable<number> = this.totalRecordsCount$.pipe(
    map((res: any) => res)
  );

   // get the child versions by offerid
   private promoVersionsResponse$ = this.promoVersionsparams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetPromoDomainVersions`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

    // offerpromo listing
    public getpromoChildVersions$: Observable<TPromoDomain[]> = this.promoVersionsResponse$.pipe(
      map((res: any) => res ? res.value : [])
    );

  // total number of offer records based on filtering
  public totalVersionsRecords$: Observable<number> = this.promoVersionsResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );



  // gets an offer by id
  getPromo(promoId: string): Observable<PromoDomain<TOfferPromo>> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/${promoId}?$expand=Detail`;
    return this.httpClient.get<PromoDomain<TOfferPromo>>(url);
  }

  getPromoVersions(promoId: string): Observable<TOfferPromo> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/GetPromoVersions?promoId=${promoId}`;
    return this.httpClient.get<TOfferPromo>(url);
  }

  getStatusCount(): Observable<StatusCount> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/StatusCount?eventId=${this.eventIdBehaviorSubject.value}`;
    return this.httpClient.get<StatusCount>(url);
  }
  
  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  getAssets() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains?$expand=Detail&$filter=assetgroupname eq 'Badges'`;
    return this.httpClient.get(url);
  }

  savePromo(PromoDomain: PromoDomain<TOfferPromo>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/Post`;
    return this.httpClient.post(url, PromoDomain);
  }

  savePromoProperties(promoIds: string[], properties: any){
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/UpdatePromoProperties?${this.preparePromoIdString(promoIds)}`;
    return this.httpClient.post(url, properties);
  }

  preparePromoIdString(promoIds: any) {
    let urlParams = '';
    if (promoIds && promoIds.length > 0) {
      for (let index = 0; index <= promoIds.length - 1; index++) {
        urlParams += `promoIds=${promoIds[index]}`;
        if (index != promoIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  // deletes an Offer by id
  deletePromo(id: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/${id}?eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  // deletes an Offer by id
  deleteBasePromo(id: string, eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/DeletePromoVersions?key=${id}&eventId=${eventId}`;
    return this.httpClient.delete(url);
  }

  clonePromo(sourceId: string, PromoDomain: PromoDomain<TOfferPromo>){
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/ClonePromo?sourceId=${sourceId}`;
    return this.httpClient.post(url, PromoDomain);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  childVersionsSort(sort: any) {
    this.childVersionsSortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the offer listing
  reload() {
    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }


  // changes the view mode of the offer listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter expressions for filtering the event listing
  private buildFilterParam(filters: Filter[], params: HttpParams): HttpParams {

    // build the offerTagId expression
    const offerTagIdFilter = filters.filter(item => item.fieldName.toLowerCase() === 'offertagid');

    // loop through the division id filters and add filter statement to param
    offerTagIdFilter.forEach((filter, index) => {
      params = params.append('offerTagIds', `${filter.value}`);
    });

    // build the versionIds expression
    const versionIdFilters = filters.filter(item => item.fieldName.toLowerCase() === 'versionid');
    // loop through the version id filters and add filter statement to param
    versionIdFilters.forEach((filter, index) => {
      params = params.append('versionIds', `${filter.value}`);
    });

    // build the versionIds expression
    const eventIdFilters = filters.filter(item => item.fieldName.toLowerCase() === 'eventtype');
    // loop through the version id filters and add filter statement to param
    eventIdFilters.forEach((filter, index) => {
      params = params.append('types', `${filter.value}`);
    });
    return params;
  }


  updateStatus(status: any){
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/UpdateStatus`;
    return this.httpClient.post(url, status);
  }

  deleteOfferPromos(promos: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/PromoDomains/DeletePromos`;
    return this.httpClient.post(url, promos);
  }

  downloadOffersJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Offers/EtlExport?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadPromosJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Promos/EtlExport?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadEventJSON(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/EtlExportEvent?eventId=${eventId}`;
    window.open(url, '_blank');
  }

  downloadCSV(eventId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Events/ExportOffers?eventId=${eventId}`;
    window.open(url, '_blank');
  }

}
