import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, tap, map, Subject, debounceTime } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  Logger,
  ApiValidation,
  ConfirmDialogComponent,
  OrganizationService,
} from 'src/app/@shared';
import {
  Offer,
  OfferDomain,
  OfferService,
  OfferTag,
  OfferTagService,
  OfferPromoService,
  OfferPromo,
  PromoDomain,
  AssetTagService,
  AssetTag,
  AssetGroup,
  EventService,
  Event,
  EventDomain,
} from '../..';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Status } from '../../models/offer-promo-status';
import * as dayjs from 'dayjs';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('OfferDetailsComponent');

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent<
  T extends Offer,
  Tag extends OfferTag,
  TOfferDomain extends OfferDomain<Offer>,
  TOfferPromo extends OfferPromo,
  TEvent extends Event,
  TPromoDomain extends PromoDomain<OfferPromo>
> implements OnInit, OnDestroy {
  offer$: Observable<OfferDomain<T>> = of({} as OfferDomain<T>);
  assetsUrl$ = this.organizationService.assetsUrl$;
  assetsforBadge: any;
  assetGroups = [];
  offertypes: any;
  noneValue = null;
  offerTags$: Observable<Tag[]> = this.offerTagService.OfferTagsList$.pipe(
    map((tags) => {
      return tags.filter((tag) => {
        const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);
        return index < 0;
      });
    })
  );
  tagCtrl = new FormControl<string>('', [
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/]*"),
  ]);
  OfferTagIds: Tag[] = [];

  formGroup = new FormGroup({
    // ParentOfferId: new FormControl<string | null>(''),
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      Id: new FormControl<string>(
        { value: '', disabled: true },
        { nonNullable: true }
      ),
      StartDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      EndDate: new FormControl<Date | string | null>(null, [
        Validators.required,
      ]), // Validators.pattern("[0-9 \\-/]*"
      Name: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Headline: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      BodyCopy: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
      ]),
      Disclaimer: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      ClientComment: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(200),
      ]),
      SalePrice: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      TargetURL: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(5000),
      ]),
      Rank: new FormControl<number>(1, [
        Validators.min(1),
        Validators.max(9999999),
      ]),
      AdRetailPrice: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      UnitOfMeasure: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(9999999),
      ]),
      Quantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      PercentOff: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      DollarOff: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      BuyQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      GetQuantity: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      Limit: new FormControl<string>('', [
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.pattern("[a-zA-Z0-9 \\-#&*\\'/.,;]*"),
      ]),
      WhenYouBuy: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(99),
      ]),
      SaveAmount: new FormControl<number>(0, [
        Validators.min(0),
        Validators.max(9999999),
      ]),
      Version: new FormControl<string | null>(null),
      BadgeAssetId: new FormControl<string | null>(null),
      OfferTypeId: new FormControl<string | null>(null),
      Status: new FormControl<string>('PENDING')

    }),
  });
  offerId: string = '0';
  eventId!: string;
  maxDate: Date = new Date(9999, 0, 1);
  buttonClicked = new Subject();
  private offerTagsList: Tag[] = [];
  private offerResponse: any;
  offerpromosList: TPromoDomain[] = [];
  versionsCount: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public offerService: OfferService<T, TOfferDomain>,
    private offerTagService: OfferTagService<Tag>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private eventService: EventService<TEvent>,
    private organizationService: OrganizationService,
    private matSnackBar: MatSnackBar,
    private assetTagService: AssetTagService<AssetTag, AssetGroup>,
    private dialog: MatDialog,
    private authorizationService: AuthorizeControlService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.offerTagService.reload();

    this.route.params.subscribe((params) => {
      // get the offer id from the parent route
      this.offerId = params['offerId'];
      this.eventId = params['eventId'];

      // if we are editing a record, get the record data
      this.offerTagService.OfferTagsList$.subscribe((offerTags) => {
        this.offerTagsList = offerTags;
      });

      this.getDataById();
      this.offerService.getAssets().subscribe((res: any) => {
        this.assetsforBadge = res.value;
      });
      this.offerService.getOfferTypes().subscribe((res: any) => {
        this.offertypes = res.value;
      });

      this.offerService.saveorCancel$.subscribe((res) => {
        if (res === '1') {
          this.save();
        } else if (res === '0') {
          this.cancel();
        }
      });
    });

    this.offerService.newVersion$.subscribe(res => {
      if (res) {
        this.getDataById();
      }
    });

    this.offerService.versionsCount$.subscribe(res => {
      this.versionsCount = res;
    })

    const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(1000));
    buttonClickedDebounced.subscribe(() => {
      this.saveOffer();
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  navigateChildOffers() {
    this.router.navigate([`../`, `childversions`], { relativeTo: this.route });
  }

  getDataById() {
    if (this.offerId && this.offerId !== '0') {
      this.OfferTagIds = [];
      this.offer$ = this.offerService.getOffer(this.offerId).pipe(
        tap((offer) => {
          this.offerService.currentRecord = JSON.parse(JSON.stringify(offer));
          if (offer && offer.Detail && offer.Detail.StartDate) {
            offer.Detail.StartDate = new Date(offer.Detail.StartDate);
            console.log('Start Date', offer.Detail.StartDate);
          }
          if (offer && offer.Detail && offer.Detail.EndDate) {
            offer.Detail.EndDate = new Date(offer.Detail.EndDate);
            console.log('End Date', offer.Detail.EndDate);
          }
          const newVersionName = this.offerService.getNewVersion;
          if (newVersionName) {
            offer.Detail.ClientKey = '';
            // this.offerId = '0';
            offer.Detail.Version = newVersionName;
          }
          this.formGroup.patchValue(offer);
          this.setNulls();
          this.formGroup.controls.Detail.controls.BadgeAssetId.patchValue(
            offer?.BadgeAssetId as string
          );
          this.formGroup.controls.Detail.controls.OfferTypeId.patchValue(
            offer?.OfferTypeId as string
          );
          this.formGroup.markAllAsTouched();
          this.offerResponse = offer;

          if (offer.OfferTagIds && offer.OfferTagIds.length) {
            this.offerTagService
              .GetOfferTagsByIdsList(offer.OfferTagIds)
              .subscribe((offerTags: any) => {
                this.OfferTagIds = offerTags;
              });
            //this.offerTagService.getOfferTagsList().subscribe((offerTags: Tag[]) => {
            //  offer.OfferTagIds?.forEach((id: any) => {
            //    const index = offerTags.findIndex(_tag => _tag.Id == id);
            //    if (index >= 0) {
            //      this.OfferTagIds.push(offerTags[index]);
            //    }
            //  });
            //})
          }
        })
      );
    } else {
      this.eventService.getEventDomain(this.eventId).subscribe((res) => {
        // const eventStartDate = dayjs(res?.Detail.StartDate).format(
        //   'YYYY-MM-DD'
        // );
        // const eventEndDate = dayjs(res?.Detail.EndDate).format('YYYY-MM-DD');
        this.formGroup.controls.Detail.controls.StartDate.patchValue(
          new Date(res?.Detail.StartDate as any)
        );
        this.formGroup.controls.Detail.controls.EndDate.patchValue(
          new Date(res?.Detail.EndDate as any)
        );
      });
    }
  }


  validateDate(): boolean {
    let startDate = this.formGroup.controls.Detail.controls.StartDate;
    let endDate = this.formGroup.controls.Detail.controls.EndDate;
    if (endDate && endDate.value && startDate && startDate.value) {
      if (endDate.value <= startDate.value) {
        this.matSnackBar.open(
          'End Date must be greater than Start Date',
          'Error',
          { verticalPosition: 'top', panelClass: ['snackbar-error'] }
        );
        return false;
      }
    }
    return true;
  }

  save() {
    this.buttonClicked.next('');
  }

  clearNulls(offer: OfferDomain<T>): any {
    if (offer.Detail.AdRetailPrice == null) offer.Detail.AdRetailPrice = -1;
    if (offer.Detail.SalePrice == null) offer.Detail.SalePrice = -1;
    if (offer.Detail.Quantity == null) offer.Detail.Quantity = -1;
    if (offer.Detail.PercentOff == null) offer.Detail.PercentOff = -1;
    if (offer.Detail.DollarOff == null) offer.Detail.DollarOff = -1;
    if (offer.Detail.BuyQuantity == null) offer.Detail.BuyQuantity = -1;
    if (offer.Detail.GetQuantity == null) offer.Detail.GetQuantity = -1;
    if (offer.Detail.WhenYouBuy == null) offer.Detail.WhenYouBuy = -1;
    if (offer.Detail.SaveAmount == null) offer.Detail.SaveAmount = -1;

    return offer;
  }

  setNulls(): any {
    if (
      this.formGroup.controls.Detail.controls.AdRetailPrice.getRawValue() == -1
    )
      this.formGroup.controls.Detail.controls.AdRetailPrice.setValue(null);
    if (this.formGroup.controls.Detail.controls.SalePrice.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SalePrice.setValue(null);
    if (this.formGroup.controls.Detail.controls.Quantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.Quantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.PercentOff.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.PercentOff.setValue(null);
    if (this.formGroup.controls.Detail.controls.DollarOff.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.DollarOff.setValue(null);
    if (this.formGroup.controls.Detail.controls.BuyQuantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.BuyQuantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.GetQuantity.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.GetQuantity.setValue(null);
    if (this.formGroup.controls.Detail.controls.WhenYouBuy.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.WhenYouBuy.setValue(null);
    if (this.formGroup.controls.Detail.controls.SaveAmount.getRawValue() == -1)
      this.formGroup.controls.Detail.controls.SaveAmount.setValue(null);
  }

  trimControlValues(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      if (abstractControl instanceof FormGroup) {
        this.trimControlValues(abstractControl);
      } else {
        if (typeof abstractControl?.value == 'string') {
          abstractControl.setValue(abstractControl?.value.trim());
        }
      }
    });
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }

  saveOffer() {
    this.trimControlValues(this.formGroup);

    this.formGroup.markAsDirty();
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (!this.validateDate()) return;

      let offer = this.formGroup.getRawValue() as OfferDomain<T>;
      const startDate = this.formatDate(
        this.formGroup.controls.Detail.controls.StartDate.value
      );
      const endDate = this.formatDate(
        this.formGroup.controls.Detail.controls.EndDate.value
      );
      offer.Detail.StartDate = startDate;
      offer.Detail.EndDate = endDate;

      offer = this.clearNulls(offer);

      offer.OfferTagIds = this.OfferTagIds.map((tag) => tag.Id);
      offer.BadgeAssetId = this.formGroup.controls.Detail.controls.BadgeAssetId
        .value as string;
      delete offer.Detail.BadgeAssetId;
      offer.OfferTypeId = this.formGroup.controls.Detail.controls.OfferTypeId
        .value as string;
      delete offer.Detail.OfferTypeId;

      const objecttoPass = this.offerId !== '0' ? this.getUpdatedRecordInfo(offer) : offer;
      const offers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected.map(x => x.Id) : [this.offerId];

      let apiToCall;
      if (objecttoPass.Detail && (!objecttoPass.Detail.Id || objecttoPass.Detail.Id === '0')) {
        apiToCall = this.offerService.saveOffer(objecttoPass, this.eventId);
      } else {
        apiToCall = this.offerService.getNewVersion ? this.offerService.cloneOffer(offer.Detail.Id, offer) : this.offerService.saveOfferProperties(offers, objecttoPass);
      }

      apiToCall.subscribe({
        next: (response: any) => {
          this.offerService.newVersion = '';
          if (response) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
              data: {
                title: 'Saved Successfully',
                //message: 'Saved Successfully',
                confirmText: 'Continue Editing',
                cancelText: 'Back to Offers',
              },
              disableClose: true,
            });
            confirmDialog.afterClosed().subscribe((confirmResult) => {
              if (confirmResult) {
                this.offerId = response.Id;
                this.offerpromoService.reload();
                this.router
                  .navigate([`../../${response.Id}/details`], {
                    relativeTo: this.route,
                  })
                  .then((x) => {
                    this.reloadCurrentRoute();
                  });
              } else {
                this.offerpromoService.reload();
                this.router.navigate([`../../`], { relativeTo: this.route });
              }
            });
          }
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving offer', error);
            this.matSnackBar.open('500 Error saving offer', 'Error', {
              verticalPosition: 'top',
              panelClass: ['snackbar-error'],
            });
          }
          if (error.status === 400) {
            const apiValidations: any = error.error;
            if (Array.isArray(apiValidations)) {
              apiValidations.forEach((validation: any) => {
                if (validation.PropertyName) {
                  validation.PropertyName =
                    validation.PropertyName.toString().replace(
                      'OfferDomain.',
                      ''
                    );
                }
                if (this.formGroup?.get(validation.PropertyName)) {
                  const control = this.formGroup?.get(validation.PropertyName);
                  if (control) {
                    control.markAsTouched();
                    control.setErrors({ invalid: validation.ErrorMessage });
                    this.matSnackBar.open(validation.ErrorMessage, 'Error', {
                      verticalPosition: 'top',
                      panelClass: ['snackbar-error'],
                    });
                  }
                } else {
                  ///TODO: if we have cross field validation then show the validation error at the top of the screen
                  // if we have cross field validation then show the validation error at the top of the screen
                  // push general error messages to array this is displayed in a toast or dialog
                }
              });
            } else {
              this.matSnackBar.open(apiValidations, 'Error', {
                verticalPosition: 'top',
                panelClass: ['snackbar-error'],
              });
            }
          }
        },
      });
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  cancel(): void {
    if (this.formGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Close without saving?',
          message: 'This offer has unsaved changes',
          confirmText: 'Continue Editing',
          cancelText: 'Close',
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmResult) => {
        if (!confirmResult) {
          this.formGroup.reset();
          this.router.navigate([`../../`], { relativeTo: this.route });
        }
      });
    } else {
      this.formGroup.reset();
      this.router.navigate([`../../`], { relativeTo: this.route });
    }
  }

  updateTableStatus(status: Status) {
    let selectedRecordsDetails: any;
    // selectedRecordsDetails = [JSON.parse(JSON.stringify(this.offerResponse))];
    const responseOffers = (this.offerService.selection.selected && this.offerService.selection.selected.length > 0) ? this.offerService.selection.selected : [this.offerResponse];
    selectedRecordsDetails = responseOffers;
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  getUpdatedObject(selectedRecords: any) {
    let datatoReturn = [];
    if (selectedRecords && selectedRecords.length > 0) {
      for (let index = 0; index <= selectedRecords.length - 1; index++) {
        if (!selectedRecords[index].Detail) {
          datatoReturn.push({
            Detail: selectedRecords[index],
            EventType: 'OFFER'
          });
        } else {
          datatoReturn.push(selectedRecords[index]);
        }
      }
    }
    return datatoReturn;
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      const recordstoupdate = selectedRecordsDetails.map((x: any) => {
        return {
          Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
          Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
          EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: status, Version: x.Version,
          Label: 'OFFER'
        }
      });

      this.offerpromoService.updateStatus(recordstoupdate).subscribe({
        next: () => {
          this.getDataById();
        },
      });
    }
  }

  deleteRecord(versionName?: string) {
    if (versionName == 'Base') return;
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerService
          .deleteOffer(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting offer', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  deleteBaseRecord() {
    const record = this.formGroup.getRawValue();
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: `Are you sure you wish to delete ${record.Detail.Name}?`,
      },
      disableClose: true,
    });

    confirmDialog.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.offerService
          .deleteOfferVersions(record.Detail.Id, this.eventId)
          .subscribe({
            next: () => {
              this.matSnackBar.open(
                `${record.Detail.Name} deleted`,
                'OK',
                DEFAULT_SNACKBAR_CONFIG
              );
              this.offerpromoService.reload();
              this.router.navigate([`../../`], { relativeTo: this.route });
            },
            error: (error) => {
              log.error('Error deleting offer', error);

              if (error.error.value) {
                throw new Error(error.error.value);
              } else {
                throw new Error(error.message);
              }
            },
          });
      }
    });
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    if (
      this.OfferTagIds &&
      this.OfferTagIds.length > 0 &&
      this.OfferTagIds.includes(event.option.value)
    ) {
      return;
    }
    this.OfferTagIds.push(event.option.value);
    this.tagCtrl.setValue('');
    this.offerTags$ = this.offerTags$.pipe(
      map((tags) => {
        return tags.filter((tag) => {
          const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);
          return index < 0;
        });
      })
    );
  }

  addTag(event: MatChipInputEvent): void {
    event.chipInput!.clear();
    const value = (event.value || '').trim();
    let valid = this.tagCtrl.status !== 'INVALID';

    // Add Tag
    if (value && valid) {
      let tag = <Tag>{
        ClientKey: value,
        OfferTagName: value,
      };

      this.saveTag(tag);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue('');
  }

  saveTag(tag: Tag) {
    if (!this.offerTagsList) this.offerTagsList = [];
    const tagInfo = this.offerTagsList.find(
      (x) =>
        x.OfferTagName &&
        tag.OfferTagName &&
        x.OfferTagName.toLowerCase() === tag.OfferTagName.toLowerCase()
    );
    if (tagInfo) {
      if (
        !this.OfferTagIds.find((x) => x.OfferTagName == tagInfo.OfferTagName)
      ) {
        this.OfferTagIds.push(tagInfo);
      }
    } else {
      this.offerTagService.saveOfferTag(tag as Tag).subscribe({
        next: (response) => {
          this.OfferTagIds.push(response as Tag);
          this.matSnackBar.open(
            `${tag.OfferTagName} saved`,
            'OK',
            DEFAULT_SNACKBAR_CONFIG
          );
          this.offerTagService.reload();
        },
        error: (error) => {
          if (error.status === 500) {
            log.error('500 Error saving event', error);
          }
          if (error.status === 400) {
            const apiValidations: ApiValidation[] = error.error;

            apiValidations.forEach((validation) => {
              if (this.formGroup.get(validation.PropertyName)) {
                const control = this.formGroup.get(validation.PropertyName);
                if (control) {
                  control.markAsTouched();
                  control.setErrors({ invalid: validation.ErrorMessage });
                }
              } else {
                ///TODO: if we have cross field validation then show the validation error at the top of the screen
                // if we have cross field validation then show the validation error at the top of the screen
                // push general error messages to array this is displayed in a toast or dialog
              }
            });
          }
        },
      });
    }
  }

  removeTag(tag: Tag): void {
    const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);

    if (index >= 0) {
      this.OfferTagIds.splice(index, 1);
    }
    this.offerTags$ = this.offerTags$.pipe(
      map((tags) => {
        return tags.filter((tag) => {
          const index = this.OfferTagIds.findIndex((_tag) => _tag.Id == tag.Id);
          return index < 0;
        });
      })
    );
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    if (hasAccess) {
      this.tagCtrl.enable();
    } else {
      this.tagCtrl.disable();
    }
    return hasAccess;
  }

  getUpdatedRecordInfo(record: any) {
    const objecttoPass: any = {
      Properties: {}
    };
    const currentOfferInfo = this.offerService.getCurrentRecord;
    if (currentOfferInfo) {
      const properties = Object.keys(record);
      for (let index = 0; index <= properties.length - 1; index++) {
        if (record[properties[index]] != null && record[properties[index]] != undefined) {
          if (typeof (record[properties[index]]) === 'object' && !Array.isArray(record[properties[index]])) {
            const childProperties = Object.keys(record[properties[index]]);
            if (childProperties && childProperties.length > 0) {
              const currentRecord = currentOfferInfo[properties[index]];
              const updatedRecord = record[properties[index]];
              for (let childIndex = 0; childIndex <= childProperties.length - 1; childIndex++) {
                if (currentRecord[childProperties[childIndex]] !== updatedRecord[childProperties[childIndex]] && updatedRecord[childProperties[childIndex]] != null && updatedRecord[childProperties[childIndex]] != undefined) {
                  objecttoPass['Properties'][childProperties[childIndex]] = updatedRecord[childProperties[childIndex]];
                }
              }
            }
          } else {
            if (currentOfferInfo[properties[index]] !== record[properties[index]] && record[properties[index]] != null && record[properties[index]] != undefined) {
              objecttoPass[properties[index]] = record[properties[index]];
            }
          }
        }
      }
    }
    return objecttoPass;
  }

  ngOnDestroy() { }
}
