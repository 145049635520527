<ng-container *ngIf="viewModel$ | async as viewModel">

  <table mat-table [dataSource]="viewModel.events" matSort (matSortChange)="onSort($event)" matSortDisableClear
         [matSortStart]="'asc'" *ngIf="viewModel.events.length">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" data-testid="Check Box">
      <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td class="no-ellipsis"  mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
        Id
      </th>
      <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
    </ng-container>

    <!-- ClientKey Column -->
    <ng-container matColumnDef="ClientKey">
      <th width="150"  mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey">

        Client Key

      </th>
      <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
    </ng-container>

    <!-- EventName Column -->
    <ng-container matColumnDef="EventName">
      <th style="width:10%" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">

          Name
      
        
      </th>
      <td mat-cell *matCellDef="let record" title="{{ record.Detail.EventName }}"><a [routerLink]="[record.Detail.Id] + '/offers'">{{ record.Detail.EventName }}</a></td>
    </ng-container>

    <!-- DivisionNames Column -->
    <ng-container matColumnDef="DivisionNames">
      <th width="100" mat-header-cell *matHeaderCellDef data-testid="Division Name">
        Division Name
      </th>
      <td mat-cell title="{{ record.DivisionNames }}" *matCellDef="let record">{{ record.DivisionNames }}</td>
    </ng-container>

    <!-- EventTypeName Column -->
    <ng-container matColumnDef="EventTypeName">
      <th width="100" mat-header-cell *matHeaderCellDef data-testid="Event Type">
        Event Type
      </th>
      <td mat-cell title="{{ record.EventTypeName }}" *matCellDef="let record">{{ record.EventTypeName }}</td>
    </ng-container>

    <!-- OfferTye Status Column -->
    <ng-container matColumnDef="EventStatus">
      <th mat-header-cell *matHeaderCellDef data-testid="Offer Status">
        Offer Status
      </th>
      <td mat-cell *matCellDef="let record">
        <span class="statusAPPROVED">{{ record.EventStatus.Approved }}</span>
        <span class="statusPENDING">{{ record.EventStatus.Pending }}</span>
        <span class="statusSUPPRESSED">{{ record.EventStatus.Suppressed }}</span>
      </td>
    </ng-container>

    <!-- StartDate Column -->
    <ng-container matColumnDef="StartDate">
      <th width="100" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by StartDate"
          data-testid="Start Date">
        Start Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.Detail.StartDate | date: "MM/dd/yyyy" }}
      </td>
    </ng-container>

    <!-- EndDate Column -->
    <ng-container matColumnDef="EndDate">
      <th width="100" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by EndDate"
          data-testid="End Date">
        End Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.Detail.EndDate | date: "MM/dd/yyyy" }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th appAuthorizeControl="edit||eventmang" mat-header-cell *matHeaderCellDef>Actions</th>
      <td appAuthorizeControl="edit||eventmang" mat-cell *matCellDef="let record">
        <mat-button-toggle-group name="recOptions" class="link-btns" color="accent">
          <mat-button-toggle value="1" color="accent" title="Clone"
                             class="save-btn" (click)="openDialog(record)">Clone</mat-button-toggle>
          <mat-button-toggle value="2" class="cancel-btn" color="accent" title="Edit"
                             [routerLink]="[record.Detail.Id, 'details']" data-testid="Edit Record Button">Edit</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- <button type="button" mat-button color="accent" [routerLink]="[record.Detail.Id, 'details']"
        data-testid="Edit Record Button">Edit</button> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="onRowDoubleClick(row.Detail.Id)"></tr>
  </table>

  <table *ngIf="!viewModel.events.length">
    <tr>
      <td class="no-records" [attr.colspan]="displayedColumns.length">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>

  <!-- pager -->
  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.events.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

</ng-container>
