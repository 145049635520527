export const environment = {
  production: false,
  pr1ApiUrl: '/Private',
  appKey: 'core',
  environment:"dev",
  appMode: "shopify",
  shopifyAuth:{
    apiKey: '33041c44fd4af88e6bc89e88c2481de9',
    host: 'YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvcXVpY2tzdGFydC0xMWI3YzdmNA'
  },
  applicationInsights: {
    instrumentationKey: '89111394-0f35-45c3-85ef-3b717c84c902', // development environment
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
  auth: {
    domain: '',
    clientId: '',
    audience: '',
    organization: '',
    redirectUri: '',
  },
  httpInterceptor: {
    allowedList: [`/*`],
  },
  blobStorage: {
    kroger: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    riteaid: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    standard: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    walgreens: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    demo: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    qaautomation: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "http://127.0.0.1:5173"
    },
    unfi: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/digitaladportal"
    },
    shopify: {
      assetsUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/assets",
      digitaladportalUrl: "https://pr1stddevstandardblob.blob.core.windows.net/public/digitaladportal"
    },
  }
};
