import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest, Subscription, distinctUntilChanged } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { ProductDomain, Product, ProductService } from 'src/app/modules/standard/v1';

const log = new Logger('UploadAssetsProductsComponent');
interface DialogData {
  offerId: string;
}

@Component({
  selector: 'app-upload-assets-products',
  templateUrl: './upload-assets-products.component.html',
  styleUrls: ['./upload-assets-products.component.scss']
})

export class UploadAssetsProductsComponent<TProduct extends Product, TProductDomain extends ProductDomain<Product>> implements OnInit {
  selectedProduct: any;
  viewModel$ = combineLatest([
    this.productService.products$,
    this.productService.isLoading$,
    this.productService.totalRecords$,
    this.productService.page$,
  ]).pipe(
    map(([products, isLoading, totalRecords, page]) => {
      return { products, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['ProductName', 'Category', 'Brand', 'Actions'];
  productsList: Product[] = [];
  selection = new SelectionModel<Product>(true, []);


  constructor(private productService: ProductService<TProduct>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    public dialogRef: MatDialogRef<UploadAssetsProductsComponent<TProduct, TProductDomain>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    log.debug('init');
    this.productService.products$.subscribe((data) => {
      this.productsList = data;
    })
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['ProductName', 'Actions'];
        }
        else {
          this.displayedColumns = ['ProductName', 'Category', 'Brand', 'Actions'];
        }
      });

  }


  closeDialog() {
    this.dialogRef.close();
  }


  updateSelectedProduct(product: TProduct) {
    console.log(product, this.selectedProduct);
    this.selectedProduct = product;
  }
  saveData() {
    if (this.selectedProduct) {
      this.dialogRef.close(this.selectedProduct);
    }
  }

  onSort(sortState: Sort): void {
    this.productService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.productService.page(pageEvent);
  }

  onSearch(event: any) {
    this.productService.search(event.target.value);
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }
}
