import { Injectable } from '@angular/core';
import { OdataResponse, Store } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';

@Injectable({
  providedIn: 'root'
})

export class StoreService<T extends Store> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'StoreName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, filters, reload]) => {

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `${sort.active} ${sort.direction}`,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );

  // create the stores observable that calls http get when any of our parameters change
  private storesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // store listing
  public stores$: Observable<T[]> = this.storesResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of store records based on filtering
  public totalRecords$: Observable<number> = this.storesResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // list of states
  public states$: Observable<string[]> = this.httpClient.get<OdataResponse>(
    `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/GetDistinctStoreStates`).pipe(
      map((res: OdataResponse) => {
        let filterStateNames: string[] = res?.value.filter((state: string) => state).map((state: string) => state.trim());
        return [...new Set(filterStateNames)];
      })
    );

  //list of cities
  public cities$: Observable<string[]> = this.httpClient.get<OdataResponse>(
    `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/GetDistinctStoreCities`).pipe(
      map((res: OdataResponse) => {
        let filterCityNames: string[] = res?.value.filter((city: string) => city).map((city: string) => city.trim());
        return [...new Set(filterCityNames)];
      })
    );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // gets an store by id
  getStore(storeId: string): Observable<T> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/${storeId}`;
    return this.httpClient.get<T>(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // search phrase
  get search() {
    return this.searchBehaviorSubject.value;
  }

  set search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts Stores
  saveStore(Store: T) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/`;
    if (!Store.Id || Store.Id === '0') {
      // create new record
      return this.httpClient.post(url, Store);
    } else {
      // edit existing record
      url += `${Store.Id}`;
      return this.httpClient.put(url, Store);
    }
  }

  // deletes an Store by id
  deleteStore(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/${id}`;
    return this.httpClient.delete(url);
  }

  deleteStores(stores: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/DeleteStores?${this.prepareStoreStringtoDelete(stores)}`;
    return this.httpClient.post(url, stores);
  }

  prepareStoreStringtoDelete(stores: any) {
    let urlParams = '';
    if (stores && stores.length > 0) {
      for (let index = 0; index <= stores.length - 1; index++) {
        urlParams += `storeIds=${stores[index]?.Id}`;
        if (index != stores.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }


  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {

    // if filtering on states, build the states parameters
    const statesFilter = filters.filter(item => item.fieldName.toLowerCase() === 'states');

    if (statesFilter.length > 0) {
      statesFilter.forEach((filter) => {
        if (filter.value.length > 0) {
          params = params.append('states', filter.value);
        }
      });
    }

    // if filtering on city, build the states expression
    const cityFilter = filters.filter(item => item.fieldName.toLowerCase() === 'cities');

    if (cityFilter.length > 0) {
      params = params.append('city', cityFilter[0].value);
    }

    // return the http params
    return params;
  }

  downloadStoresJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/EtlExport`;
    window.open(url,'_blank');
  }

  downloadStoresCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Stores/Export`;
    window.open(url,'_blank');
  }

}
