import { Injectable } from '@angular/core';
import { Product, ProductDomain, ProductView } from '..';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { newGuid } from '@microsoft/applicationinsights-core-js';

@Injectable({
  providedIn: 'root'
})

export class ProductService<TProduct extends Product> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'ProductName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $expand: 'Detail',
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: `Detail/${sort.active} ${sort.direction}`,
          $count: true,
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  // create the products observable that calls http get when any of our parameters change
  private productsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // product listing
  public products$: Observable<TProduct[]> = this.productsResponse$.pipe(
    map((res: any) => res.value)
  );

  // total number of product records based on filtering
  public totalRecords$: Observable<number> = this.productsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  private productCategoriesResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductCategories/GetProductCategories`)
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // productCategories listing
  public productCategories$: Observable<TProduct[]> = this.productCategoriesResponse$.pipe(
    map((res: any) => res.value)
  );

  // gets an product by id
  getProduct(productId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/${productId}?$expand=Detail`;
    return this.httpClient.get(url);
  }

  // gets an product by id
  getByOfferIdProductId(offerId: string, productId: string): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/ByOfferIdProductId?offerId=${offerId}&productId=${productId}&$expand=Detail`;
    return this.httpClient.get(url);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the product listing
  reload() {
    // reload the Product data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // up-serts Products
  saveProduct(product: ProductView) {
    const productCategoryIds: string[] = product.ProductCategoryIds ? [product.ProductCategoryIds] : [];
    const inputProduct: Product = {
      Id: product.Id, ClientKey: product.ClientKey, ProductName: product.ProductName,
      ProductDisplayName: product.ProductDisplayName, ProductDescription: product.ProductDescription, SKU: product.SKU, UPC: product.UPC
    };
    const inputProductDomain: ProductDomain<Product> = { Detail: inputProduct, DomainId: newGuid(), ProductBrandId: product.ProductBrandId, VendorId: product.VendorId, ProductCategoryIds: productCategoryIds };

    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/`;
    if (!product.Id || product.Id === '0') {// create new record
      url += `CreateProduct`;
      return this.httpClient.post(url, inputProductDomain);
    } else { // edit existing record      
      inputProductDomain.DomainId = product.Id;
      url += `UpdateProduct?key=${product.Id}`;
      return this.httpClient.put(url, inputProductDomain);
    }
  }

  // deletes an Product by id
  deleteProduct(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Products/${id}`;
    return this.httpClient.delete(url);
  }

  deleteProducts(products: any) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/DeleteProducts?${this.prepareProductStringtoDelete(products)}`;
    return this.httpClient.post(url, products);
  }

  prepareProductStringtoDelete(products: any) {
    let urlParams = '';
    if (products && products.length > 0) {
      for (let index = 0; index <= products.length - 1; index++) {
        urlParams += `productIds=${products[index]?.Detail.Id}`;
        if (index != products.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  prepareOfferIdString(offerIds: any) {
    let urlParams = '';
    if (offerIds && offerIds.length > 0) {
      for (let index = 0; index <= offerIds.length - 1; index++) {
        urlParams += `offerIds=${offerIds[index]}`;
        if (index != offerIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }


  // update Offer Has Product Rank
  updateOfferHasProductRank(offerIds: string[], product: ProductDomain<Product>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/UpsertRankOfferHasProduct?${this.prepareOfferIdString(offerIds)}`;
    return this.httpClient.post(url, product);
  }

  downloadProductsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Products/EtlExport`;
    window.open(url,'_blank');
  }

  downloadProductsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Products/Export`;
    window.open(url,'_blank');
  }
}
