import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from "rxjs";
import { Filter, OrganizationService } from "src/app/@shared";
import { DEFAULT_PAGING } from "src/app/@shared/constants/site.constants";
import { environment } from 'src/environments/environment';
import { Coupon } from "../models/coupon.model";
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { SelectionModel } from "@angular/cdk/collections";
import { StatusCount } from "../models/status-count.model";

// constants
///TODO: move this to a constants pattern
const DEFAULT_DATE_RANGE = {
  startDate: dayjs().subtract(1, 'days').format('MM/DD/YYYY'),
  endDate: dayjs().add(5, 'months').format('MM/DD/YYYY'),
};

@Injectable({
    providedIn: 'root'
  })

export class CouponService<T extends Coupon> {

    // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING); 
  private dateRangeBehaviorSubject = new BehaviorSubject(DEFAULT_DATE_RANGE);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'RedemptionStartDate', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private saveorCancel = new BehaviorSubject<string>('');
  private totalRecordsCount = new BehaviorSubject<number>(0);
  private currentSelectedRecord = new BehaviorSubject<any>({});
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private statusUpdated = new BehaviorSubject<Date>(new Date());
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable(); 
  public dateRange$ = this.dateRangeBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public totalRecordsCount$ = this.totalRecordsCount.asObservable();
  public saveorCancel$ = this.saveorCancel.asObservable();
  public currentSelectedRecord$ = this.currentSelectedRecord.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public selection = new SelectionModel<Coupon>(true, []);
  public statusUpdated$ = this.statusUpdated.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
    this.filterBehaviorSubject.pipe(debounceTime(300)),
    this.dateRangeBehaviorSubject.pipe(debounceTime(50)),
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([page, sort, search, reload, filtersList, dateRange]) => {
      let OrderBy = `Detail/${sort.active} ${sort.direction}`;

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          //  mode: viewMode,
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $expand: 'Detail',
          $orderby: OrderBy,
          start: dateRange.startDate,
          Expiry: dateRange.endDate
        }
      });
      
      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `${search}`);
      }

      return params;
    })
  );

  public updateRecordStatus(date: Date) {
    this.statusUpdated.next(date);
  }

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  public setSaveorCancel(currentAction: string) {
    this.saveorCancel.next(currentAction);
  }

  set currentRecord(record: any) {
    this.currentSelectedRecord.next(record);
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // reloads/refreshes the coupon listing
  reload() {
    // reload the Coupon data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the date range of the event listing
  dateRange(start?: string, end?: string) {
    const viewMode = this.viewModeBehaviorSubject.value;
    if (start && end) {
      const range = { startDate: dayjs(start).format('MM/DD/YYYY'), endDate: dayjs(end).format('MM/DD/YYYY') };
      this.dateRangeBehaviorSubject.next(range);
    }
  }

  addFilters(newFilters: any) {
    const filters: any[] = []
    for(let key in newFilters) {
      if(newFilters[key]) {
        if(typeof(newFilters[key]) === 'object') {
          newFilters[key] = dayjs(newFilters[key]).format('MM/DD/YYYY')
        }
        filters.push({ fieldName: key, displayText: newFilters[key], value: newFilters[key] })
      }
    }

    this.filterBehaviorSubject.next(filters);
  }
  
  // create the couponsList observable that calls http get when any of our parameters change
  private couponsListResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // gets an offer by id
  getCoupon(couponId: string): Observable<Coupon> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/${couponId}?$expand=Detail`;
    return this.httpClient.get<Coupon>(url);
  }

  // coupons listing
  public couponList$: Observable<any[]> = this.couponsListResponse$.pipe(
    map((res: any) => {
    this.totalRecordsCount.next(res['@odata.count'])
     return res.value
    })
  );

  saveCoupon(coupon: T): Observable<any> {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/`;
    if (!coupon.Detail.Id || coupon.Detail.Id === '0') {
      // create new record
      return this.httpClient.post(url, coupon);
    } else {
      url += `${coupon.Detail.Id}`;
      return this.httpClient.put(url, coupon);
    }
  }

  deleteCoupon(couponId: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/${couponId}`;
    return this.httpClient.delete(url);
  }

  deleteBulkCoupons(couponIds: string[]) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/BulkDelete`;
    return this.httpClient.post(url, couponIds);
  }

  getStatusCount(): Observable<StatusCount> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/StatusCount`;
    return this.httpClient.post<StatusCount>(url, {});
  }

  updateStatus(status: string, couponIds: string[]): Observable<any> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/CouponDomains/UpdateStatus?status=${status}`;
    return this.httpClient.post<StatusCount>(url, couponIds);
  }

}