<!-- page title -->
<div class="offerMainDiv">
  <div class="headerWidth">
    <h1 class="headerMargin">Offer Management</h1>
  </div> 
</div>


<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['promodetails']" routerLinkActive #promodetails="routerLinkActive"
    [active]="promodetails.isActive">Promo Details
  </a>
    <a mat-tab-link  [disabled]="promoId == '0'" [routerLink]="['versions']" routerLinkActive #versions="routerLinkActive"
    [active]="versions.isActive">Versions
  </a>
  <a mat-tab-link  [disabled]="promoId == '0'" [routerLink]="['assets']" routerLinkActive #assets="routerLinkActive"
    [active]="assets.isActive">Assets
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>