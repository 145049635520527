<ng-container *ngIf="viewModel$ | async as viewModel; else loading">
    <form [formGroup]="form">
        <div formArrayName="versions">
          <div class="grid" *ngIf="viewModel.coupons.length">
            <mat-card appearance="outlined" *ngFor="let coupon of viewModel.coupons; index as i">
              <mat-card-content>
                <div class="offer-status">
                  <span [matMenuTriggerFor]="menu" class="ellipsis-3-line status{{coupon?.Detail?.Status}}">{{coupon?.Detail?.Status}}
                    <mat-icon class="icon">arrow_drop_down</mat-icon>
                  </span>

                </div>
                <mat-menu #menu="matMenu">
                  <button type="button" [disabled]="coupon?.Detail?.Status === 'Approved'" mat-menu-item class="statusApproved" (click)="updateTableStatus('Approved', coupon)">
                    Approved
                  </button>
                  <button type="button" [disabled]="coupon?.Detail?.Status === 'Pending'" mat-menu-item class="statusPending" (click)="updateTableStatus('Pending', coupon)">
                    Pending
                  </button>
                  <button type="button" [disabled]="coupon?.Detail?.Status === 'Suppressed'" mat-menu-item class="statusSuppressed" (click)="updateTableStatus('Suppressed', coupon)">
                    Suppressed
                  </button>
                </mat-menu>
                <div class="grid-col-span-2 offer-name" title="{{ coupon?.Detail?.Summary }}" fxLayout="row wrap"
                  fxLayoutAlign="start start" fxLayoutGap="15px">
                  <mat-checkbox (click)="$event.stopPropagation()" fxFlex="30px" class="card-checkbox"
                    (change)="$event ? selection.toggle(coupon) : null" [checked]="selection.isSelected(coupon)">
                  </mat-checkbox>
                  <!-- <div class="ellipsis-3-line offer-name-detail">{{ getActiveOffer(offers).Name }}</div> -->
                </div>
                <ng-container *ngIf="coupon.PreviewHtml; else couponPreviewHTML">
                  <div [innerHTML]="coupon.PreviewHtml"></div>
                </ng-container>
                <ng-template #couponPreviewHTML>
                  <div fxLayoutAlign="center center" class="offer-image">
                    <img mat-card-md-image src="./assets/images/default-image.png"
                      alt="Offer image" />
                  </div>
                  <div class="offer-content" fxLayout="column">
                    <div class="offer-headline">
                      <span class="ellipsis-3-line">$ {{ coupon?.Detail?.CouponValue }} off</span>
                    </div>
                    <div class="offer-headline">
                      <span class="ellipsis-3-line">{{ coupon?.Detail?.Summary }}</span>
                    </div>
                    <div class="offer-bodycopy">
                      <span class="ellipsis-3-line">{{ coupon?.Detail?.Description }}</span>
                    </div>
                    <div class="offer-bodycopy">
                      <span class="ellipsis-3-line">{{ coupon?.Detail?.PurchaseConditions }}</span>
                    </div>
                    <div class="offer-bodycopy">
                      <span class="">Valid from {{ coupon?.Detail?.ActiveDate }} - {{ coupon?.Detail?.ExpiryDate }}</span>
                    </div>
                  </div>
                </ng-template>
                <div class="card-footer">
                  <div class="card-footer-text">
                    <span class="footer-text">COUPON</span>
                    <span class="footer-text display">Client Key:
                      <span title="{{ coupon?.Detail?.ClientKey }}">{{
                        coupon?.Detail?.ClientKey
                        }}</span></span>
                  <div fxLayOut="row" class="card-footer-btns">
                      <button class="offer-button" type="button" mat-button
                          color="accent" [routerLink]="[coupon?.Detail?.Id, 'details']">
                          <span >Edit</span>
                      </button>
                  </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>

      <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100, 500]" (page)="onPage($event)" *ngIf="viewModel.coupons?.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
      </mat-paginator>

      <table *ngIf="!viewModel.coupons.length">
        <tr>
          <td class="no-records">
            <h2>No Records Found</h2>
          </td>
        </tr>
      </table>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </ng-template>
