<form *ngIf="offer$ | async as OfferDetails; else loading" [formGroup]="formGroup">

    <div formGroupName="Detail" fxLayout="column">
        <h2><b> New Version </b> </h2>        
            <p>{{offerName}} will be duplicated into a new version.</p>
            <mat-form-field class="version" appearance="outline">
                <mat-label>Version Name</mat-label>
                <input matInput type="text" formControlName="Name" />
                <mat-error *ngIf="formGroup.controls.Detail.controls.Name.hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
    </div>

    <mat-card-actions fxLayout="row" fxLayoutAlign="end">

        <div>
            <button mat-stroked-button type="button" (click)="cancel()">
                Cancel
            </button>
            <button mat-raised-button type="button" color="accent" (click)="saveData()">
                Create
            </button>
        </div>
    </mat-card-actions>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>