<form *ngIf="offer$ | async as OfferDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div class="d-flex">
                <h2>{{formGroup.controls.Detail.controls.Name.value == '' ? "Offer" : formGroup.controls.Detail.controls.Name.value}}</h2>
                <button *ngIf="offerId !=='0'" (click)="deleteRecord(OfferDetails?.Detail?.Version)"  class="version" mat-button> {{formGroup.controls.Detail.controls.Version.value}} <fa-icon *ngIf=" OfferDetails?.Detail?.Version !== 'Base'"  [icon]="['fas', 'trash']"></fa-icon></button>
                <button (click)="navigateChildOffers()" mat-button type="button" color="accent"  *ngIf="offerId !=='0' && versionsCount > 1" class="view-all">View All Versions ({{ versionsCount }})</button>
            </div>
            <div fxFlex="50" class="d-flex flex-end">
                <div class="header-card-actions" [ngClass]="{'mydisable': formGroup.invalid}" *ngIf="offerId != '0'" style="top: 0; position: relative;" [ngStyle]="{'top': canEdit() ? '0': '17px'}">
                    <span  [matMenuTriggerFor]="canEdit() ? menu: null" class="status-btns status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" class="statusAPPROVED tab-status" mat-menu-item (click)="updateTableStatus(2)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'APPROVED'">APPROVED</button>
                    <button type="button" mat-menu-item class="statusPENDING tab-status"  (click)="updateTableStatus(1)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'PENDING'">PENDING</button>
                    <button type="button" class="statusSUPPRESSED tab-status" mat-menu-item (click)="updateTableStatus(3)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'SUPPRESSED'">SUPPRESSED</button>
                  </mat-menu>

                <mat-card-actions fxLayout="row" [fxLayoutAlign]="offerId != '0'?'space-between':'end'" >
                    <div class="btn-width">
                        <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                            <span *ngIf="!canEdit()">Close</span>
                            <span *ngIf="canEdit()">Cancel</span>
                        </button>
                        <button *ngIf="canEdit()" class="btn-right" mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
                            Save
                        </button>
                    </div>
                </mat-card-actions>
            </div>
            </div>


            <div formGroupName="Detail">
                <mat-accordion class="OfferDetails" multi>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Offer Details</mat-panel-title>
                            <mat-panel-description>Copy, Dates and Tags</mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px"  fxLayout.lt-md="column" >
                            <mat-card appearance="outlined" fxFlex="25" fxFlexFill fxLayout="column"   class="marginTop marginBottom mat-card-offer">
                                <!-- <img mat-card-md-image *ngIf="OfferDetails.FileName" src="{{assetsUrl$ | async}}/{{OfferDetails.FileName}}" class="product-image" alt="offer image">
                                <img mat-card-md-image *ngIf="!OfferDetails.FileName" src="../.././assets/images/default-image.png" class="product-image" alt="offer image"> -->
                                <ng-container *ngIf="assetsUrl$ | async as assetsUrl">
                                    <img *ngIf="OfferDetails?.FileName && OfferDetails.OfferTypeId" mat-card-md-image
                                        [src]="getImageSrc(assetsUrl, OfferDetails)" alt="Offer image">
                                </ng-container>

                                <img mat-card-md-image *ngIf="!OfferDetails.FileName &&  (offerId == '0' || OfferDetails.OfferTypeId)"
                                    src="./assets/images/default-image.png" alt="Offer image">

                                <ng-container *ngIf="assetsUrl$ | async as assetsUrl">
                                    <div *ngIf="OfferDetails?.OfferTypeId"
                                        [innerHtml]="OfferDetails.PricingPreviewHtml"></div>
                                </ng-container>
                                <ng-container *ngIf="offerId != '0' && !OfferDetails.OfferTypeId">
                                    <img mat-card-md-image src="./assets/images/default-offer-image.png"
                                        alt="Offer image">
                                    <div style="text-align: center; padding:2rem 0;">Please select an offer type</div>
                                </ng-container>


                                <!-- <img mat-card-md-image *ngIf="offerId == '0'"
                                    src="./assets/images/default-image.png" alt="Offer image"> -->
                            </mat-card>
                            <div fxLayout="column" fxFlex="auto" class="marginTop">
                                <mat-form-field appearance="outline" >
                                    <mat-label>Offer Name</mat-label>
                                    <input [readonly]="!canEdit()" matInput type="text" formControlName="Name" />
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.Name.hasError('required')">
                                        Offer Name is required
                                    </mat-error>
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.Name.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.Name.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.Name.errors!['maxlength']['requiredLength'] + '.'}}
                                    </mat-error>
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                                    <mat-form-field appearance="outline"  fxFlex>
                                        <mat-label>Start Date</mat-label>
                                        <input  matInput [matDatepicker]="startDatePicker" formControlName="StartDate" [disabled]="!canEdit()">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('required')">
                                            Start date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.StartDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.StartDate.errors!['matDatepickerMax'] && 'Start Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="startDatePicker" [disabled]="!canEdit()">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #startDatePicker [disabled]="!canEdit()"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>End Date</mat-label>
                                        <input [disabled]="!canEdit()" matInput [matDatepicker]="endDatePicker" formControlName="EndDate">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('required')">
                                            End date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.EndDate.errors!['matDatepickerMax'] && 'End Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="endDatePicker" [disabled]="!canEdit()"></mat-datepicker-toggle>
                                        <mat-datepicker #endDatePicker [disabled]="!canEdit()"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline" fxFlex="33">
                                    <mat-label>Headline</mat-label>
                                    <input [readonly]="!canEdit()" matInput type="text" formControlName="Headline" />
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.Headline.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.Headline.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.Headline.errors!['maxlength']['requiredLength'] + '.'}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>Body Copy</mat-label>
                                    <input [readonly]="!canEdit()" matInput type="text" formControlName="BodyCopy" />
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.BodyCopy.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.BodyCopy.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.BodyCopy.errors!['maxlength']['requiredLength'] + '.'}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50">
                                    <mat-label>Disclaimer</mat-label>
                                    <input [readonly]="!canEdit()" matInput type="text" formControlName="Disclaimer" />
                                    <mat-error *ngIf="formGroup.controls.Detail.controls.Disclaimer.status=='INVALID'">
                                        {{formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength']['requiredLength'] + '.'}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Comment</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="ClientComment" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientComment.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.ClientComment.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.ClientComment.errors!['maxlength']['requiredLength'] + '.'}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Offer Tags</mat-label>
                                <mat-chip-grid #chipList aria-label="Tags selection" [formControl]="tagCtrl">
                                    <mat-chip-row *ngFor="let tag of OfferTagIds" (removed)="removeTag(tag)">
                                        {{tag.OfferTagName}}
                                        <button type="button" matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip-row>
                                    <input [readonly]="!canEdit()" placeholder="Associate Tag..." [matChipInputFor]="chipList"
                                        [matAutocomplete]="auto" (matChipInputTokenEnd)="addTag($event)"
                                        [formControl]="tagCtrl">
                                </mat-chip-grid>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                                    <mat-option *ngFor="let tag of offerTags$ | async" [value]="tag">
                                        {{tag.OfferTagName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="tagCtrl.status=='INVALID'">
                                    {{tagCtrl.errors!['maxlength'] && 'Maximum length can be ' + tagCtrl.errors!['maxlength']['requiredLength']+'. '}}
                                    {{tagCtrl.errors!['pattern'] && 'Offer Tags may only contain alphanumeric and -#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Target Url</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="TargetURL" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.TargetURL.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.TargetURL.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.TargetURL.errors!['maxlength']['requiredLength']+'. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" *ngIf="offerId != '0'">
                                <mat-label>Id</mat-label>
                                <input [readonly]="!canEdit()" matInput formControlName="Id" readonly>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Client Key</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="ClientKey" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('required')">
                                    Client key is required
                                </mat-error>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be ' + formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Rank</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="Rank" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Rank.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Rank.errors!['max'] && 'Maximum number can be ' + formGroup.controls.Detail.controls.Rank.errors!['max']['max'] + '. '}}
                                    {{formGroup.controls.Detail.controls.Rank.errors!['min'] && 'Minimum required number can be' + formGroup.controls.Detail.controls.Rank.errors!['min']['min'] + '. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Badge</mat-label>
                                <mat-select [disabled]="!canEdit()" formControlName="BadgeAssetId">
                                    <mat-option *ngFor="let item of assetsforBadge" [value]="item?.Id">
                                      <!-- <img *ngIf="item?.FileName" class="badge-option-image" src="{{assetsUrl$ | async}}/{{item?.FileName}}" /> -->
                                      <img *ngIf="item?.FileName && assetsUrl$ | async as assetsUrl" class="badge-option-image" [src]="getImageSrc(assetsUrl, item)" alt="Offer image">
                                      {{item?.AssetName}}
                                    </mat-option>
                                    <mat-option [value]="noneValue">None</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Offer Type</mat-label>
                                <mat-select [disabled]="!canEdit()" formControlName="OfferTypeId">
                                    <mat-option *ngFor="let item of offertypes" [value]="item?.Id">
                                      <img *ngIf="item?.Detail?.FileName" class="badge-option-image" src="{{assetsUrl$ | async}}/{{item?.Detail?.FileName}}" />  {{item?.OfferTypeName}}
                                    </mat-option>
                                    <mat-option [value]="noneValue">None</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Pricing</mat-panel-title>
                            <mat-panel-description>Price and Savings Info</mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" class="paddingTop">
                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Sale Price</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="SalePrice" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.SalePrice.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.SalePrice.errors!['max'] && 'Maximum number can be ' + formGroup.controls.Detail.controls.SalePrice.errors!['max']['max'] + '. '}}
                                    {{formGroup.controls.Detail.controls.SalePrice.errors!['min'] && 'Minimum required number can be ' + formGroup.controls.Detail.controls.SalePrice.errors!['min']['min'] + '.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Ad Retail Price</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="AdRetailPrice" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.AdRetailPrice.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.AdRetailPrice.errors!['max'] && 'Maximum number can be ' + formGroup.controls.Detail.controls.AdRetailPrice.errors!['max']['max'] + '. '}}
                                    {{formGroup.controls.Detail.controls.AdRetailPrice.errors!['min'] && 'Minimum required number can be' + formGroup.controls.Detail.controls.AdRetailPrice.errors!['min']['min'] + '. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="auto">
                                <mat-label>Unit Of Measure</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="UnitOfMeasure" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.UnitOfMeasure.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.UnitOfMeasure.errors!['maxlength'] && 'Maximum length can be ' + formGroup.controls.Detail.controls.UnitOfMeasure.errors!['maxlength']['requiredLength'] + '.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Quantity</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="Quantity" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Quantity.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Quantity.errors!['max'] && 'Maximum number can be ' + formGroup.controls.Detail.controls.Quantity.errors!['max']['max'] + '. '}}
                                    {{formGroup.controls.Detail.controls.Quantity.errors!['min'] && 'Minimum required number can be ' + formGroup.controls.Detail.controls.Quantity.errors!['min']['min'] + '.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Percent Off</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="PercentOff" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.PercentOff.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.PercentOff.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.PercentOff.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.PercentOff.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.PercentOff.errors!['min']['min']+'.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="auto">
                                <mat-label>Dollar Off</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="DollarOff" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.DollarOff.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.DollarOff.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.DollarOff.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.DollarOff.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.DollarOff.errors!['min']['min']+'.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Buy Quantity</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="BuyQuantity" pattern="\d{1,2}" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.BuyQuantity.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.BuyQuantity.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.BuyQuantity.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.BuyQuantity.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.BuyQuantity.errors!['min']['min']+'. '}}
                                    {{formGroup.controls.Detail.controls.BuyQuantity.errors!['pattern'] && 'Decimal values are not allowed.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Get Quantity</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="GetQuantity" pattern="\d{1,2}" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.GetQuantity.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.GetQuantity.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.GetQuantity.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.GetQuantity.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.GetQuantity.errors!['min']['min']+'. '}}
                                    {{formGroup.controls.Detail.controls.GetQuantity.errors!['pattern'] && 'Decimal values are not allowed.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="auto">
                                <mat-label>When You Buy</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="WhenYouBuy" pattern="\d{1,2}" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.WhenYouBuy.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.WhenYouBuy.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.WhenYouBuy.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.WhenYouBuy.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.WhenYouBuy.errors!['min']['min']+'.'}}
                                    {{formGroup.controls.Detail.controls.WhenYouBuy.errors!['pattern'] && 'Decimal values are not allowed.'}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Limit</mat-label>
                                <input [readonly]="!canEdit()" matInput type="text" formControlName="Limit" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Limit.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Limit.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.Limit.errors!['maxlength']['requiredLength']+'.'}}
                                    {{formGroup.controls.Detail.controls.Limit.errors!['pattern'] && 'Limit may only contain alphanumeric and .,;-#&*\'/ characters. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Save Amount</mat-label>
                                <input [readonly]="!canEdit()" matInput type="number" formControlName="SaveAmount" />
                                <mat-error *ngIf="formGroup.controls.Detail.controls.SaveAmount.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.SaveAmount.errors!['max'] && 'Maximum number can be '+formGroup.controls.Detail.controls.SaveAmount.errors!['max']['max']+'. '}}
                                    {{formGroup.controls.Detail.controls.SaveAmount.errors!['min'] && 'Minimum required number can be '+formGroup.controls.Detail.controls.SaveAmount.errors!['min']['min']+'.'}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-card-content>
        <mat-card-actions class="card-footer-actions" fxLayout="row" [fxLayoutAlign]="offerId != '0' && OfferDetails?.Detail?.Version == 'Base' ?'space-between':'end'">
            <button appAuthorizeControl="edit||offermang" mat-raised-button type="button" color="warn" (click)="deleteBaseRecord()"  *ngIf="offerId != '0' && OfferDetails?.Detail?.Version == 'Base'">
                Delete
            </button>
            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" fxLayoutAlign="end">
                <div [ngClass]="{'mydisable': formGroup.invalid}" *ngIf="offerId != '0'" style="top: 0; position: relative; " [ngStyle]="{'top': canEdit() ? '0px': '17px'}">
                    <span [matMenuTriggerFor]="canEdit() ? menu: null" class="status-btns status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" class="tab-status statusAPPROVED" mat-menu-item (click)="updateTableStatus(2)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'APPROVED'">APPROVED</button>
                    <button type="button" mat-menu-item class="tab-status statusPENDING" (click)="updateTableStatus(1)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'PENDING'">PENDING</button>
                    <button type="button" class="tab-status statusSUPPRESSED" mat-menu-item (click)="updateTableStatus(3)" [disabled]="formGroup.controls.Detail.controls.Status.value === 'SUPPRESSED'">SUPPRESSED</button>
                  </mat-menu>
                <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                    <span *ngIf="canEdit()">Cancel</span>
                    <span *ngIf="!canEdit()">Close</span>
                </button>
                <button *ngIf="canEdit()" class="btn-right" mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
                    Save
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
