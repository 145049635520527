<ng-container *ngIf="viewModel$ | async as viewModel">
    <table *ngIf="viewModel.products.length" mat-table [dataSource]="viewModel.products" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">
          <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                <mat-checkbox appAuthorizeControl="edit||offermang" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [disabled]="!viewModel.products.length">
                </mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
                <mat-checkbox appAuthorizeControl="edit||offermang" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- ProductName Column -->
        <ng-container matColumnDef="ProductName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
                Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail.ProductName }}</td>
        </ng-container>     

        <!-- Category Column -->
        <ng-container matColumnDef="ProductCategoryName">
            <th mat-header-cell *matHeaderCellDef>
                Category
            </th>
            <td mat-cell *matCellDef="let record">{{ record.ProductCategoryName }}</td>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="ProductBrandName">
            <th mat-header-cell *matHeaderCellDef>
                Brand
            </th>
            <td mat-cell *matCellDef="let record">{{ record.ProductBrandName }}</td>
        </ng-container>

         <!-- Id Column -->
         <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
                Id
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
        </ng-container>

        <!-- ClientKey Column -->
        <ng-container matColumnDef="ClientKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey">
                Client Key
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
        </ng-container>

        <!-- Rank Column -->
        <ng-container matColumnDef="Rank">
            <th mat-header-cell *matHeaderCellDef >
                Rank
            </th>
            <td mat-cell *matCellDef="let record">{{ record.Rank }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
            <th appAuthorizeControl="admin||offermang" mat-header-cell *matHeaderCellDef>Actions</th>
            <td appAuthorizeControl="admin||offermang" mat-cell *matCellDef="let record">
                <a [routerLink]="[record.Detail.Id]">Edit Rank</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.products.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>

    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.products.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

</ng-container>
