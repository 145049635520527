<form *ngIf="coupon$ | async as CouponDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div class="d-flex">
                <h2>{{formGroup.controls.Detail.controls.Summary.value }}</h2>
            </div>
            <div fxFlex="50" class="d-flex flex-end">
                <div *ngIf="couponId != '0'" style="top: 17px; position: relative; margin-right: 0;" [ngStyle]="{'top': canEdit() ? '8px': '0'}">
                    <span  [matMenuTriggerFor]="menu" class="status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" class="statusApproved tab-status" [disabled]="formGroup.controls.Detail.controls.Status.value === 'Approved'" mat-menu-item (click)="updateStatus('Approved')">Approved</button>
                    <button type="button" mat-menu-item class="statusPending tab-status" [disabled]="formGroup.controls.Detail.controls.Status.value === 'Pending'" (click)="updateStatus('Pending')">Pending</button>
                    <button type="button" class="statusSuppressed tab-status" mat-menu-item [disabled]="formGroup.controls.Detail.controls.Status.value === 'Suppressed'" (click)="updateStatus('Suppressed')">Suppressed</button>
                  </mat-menu>

                <mat-card-actions fxLayout="row" [fxLayoutAlign]="couponId != '0'?'space-between':'end'" >
                    <div class="btn-width">
                        <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                            <span *ngIf="!canEdit()">Close</span>
                            <span *ngIf="canEdit()">Cancel</span>
                        </button>
                        <button *ngIf="canEdit()" class="btn-right" mat-raised-button type="button" (click)="save()" [disabled]="formGroup.invalid" color="accent">
                            Save
                        </button>
                    </div>
                </mat-card-actions>
            </div>
            </div>


            <div formGroupName="Detail" class="CouponDetails">
                <mat-card-title>Coupon Details</mat-card-title>

                <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px"  fxLayout.lt-md="column" >
                            <mat-card appearance="outlined" fxFlex="25" fxFlexFill fxLayout="column"  fxLayoutAlign="space-around center"  class="marginTop marginBottom mat-card-offer">

                                <ng-container *ngIf="!couponId || couponId == '0' || !couponResponse?.PreviewHtml; else couponPreviewHTML">
                                    <img mat-card-md-image src="./assets/images/default-offer-image.png"
                                        alt="Offer image">
                                    <div style="text-align: center; padding:2rem 0;">Preview not available</div>
                                </ng-container>
                                <ng-template #couponPreviewHTML>
                                    <div [innerHTML]="couponResponse?.PreviewHtml"></div>
                                </ng-template>
                            </mat-card>
                            <div fxLayout="column" fxFlex="auto" class="marginTop">
                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" >
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Coupon Type</mat-label>
                                        <mat-select [disabled]="!canEdit()" formControlName="CouponType">
                                            <mat-option value="BOGOfree">BOGO free</mat-option>
                                            <mat-option value="DollarOff">Dollar Off</mat-option>
                                            <mat-option value="PercentOff">Percent Off</mat-option>
                                            <mat-option [value]="noneValue">None</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.CouponType.hasError('required')">
                                            Coupon Type is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                    <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" >
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Coupon Value</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="number" formControlName="CouponValue" />
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.CouponValue.hasError('required')">
                                            Coupon value is required
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.CouponValue.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.CouponValue.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.CouponValue.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Summary</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="text" formControlName="Summary" />
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.Summary.hasError('required')">
                                            Summary is required
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.Summary.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.Summary.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.Summary.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Description</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="text" formControlName="Description" />
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.Description.hasError('required')">
                                            Description is required
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.Description.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.Description.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.Description.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" >
                                        <mat-label>Type of Code:</mat-label>
                                        <mat-radio-group class="sort-radio-group" aria-label="Sorting By" formControlName="PreferredCouponCodeType">
                                        <mat-radio-button class="sort-radio-button" [value]="couponCodeType.GS1_BarCode"
                                            [labelPosition]="'after'" aria-label="GS1 Barcode">
                                            GS1 Barcode
                                        </mat-radio-button>
                                        <mat-radio-button class="sort-radio-button" [value]="couponCodeType.BarCode"
                                            [labelPosition]="'after'" aria-label="Barcode">
                                            Barcode
                                        </mat-radio-button>
                                        <mat-radio-button class="sort-radio-button" [value]="couponCodeType.QR_Code"
                                            [labelPosition]="'after'" aria-label="Qr Code">
                                            QR Code
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <!-- <button class="btn-right" mat-raised-button type="button" color="accent">Generate</button>
                                    <button class="btn-right" mat-raised-button type="button">Reset</button> -->
                                </div>

                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                                    <mat-form-field appearance="outline"  fxFlex>
                                        <mat-label>Active Date</mat-label>
                                        <input  matInput [matDatepicker]="activeDatePicker" formControlName="ActiveDate" [disabled]="!canEdit()">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.ActiveDate.hasError('required')">
                                            Active date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.ActiveDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.ActiveDate.errors!['matDatepickerMax'] && 'Start Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="activeDatePicker" [disabled]="!canEdit()">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #activeDatePicker [disabled]="!canEdit()"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>Redemption start Date</mat-label>
                                        <input [disabled]="!canEdit()" matInput [matDatepicker]="RdemptionStartDatePicker" formControlName="RedemptionStartDate">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.RedemptionStartDate.hasError('required')">
                                            End date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.RedemptionStartDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.RedemptionStartDate.errors!['matDatepickerMax'] && 'End Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="RdemptionStartDatePicker" [disabled]="!canEdit()"></mat-datepicker-toggle>
                                        <mat-datepicker #RdemptionStartDatePicker [disabled]="!canEdit()"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>Expiry Date</mat-label>
                                        <input [disabled]="!canEdit()" matInput [matDatepicker]="ExpiryDatePicker" formControlName="ExpiryDate">
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                            Expiry date is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.ExpiryDate.errors!['matDatepickerMax'] && 'Expiry Date exceeded maximum year.'}}
                                        </mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="ExpiryDatePicker" [disabled]="!canEdit()"></mat-datepicker-toggle>
                                        <mat-datepicker #ExpiryDatePicker [disabled]="!canEdit()"></mat-datepicker>
                                    </mat-form-field>

                                </div>
                                <div fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Purchase unit</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="text" formControlName="PurchaseUnit" />
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                            Purchase unit is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.PurchaseUnit.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.PurchaseUnit.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.PurchaseUnit.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Reward unit</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="text" formControlName="RewardUnit" />
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.RewardUnit.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.RewardUnit.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.RewardUnit.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33">
                                        <mat-label>Activication limit</mat-label>
                                        <input [readonly]="!canEdit()" matInput type="number" min="0" formControlName="ActivationLimit" />
                                        <mat-error
                                            *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                            Activication limit is required.
                                        </mat-error>
                                        <mat-error *ngIf="formGroup.controls.Detail.controls.ActivationLimit.status=='INVALID'">
                                            {{formGroup.controls.Detail.controls.ActivationLimit.errors!['maxlength'] && 'Maximum length can be '+formGroup.controls.Detail.controls.ActivationLimit.errors!['maxlength']['requiredLength'] + '.'}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                </div>
                <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Fine Print</mat-label>
                                <textarea matInput placeholder="Fine print..." formControlName="FinePrint"></textarea>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.FinePrint.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.FinePrint.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.FinePrint.errors!['maxlength']['requiredLength'] + '.'}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Disclaimer</mat-label>
                                <textarea matInput placeholder="Disclaimer..." formControlName="Disclaimer"></textarea>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.Disclaimer.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Detail.controls.Disclaimer.errors!['maxlength']['requiredLength'] + '.'}}
                                </mat-error>
                            </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                            <mat-form-field appearance="outline" fxFlex="48">
                                <mat-label>Featured text</mat-label>
                                <input matInput type="text" formControlName="FeaturedText">
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                    Featured text is required.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Target Type</mat-label>
                                <mat-select [disabled]="!canEdit()" formControlName="TargetType">
                                    <mat-option value="Recommended">Recommended</mat-option>
                                    <mat-option value="Targeted">Targeted</mat-option>
                                    <mat-option [value]="noneValue">None</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Discount Type</mat-label>
                                <mat-select [disabled]="!canEdit()" formControlName="DiscountType">
                                    <mat-option value="ITEM_LEVEL">Item Level</mat-option>
                                    <mat-option value="BASKET_LEVEL">Basket Level</mat-option>
                                    <mat-option [value]="noneValue">None</mat-option>
                                </mat-select>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                    Discount Type is required.
                                </mat-error>
                            </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutGap.gt-sm="8px" fxLayout.lt-md="column">
                            <mat-form-field appearance="outline" fxFlex="24">
                                <mat-label>Purchase conditions</mat-label>
                                <input matInput type="text" formControlName="PurchaseConditions">
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                    Purchase conditions is required.
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="24">
                                <mat-label>Reward conditions</mat-label>
                                <input matInput type="text" formControlName="RewardConditions">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="24" *ngIf="couponId !== '0'">
                                <mat-label>Coupon ID</mat-label>
                                <input matInput type="text" formControlName="CouponID" readonly [disabled]="true" [readonly]="true">
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="24">
                                <mat-label>Coupon Client key</mat-label>
                                <input matInput type="text" formControlName="ClientKey">
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                    Coupon Client key is required.
                                </mat-error>
                            </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutGap.gt-sm="8px" fxLayout.lt-md="column">
                            <mat-form-field appearance="outline" fxFlex="24">
                                <mat-label>Coupon source</mat-label>
                                <input matInput type="text" formControlName="CouponSource">
                                <mat-error *ngIf="formGroup.controls.Detail.controls.ExpiryDate.hasError('required')">
                                    Coupon source is required.
                                </mat-error>
                            </mat-form-field>
                            <mat-radio-group class="sort-radio-group" aria-label="Sorting By" fxFlex="24" formControlName="IsAutoActivated">
                                <mat-label class="coupon-label">Is AutoActivated:</mat-label>
                                <div class="custom-radio">
                                <mat-radio-button class="sort-radio-button" [value]="true"
                                    [labelPosition]="'after'" aria-label="Yes">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button class="sort-radio-button" [value]="false"
                                    [labelPosition]="'after'" aria-label="No">
                                    No
                                </mat-radio-button>
                                </div>
                            </mat-radio-group>
                            <mat-radio-group class="sort-radio-group" aria-label="Sorting By" fxFlex="24" formControlName="IsVisible">
                                <mat-label class="coupon-label">Visible:</mat-label>
                                <div class="custom-radio">
                                <mat-radio-button class="sort-radio-button" [value]="true"
                                    [labelPosition]="'after'" aria-label="Yes">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button class="sort-radio-button" [value]="false"
                                    [labelPosition]="'after'" aria-label="No">
                                    No
                                </mat-radio-button>
                                </div>
                            </mat-radio-group>
                            <mat-radio-group class="sort-radio-group" aria-label="Sorting By" fxFlex="24" formControlName="HasExtendedRequirements">
                                <mat-label class="coupon-label">Has extended requirements:</mat-label>
                                <div class="custom-radio">
                                <mat-radio-button class="sort-radio-button" [value]="true"
                                    [labelPosition]="'after'" aria-label="Yes">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button class="sort-radio-button" [value]="false"
                                    [labelPosition]="'after'" aria-label="No">
                                    No
                                </mat-radio-button>
                                </div>
                            </mat-radio-group>
                            <mat-form-field appearance="outline" fxFlex="25" *ngIf="couponId !== '0'">
                                <mat-label class="coupon-label">Updated on</mat-label>
                                <input matInput [matDatepicker]="updatedDatePicker" formControlName="UpdatedOn">
                                <mat-error
                                    *ngIf="formGroup.controls.Detail.controls.UpdatedOn.hasError('required')">
                                    End date is required.
                                </mat-error>
                                <mat-error *ngIf="formGroup.controls.Detail.controls.UpdatedOn.status=='INVALID'">
                                    {{formGroup.controls.Detail.controls.UpdatedOn.errors!['matDatepickerMax'] && 'End Date exceeded maximum year.'}}
                                </mat-error>
                                <mat-datepicker-toggle matSuffix [for]="updatedDatePicker" ></mat-datepicker-toggle>
                                <mat-datepicker #updatedDatePicker ></mat-datepicker>
                            </mat-form-field>
                </div>

            </div>
        </mat-card-content>
        <mat-card-actions fxLayout="row" [fxLayoutAlign]="couponId != '0' ?'space-between':'end'">
            <button appAuthorizeControl="edit||offermang" mat-raised-button type="button" color="warn" (click)="deleteBaseRecord()"  *ngIf="couponId != '0'">
                Delete
            </button>
            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" fxLayoutAlign="end" class="btn-width">
                <div [ngClass]="{'mydisable': formGroup.invalid}" *ngIf="couponId != '0'" style="top: 15px; position: relative; margin-right: 0;" [ngStyle]="{'top': canEdit() ? '0px': '0'}" class="no-space">
                    <span [matMenuTriggerFor]="canEdit() ? menu: null" class=" status {{formGroup.controls.Detail.controls.Status.value === '0' ? 'statusPENDING' :  'status' + formGroup.controls.Detail.controls.Status.value}}">
                        {{(formGroup.controls.Detail.controls.Status && formGroup.controls.Detail.controls.Status.value === '0') ? 'PENDING' : formGroup.controls.Detail.controls.Status.value }} <mat-icon *ngIf="canEdit()" class="icon">arrow_drop_down</mat-icon> </span>
                </div>
                <mat-menu #menu="matMenu">
                    <button type="button" class="statusApproved tab-status" [disabled]="formGroup.controls.Detail.controls.Status.value === 'Approved'" mat-menu-item (click)="updateStatus('Approved')">Approved</button>
                    <button type="button" mat-menu-item class="statusPending tab-status" [disabled]="formGroup.controls.Detail.controls.Status.value === 'Pending'" (click)="updateStatus('Pending')">Pending</button>
                    <button type="button" class="statusSuppressed tab-status" [disabled]="formGroup.controls.Detail.controls.Status.value === 'Suppressed'" mat-menu-item (click)="updateStatus('Suppressed')">Suppressed</button>
                  </mat-menu>
                <button class="btn-right" mat-stroked-button type="button" (click)="cancel()">
                    <span *ngIf="canEdit()">Cancel</span>
                    <span *ngIf="!canEdit()">Close</span>
                </button>
                <button *ngIf="canEdit()" class="btn-right" [disabled]="formGroup.invalid" mat-raised-button type="button" (click)="save()"  color="accent">
                    Save
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
