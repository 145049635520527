import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, map, Observable } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { OfferPromo, PromoDomain, OfferPromoService, OfferService, Offer, OfferDomain } from '../..';
import { Status } from '../../models/offer-promo-status';
import { AuthorizeControlService } from 'pr1-ui-components';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

const log = new Logger('VersionPromoCardsComponent');

@Component({
  selector: 'app-version-promo-cards',
  templateUrl: './version-promo-cards.component.html',
  styleUrls: ['./version-promo-cards.component.scss'],
})
export class VersionPromoCardsComponent<
  TOfferPromo extends OfferPromo,
  TPromoDomain extends PromoDomain<OfferPromo>,
  T extends Offer,
  TOfferDomain extends OfferDomain<Offer>
> implements OnInit {
  private assetsUrl: string = '';
  viewModel$ = combineLatest([
    this.offerpromoService.getpromoChildVersions$,
    this.offerpromoService.isLoading$,
    this.offerpromoService.totalVersionsRecords$,
    this.offerpromoService.page$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([offers, isLoading, totalRecords, page, assetsUrl]) => {
      if (offers.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0;
        page.pageIndex = 0;
        this.offerService.page(page);
        this.offerService.reload();
      }
      offers.map((x: any) => this.addVersion(x));
      this.assetsUrl = assetsUrl;
      return { offers, isLoading, totalRecords, page, assetsUrl };
    })
  );
  form = new FormGroup({
    versions: new FormArray([])
  });
  promoVersionsList: TPromoDomain[] = [];
  assetsUrl$: Observable<string> = this.organizationService.assetsUrl$;
  selection = new SelectionModel<TPromoDomain>(true, []);
  versionsList: any[] = [];

  constructor(
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private organizationService: OrganizationService,
    private authorizationService: AuthorizeControlService,
    public offerService: OfferService<T, TOfferDomain>,
    private router: Router,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    log.debug('init');
    // this.offerpromoService.reload();
  }

  onSort(sortState: Sort): void {
    this.offerService.sort(sortState);
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.offerService.page(pageEvent);
  }

  onEdit(id: string){
    this.router.navigate([`../../../${id}/promos/promodetails`], { relativeTo: this.route });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.promoVersionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.promoVersionsList.forEach((row) => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = JSON.parse(
      JSON.stringify(this.getSelectedSectionRecords())
    );
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateTableStatus(status: Status, row: any) {
    let selectedRecordsDetails: any;
    selectedRecordsDetails = [JSON.parse(JSON.stringify(row))];
    this.updateRecordsStatus(selectedRecordsDetails, status);
  }

  updateRecordsStatus(selectedRecordsDetails: Array<any>, status: any) {
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      selectedRecordsDetails = selectedRecordsDetails.map((x: any) => {
        x.Detail.Status = status;
        return x.Detail;
      });
      this.offerpromoService.updateStatus(selectedRecordsDetails).subscribe({
        next: () => {
          this.offerService.reload();
          this.offerpromoService.reload();
          this.clearSelection();
          this.offerpromoService.updateRecordStatus(new Date());
        },
      });
    }
  }

  public getImageSrc(assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    } else {
      imagesrc = this.assetsUrl + '/' + assets.FileName;
    }
    return imagesrc;
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    console.log(`hasAccess: `, hasAccess);
    return hasAccess;
  }

  getVersionNames(offer: PromoDomain<OfferPromo>) {
    let versionNames = [{ Id: offer.Detail.Id, Version: offer.Detail.Version }];
    versionNames = [...versionNames, ...offer.Versions];
    return versionNames;
  }

  getSelectedVersionDetail(event: any, offer: any) {
    offer.activeItem = false;
    offer.Versions = offer.Versions.map((x: any) => x.activeItem = false);
    if (event.value && offer) {
      if (event.value.Id === offer.Detail.Id) {
        offer.activeItem = true;
        return;
      }
      const offerVersion = offer.Versions.find((x: any) => x.Id === event.value.Id);
      if (offerVersion) {
        offerVersion.activeItem = true;
      }
    }
  }

  getActiveOffer(offer: any) {
    if (offer.activeItem) {
      return offer;
    }
    const versionOffer = offer.Versions.find((x: any) => x.activeItem === true);
    return versionOffer;
  }

  get versions(): FormArray {
    return this.form.get('versions') as FormArray;
  }
  addVersion(offer: any) {
    if (this.versions) {
      const formcontrolInfo = new FormControl(offer);
      formcontrolInfo.disable();
      this.versions.push(formcontrolInfo);
    }
  }
}
