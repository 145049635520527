import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { Filter } from 'src/app/@shared/models/filter.model';
import { Asset } from '..';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})

export class AssetsService<T extends Asset> {

  // initialize behavior subjects
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'DateAdded', direction: 'desc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private filterBehaviorSubject = new BehaviorSubject<Filter[]>([]);
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');
  private assetIdBehaviorSubject = new BehaviorSubject<string>('');
  private offerIdBehaviorSubject = new BehaviorSubject<string>('');
  private promoIdBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private assetSearchBehaviorSubject = new BehaviorSubject<string>('');
  private assetPromoSearchBehaviorSubject = new BehaviorSubject<string>('');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public filters$ = this.filterBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public assetId$ = this.assetIdBehaviorSubject.asObservable();
  public offerId$ = this.offerIdBehaviorSubject.asObservable();
  public promoId$ = this.promoIdBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public assetSearch$ = this.assetSearchBehaviorSubject.asObservable();
  public assetPromoSearch$ = this.assetPromoSearchBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.assetIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.filterBehaviorSubject.pipe(debounceTime(50)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([assetId, page, sort, search, filters, reload]) => {
      let _orderby = `${sort.active} ${sort.direction}`;
      if (sort.active == 'FileName') {
        _orderby = `Detail/${sort.active} ${sort.direction}`;
      }

      // set the query string parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
          $skip: page.pageIndex * page.pageSize,
          $top: page.pageSize,
          $orderby: _orderby,
          $count: true,
          $expand: 'Detail'
        }
      });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      // if there are filters, add the filters to the parameters
      if (filters.length > 0) {
        params = this.buildFilterParams(filters, params);
      }

      return params;
    })
  );

  public dialogParams$ = combineLatest([
    this.assetIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([assetId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        assetId: this.assetIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public eventAssetdialogParams$ = combineLatest([
    this.assetIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([assetId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        assetId: this.assetIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public versionAssetdialogParams$ = combineLatest([
    this.assetIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([assetId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        assetId: this.assetIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogAssetParams$ = combineLatest([
    this.offerIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.assetSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([offerId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        offerId: this.offerIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogAssetPromoParams$ = combineLatest([
    this.promoIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.assetPromoSearchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([promoId, page, sort, search, reload]) => {

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        promoId: this.promoIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: `${sort.active} ${sort.direction}`,
        $expand: 'Detail',
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set assetId(assetId: string) {
    this.assetIdBehaviorSubject.next(assetId);
  }
  set promoId(promoId: string) {
    this.promoIdBehaviorSubject.next(promoId);
  }
  set offerId(offerId: string) {
    this.offerIdBehaviorSubject.next(offerId);
  }
  // create the assets observable that calls http get when any of our parameters change
  private assetsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`, { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // create the assets observable that calls http get when any of our parameters change
  private allAssetsResponse$ = this.dialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // create the assets observable that calls http get when any of our parameters change
  private allAssetsForOffersResponse$ = this.dialogAssetParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetsByNotOfferId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  // create the assets observable that calls http get when any of our parameters change
  private allAssetsForPromoResponse$ = this.dialogAssetPromoParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetsByNotPromoId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

   // create the assets observable that calls http get when any of our parameters change
   private allEventAssetsResponse$ = this.eventAssetdialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

   // create the assets observable that calls http get when any of our parameters change
   private allVersionAssetsResponse$ = this.versionAssetdialogParams$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  uploadAsset(formData: any) {
    return this.httpClient.post(
      `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/Upload`,
      formData,
      { reportProgress: true, responseType: 'json' });
  }

  // assets listing
  public assets$: Observable<T[]> = this.assetsResponse$.pipe(
    map((res: any) => res.value)
  );

  // assets listing
  public allAssets$: Observable<T[]> = this.allAssetsResponse$.pipe(
    map((res: any) => res.value)
  );

  // assets listing
  public allAssetsForOffers$: Observable<T[]> = this.allAssetsForOffersResponse$.pipe(
    map((res: any) => res.value)
  );

  public allAssetsForPromos$: Observable<T[]> = this.allAssetsForPromoResponse$.pipe(
    map((res: any) => res.value)
  );

   // assets listing
   public allEventAssets$: Observable<T[]> = this.allEventAssetsResponse$.pipe(
    map((res: any) => res.value)
  );

   // assets listing
   public allVersionAssets$: Observable<T[]> = this.allVersionAssetsResponse$.pipe(
    map((res: any) => res.value)
  );

  deleteAsset(id: string) {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Assets/${id}`;
    return this.httpClient.delete(url);
  }

  // total number of asset records based on filtering
  public totalRecords$: Observable<number> = this.assetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of asset records based on filtering
  public assetTotalRecords$: Observable<number> = this.allAssetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );
  // total number of asset records based on filtering
  public allAssetsForOffersTotalRecords$: Observable<number> = this.allAssetsForOffersResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of asset records based on filtering
  public assetsPromoTotalRecords$: Observable<number> = this.allAssetsForPromoResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

   
   // total number of asset records based on filtering
   public EventAssetTotalRecords$: Observable<number> = this.allEventAssetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // total number of asset records based on filtering
  public VersionAssetTotalRecords$: Observable<number> = this.allVersionAssetsResponse$.pipe(
    map((res: any) => res['@odata.count'])
  );

  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }

  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogAssetSearch(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.assetSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // sets the search phrase
  dialogAssetPromoSearch(search: string) {
    const page = this.pageBehaviorSubject.value;
    page.pageIndex = 0;
    page.previousPageIndex = 0;
    this.assetPromoSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(page);
  }

  // reloads/refreshes the store listing
  reload() {
    // reload the Store data
    this.reloadBehaviorSubject.next(uuidv4());
  }

  // reset the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  getRecordsForFilters() {
    return this.httpClient.get(`${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/GetAssetTypes`);
  }

  // getListByFieldName for filters
  getListByFieldName(fieldName: string): Observable<T[]> {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/AssetDomains/Get?$apply=groupby((${fieldName}))`;
    return this.httpClient.get<T[]>(url);
  }

  // adds filters to the event listing
  addFilters(newFilters: Filter[]) {
    const filters = this.filterBehaviorSubject.value;

    newFilters.forEach(filter => {
      if (filters.findIndex(item => item.fieldName.toLowerCase() === filter.fieldName.toLowerCase() && item.value.toLowerCase() === filter.value.toLowerCase()) === -1) {
        filters.push(filter)
      }
    });

    this.filterBehaviorSubject.next(filters);
  }

  // removes a filter from the event listing
  removeFilter(filter: Filter) {
    const filters = this.filterBehaviorSubject.value.filter(item => item !== filter);
    this.filterBehaviorSubject.next(filters)
  }

  // removes a filter from the event listing
  removeFilterByFieldName(fieldName: string) {
    const filters = this.filterBehaviorSubject.value.filter(item => item.fieldName.toLowerCase() !== fieldName.toLowerCase());
    this.filterBehaviorSubject.next(filters)
  }

  // removes all filters for the event listing
  clearFilters() {
    this.filterBehaviorSubject.next([]);
  }

  // builds the filter parameters
  private buildFilterParams(filters: Filter[], params: HttpParams): HttpParams {

    // start date added
    const startDateFilter = filters.filter(item => item.fieldName.toLowerCase() === 'startdate');
    if (startDateFilter.length > 0) {
      startDateFilter[0].value =  dayjs(startDateFilter[0].value).format('MM/DD/YYYY'),
      params = params.append('startDateAdded', startDateFilter[0].value);
    }

    // end date added
    const endDateFilter = filters.filter(item => item.fieldName.toLowerCase() === 'enddate');
    if (endDateFilter.length > 0) {
      endDateFilter[0].value =  dayjs(endDateFilter[0].value).format('MM/DD/YYYY'),
      params = params.append('endDateAdded', endDateFilter[0].value);
    }

    // asset group names
    const groupNameFilters = filters.filter(item => item.fieldName.toLowerCase() === 'assetgroupname');
    groupNameFilters.forEach(groupName => {
      params = params.append('assetGroupIds', groupName.value);
    });

    // file types
    const fileTypeFilters = filters.filter(item => item.fieldName.toLowerCase() === 'type');
    fileTypeFilters.forEach(fileType => {
      params = params.append('fileTypes', fileType.value);
    });

    // return the filter parameters
    return params;
  }

  downloadAssetsJSON() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Assets/EtlExport`;
    window.open(url,'_blank');
  }

  downloadAssetsCSV() {
    const url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/Assets/Export`;
    window.open(url,'_blank');
  }
} 
