<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>
    <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
        <mat-card-content fxLayout="column">

            <!-- actions bar -->

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
                fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
                <h2>Add Versions</h2>
                <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                    <mat-label>Search Versions</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)" #searchInput/>
                    <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

            </div>

            <div class="grid-container">

            <!-- record listing table -->
            <table *ngIf="viewModel.eventversions.length"  mat-table [dataSource]="viewModel.eventversions" matSort (matSortChange)="onSort($event)" class="mat-elevation-z8">

                <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [disabled]="!viewModel.eventversions.length">
              </mat-checkbox>
            </th>
            <td class="no-ellipsis" mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="VersionName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Version">
                        Version Name
                    </th>
                    <td mat-cell *matCellDef="let record">{{ record.VersionName }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="onRowDoubleClick(row.Id)"></tr>
            </table>

            <table *ngIf="!viewModel.eventversions.length">
                <tr>
                    <td class="no-records" [attr.colspan]="displayedColumns.length">
                        <h2>No Records Found</h2>
                    </td>
                </tr>
            </table>
            </div>
            <!-- pager -->
            <mat-paginator [length]="viewModel.totalVersionsNotInOffer" [pageSize]="viewModel.page.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.eventversions.length"
                showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
            </mat-paginator>
        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayoutAlign="end">

            <div>
                <button mat-stroked-button type="button" (click)="closeDialog()">
                    Cancel
                </button>
                <button mat-raised-button type="button" color="accent" (click)="saveData()">
                    Select
                </button>
            </div>
        </mat-card-actions>

    </mat-card>
</form>
</ng-container>
