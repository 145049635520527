import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Filter } from 'src/app/@shared/models/filter.model';
import { CloneEventComponent, Event, EventService, OfferPromoSortDialogComponent, OfferTag, OfferTagService, Version, VersionListComponent, VersionService } from '../..';
import { OfferPromo } from 'src/app/modules/standard/v1';
import { PromoDomain } from 'src/app/modules/standard/v1';
import { OfferPromoService } from 'src/app/modules/standard/v1';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '../../models/offer-promo-status';
import { StatusCount } from '../../models/status-count.model';
import * as dayjs from 'dayjs';

const log = new Logger('OffersComponent');

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})

export class OffersComponent<TEvent extends Event, Tag extends OfferTag, TOfferPromo extends OfferPromo, TPromoDomain extends PromoDomain<OfferPromo>> implements OnInit {

  public offerSorting = [
    { id: { active: 'PromoName', direction: 'asc', }, value: 'Offer Name asc' },
    { id: { active: 'PromoName', direction: 'desc', }, value: 'Offer Name desc' },
    { id: { active: 'StartDate', direction: 'asc', }, value: 'Start date asc' },
    { id: { active: 'StartDate', direction: 'desc', }, value: 'Start date desc' },
    { id: { active: 'Rank', direction: 'asc', }, value: 'Rank asc' },
    { id: { active: 'Rank', direction: 'desc', }, value: 'Rank desc' },
  ]
  public offerpromos = [
    { name: 'Offers', value: 'OFFER' },
    { name: 'Promos', value: 'PROMO' }
  ];

  public statusCount: StatusCount = {};
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('offerPromoTable', { static: false }) offerPromoTable: any;
  @ViewChild('offerPromoCards', { static: false }) offerPromoCards: any;
  offerTags$ = this.offerTagService.getByEventId(this.route.snapshot.params['eventId'] || this.offerpromoService.eventId)
  viewModel$ = combineLatest([
    this.eventService.getEvent(this.route.snapshot.params['eventId']),
    this.offerpromoService.isLoading$,
    this.offerpromoService.viewMode$,
    this.offerpromoService.eventId$,
    this.offerpromoService.filters$,
    this.versionService.allVersions$,
    this.offerTags$
  ]).pipe(
    map(([event, isLoading, viewMode, eventId, filters, versions, offerTags]) => {
      return { event, isLoading, viewMode, filters, versions, eventId, offerTags }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl(),
    versions: new FormControl(),
    offerTags: new FormControl(),
    offerPromos: new FormControl()
  });

  selectedTag = ''


  constructor(
    private eventService: EventService<TEvent>,
    private offerpromoService: OfferPromoService<TOfferPromo, TPromoDomain>,
    private versionService: VersionService<Version>,
    private offerTagService: OfferTagService<Tag>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.offerpromoService.toggleViewMode('CARDS');
    this.offerpromoService.eventId = this.route.snapshot.params['eventId'];
    this.versionService.eventId = this.route.snapshot.params['eventId'];
    this.offerpromoService.page({ pageIndex: 0, pageSize: 10 });
    this.clearSearch();
    this.clearFilters();
    this.offerpromoService.statusUpdated$.subscribe(res => {
      this.getStatusCount();
    })
    // this.offerpromoService.sort(this.offerSorting[0].id);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CloneEventComponent, {
      width: '40%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId']}
    });
  }

  getStatusCount() {
    this.offerpromoService.getStatusCount().subscribe((count) => {
      this.statusCount = count;
    })
  }

  onSearch(event: any) {
    this.offerpromoService.search(event.target.value);
  }

  onSort() {
    const dialogRef = this.dialog.open(OfferPromoSortDialogComponent);
  }
  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.offerpromoService.search('');
  }

  toggleView(mode: string) {
    this.offerpromoService.toggleViewMode(mode);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.offerpromoService.clearFilters();
  }

  removeFilter(filter: Filter) {
    if (filter.fieldName === 'versionId') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        versions: this.filtersForm.controls.versions.value.filter((item: Version) => item.Id !== filter.value)
      });
    }
    this.offerpromoService.removeFilter(filter);
  }

  onFilterChange(event: any) {
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'versions':
        // get the selected version ids 
        const versionValues: Version[] = event.value;
        // remove any existing version id filters
        this.offerpromoService.removeFilterByFieldName('versionId');
        // init the array of version filters
        const versionFilters: Filter[] = [];
        // loop through the selected version ids and add each as a filter
        versionValues.forEach(value => {
          // init the new filter
          const versionFilter: Filter = { displayText: value.VersionName, fieldName: 'versionId', value: value.Id };
          // add the filter to the list of filters
          versionFilters.push(versionFilter)
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(versionFilters);
        break;
      case 'offertags':
        // get the selected offerTag ids 
        const offerTagValues: OfferTag[] = event.value;
        //remove any existing offer tags filter  
        this.offerpromoService.removeFilterByFieldName('offerTagId');
        // init the array of offerTag filters
        const offerTagFilters: Filter[] = [];
        // loop through the selected offerTag ids and add each as a filter
        offerTagValues.forEach(value => {
          // init the new filter
          const offerTagFilter: Filter = { displayText: value.OfferTagName, fieldName: 'offerTagId', value: value.Id };
          // add the filter to the list of filters
          offerTagFilters.push(offerTagFilter)
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(offerTagFilters);
        break;
      case 'offerpromos':
        // get the selected offerTag ids 
        const eventtypeValues: PromoDomain<any>[] = event.value;
        //remove any existing offer tags filter  
        this.offerpromoService.removeFilterByFieldName('eventtype');
        // init the array of offerTag filters
        const eventtypeFilters: Filter[] = [];
        // loop through the selected offerTag ids and add each as a filter
        eventtypeValues.forEach((value: any) => {
          // init the new filter
          const eventtypeFilter: Filter = { displayText: value.name, fieldName: 'eventtype', value: value.value };
          // add the filter to the list of filters
          eventtypeFilters.push(eventtypeFilter)
        });
        // add the filters to the event service
        this.offerpromoService.addFilters(eventtypeFilters);
        break;
    }
  }

  resetFilters() {
    this.filtersForm.reset();
    this.offerpromoService.search('')
    this.offerpromoService.clearFilters();
    this.sidenav.toggle();
  }

  deleteSelectedRecords() {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = this.offerPromoTable.getSelectedSectionRecords();
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = this.offerPromoCards.getSelectedSectionRecords();
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {

      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove selected Offer/Promo?`,
        },
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            let bulkDelete: any = [];
            for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
              const activeVersion = selectedRecordsDetails[index].Versions.find((x: any) => x.activeItem == true);
              if (!activeVersion || (activeVersion && activeVersion.Id === selectedRecordsDetails[index].DomainId)) {
                const recordstoupdate = selectedRecordsDetails[index].Versions.map((x: any) => {
                  return {
                    Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
                    Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
                    EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: x.Status, Version: x.Version,
                    Label: x.Label
                  }
                });
                bulkDelete = [...bulkDelete, ...recordstoupdate];
              } else if (activeVersion && activeVersion.Id != selectedRecordsDetails[index].DomainId) {
                const x = activeVersion;
                bulkDelete.push({
                  Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
                  Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
                  EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: x.Status, Version: x.Version,
                  Label: x.Label
                });
              }

            }
            this.offerpromoService.deleteOfferPromos(bulkDelete).subscribe({
              next: () => {
                this.matSnackBar.open(`Offer/Promo(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.offerpromoService.reload();
                if (this.offerPromoTable) {
                  this.offerPromoTable.clearSelection();
                } else if (this.offerPromoCards) {
                  this.offerPromoCards.clearSelection();
                }
              },
              error: (error) => {
                log.error('Error in deleting Offer/Promo', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadOffersJSON() {
    this.offerpromoService.downloadOffersJSON(this.route.snapshot.params['eventId']);
  }

  updateStatus(status: Status) {
    let selectedRecordsDetails: any;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoTable.getSelectedSectionRecords()));
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoCards.getSelectedSectionRecords()));
    }
    if (selectedRecordsDetails && selectedRecordsDetails.length > 0) {
      let statustoUpdate: any = [];
      for (let index = 0; index <= selectedRecordsDetails.length - 1; index++) {
        const activeVersion = selectedRecordsDetails[index].Versions.find((x: any) => x.activeItem == true);
        if (!activeVersion || (activeVersion && activeVersion.Id === selectedRecordsDetails[index].DomainId)) {
          const recordstoupdate = selectedRecordsDetails[index].Versions.map((x: any) => {
            return {
              Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
              Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
              EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: status, Version: x.Version,
              Label: x.Label
            }
          });
          statustoUpdate = [...statustoUpdate, ...recordstoupdate];
        } else if (activeVersion && activeVersion.Id != selectedRecordsDetails[index].DomainId) {
          const x = activeVersion;
          statustoUpdate.push({
            Id: x.Id, ClientKey: x.ClientKey, UserId: x.UserId, Name: x.Name, Headline: x.Headline, BodyCopy: x.BodyCopy,
            Disclaimer: x.Disclaimer, ClientComment: x.ClientComment, StartDate: dayjs(x.StartDate).format('MM/DD/YYYY'),
            EndDate: dayjs(x.EndDate).format('MM/DD/YYYY'), Rank: x.Rank, TargetURL: x.TargetURL, Status: status, Version: x.Version,
            Label: x.Label
          });
        }

      }

      this.offerpromoService.updateStatus(statustoUpdate).subscribe({
        next: () => {
          this.offerpromoService.reload();
          if (this.offerPromoTable) {
            this.offerPromoTable.clearSelection();
          } else if (this.offerPromoCards) {
            this.offerPromoCards.clearSelection();
          }
          this.getStatusCount();
        }
      })
    }
  }

  downloadPromosJSON() {
    this.offerpromoService.downloadPromosJSON(this.route.snapshot.params['eventId']);
  }

  downloadEventJSON() {
    this.offerpromoService.downloadEventJSON(this.route.snapshot.params['eventId']);
  }

  downloadCSV() {
    this.offerpromoService.downloadCSV(this.route.snapshot.params['eventId']);
  }

  searchOfferTags(event: any) {
    this.offerTagService.search(event.target.value)
  }

  tagSelected(event: any) {
    this.offerpromoService.removeFilterByFieldName('offerTagId');
    this.selectedTag = event.option.value.OfferTagName;
    this.filtersForm['controls']['offerTags'].setValue(event.option.value.Id)
    const offerTagFilters: Filter[] = [{ displayText: event.option.value.OfferTagName, fieldName: 'offerTagId', value: event.option.value.Id }]
    // add the filters to the event service
    this.offerpromoService.addFilters(offerTagFilters);
  }
  
  openVersionsDialog(): void {
    let selectedRecordsDetails;
    if (this.offerPromoTable) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoTable.getSelectedSectionRecords()));
    } else if (this.offerPromoCards) {
      selectedRecordsDetails = JSON.parse(JSON.stringify(this.offerPromoCards.getSelectedSectionRecords()));
    }
    const dialogRef = this.dialog.open(VersionListComponent, {
      width: '80%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId'], selectedRecordsDetails }
    });
    this.versionService.search('');
    this.route.params.subscribe(params => {
      this.versionService.eventId = params['eventId'];
    }); 
  }

}
