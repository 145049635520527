import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent, Logger } from 'src/app/@shared';
import { Version, VersionPromoService, PromoAddVersionsComponent, PromoDomain, OfferPromo, OfferPromoService } from '../..';
import { combineLatest, map, Subscription, distinctUntilChanged } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthorizeControlService } from 'pr1-ui-components';

const log = new Logger('PromoVersionsComponent');

@Component({
  selector: 'app-promo-versions',
  templateUrl: './promo-versions.component.html',
  styleUrls: ['./promo-versions.component.scss'],
})

export class PromoVersionsComponent implements OnInit, OnDestroy {
  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  
  viewModel$ = combineLatest([
    this.versionPromoService.versions$,
    this.versionPromoService.isLoading$,
    this.versionPromoService.totalRecords$,
    this.versionPromoService.page$,
    this.versionPromoService.promoId$,
    this.offerPromoService.offerPromos$
  ]).pipe(
    map(([versions, isLoading, totalRecords, page, promoId, promos]) => {
      if (versions.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.versionPromoService.page(page);
        this.versionPromoService.reload();
      }
      return { versions, isLoading, totalRecords, page, promoId, promos }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'VersionName'];
  versionsList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  constructor(
    private versionPromoService: VersionPromoService,
    private offerPromoService: OfferPromoService<OfferPromo, PromoDomain<OfferPromo>>,
    private mediaObserver: MediaObserver,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizeControlService) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.versionPromoService.reload();
    this.versionPromoService.promoId = this.route.snapshot.params['offerId'];

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'VersionName'];
        }
        else {
          this.displayedColumns = ['select', 'VersionName'];
        }
      });

      this.versionPromoService.versions$.subscribe((data) => {
        this.versionsList = data;
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PromoAddVersionsComponent, {
      width: '80%',
      height: '90%',
      data: { eventId: this.route.snapshot.params['eventId'] }
    });
    this.versionPromoService.dialogSearch('');
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  disassociateVersions() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let versions: string[] = this.selection.selected.map(x => x.Id);
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove from version?`,
        },
        disableClose: true,
      });
  
      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.versionPromoService.disassociateVersion(versions).subscribe({
              next: () => {
                this.matSnackBar.open(`Version deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.selection.clear();
                this.versionPromoService.reload();
                this.selection.clear();
                this.router.navigate([`../`, `versions`], { relativeTo: this.route });
              },
              error: (error) => {
                log.error('Error adding store to version', error);
  
                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  onSearch(event: any) {
    this.versionPromoService.search(event.target.value);
  }

  getPromoName(promos: any) {
    if (promos && promos.length > 0) {
      const promoDetail = promos.find((x: PromoDomain<OfferPromo>) => x.DomainId === this.route.snapshot.params['offerId']);
      if (promoDetail && promoDetail.Detail) {
        return promoDetail.Detail.Name;
      } else {
        return '';
      }
    }
    return '';
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionPromoService.search('');
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionPromoService.page(pageEvent);
  }

  onSort(sortState: Sort): void {
    this.versionPromoService.sort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionsList.forEach(row => this.selection.select(row));
  }

  cancel(): void {
    this.versionPromoService.reload();
    this.router.navigate([`../../../`], { relativeTo: this.route });
  }

  canEdit(): boolean {
    const hasAccess = this.authorizationService.checkAccess('edit||offermang');
    return hasAccess;
  }

}
function dialogData(dialogData: any): MatDialog {
  throw new Error('Function not implemented.');
}

