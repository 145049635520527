<h1>Store Management</h1>

<form *ngIf="store$ | async as T; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">

            <h2>{{formGroup.controls.StoreName.value == '' ? "Store" : formGroup.controls.StoreName.value}}</h2>

            <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Store Name</mat-label>
                    <input matInput type="text" formControlName="StoreName" />
                    <mat-error *ngIf="formGroup.controls.StoreName.hasError('required')">
                        Store Name is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.StoreName.hasError('invalid')">
                        {{formGroup.controls.StoreName.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.StoreName.status=='INVALID'">
                        {{formGroup.controls.StoreName.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.StoreName.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Address 1</mat-label>
                    <input matInput type="text" formControlName="Address1" />
                    <mat-error *ngIf="formGroup.controls.Address1.hasError('invalid')">
                        {{formGroup.controls.Address1.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Address1.status=='INVALID'">
                        {{formGroup.controls.Address1.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Address1.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Address 2</mat-label>
                    <input matInput type="text" formControlName="Address2" />
                    <mat-error *ngIf="formGroup.controls.Address2.hasError('invalid')">
                        {{formGroup.controls.Address2.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Address2.status=='INVALID'">
                        {{formGroup.controls.Address2.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.Address2.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>City</mat-label>
                    <input matInput type="text" formControlName="City" />
                    <mat-error *ngIf="formGroup.controls.City.hasError('invalid')">
                        {{formGroup.controls.City.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.City.status=='INVALID'">
                        {{formGroup.controls.City.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.City.errors!['maxlength']['requiredLength'] + '.'}}
                        {{formGroup.controls.City.errors!['pattern'] && 'City may only contain alphanumeric characters.'}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>State</mat-label>
                    <input matInput type="text" formControlName="State" />
                    <mat-error *ngIf="formGroup.controls.State.hasError('invalid')">
                        {{formGroup.controls.State.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.State.status=='INVALID'">
                        {{formGroup.controls.State.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.State.errors!['maxlength']['requiredLength'] + '.'}}
                        {{formGroup.controls.State.errors!['pattern'] && 'State may only contain alphanumeric characters. '}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Postal Code</mat-label>
                    <input matInput type="text" formControlName="PostalCode" />
                    <mat-error *ngIf="formGroup.controls.PostalCode.hasError('invalid')">
                        {{formGroup.controls.PostalCode.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.PostalCode.status=='INVALID'">
                        {{formGroup.controls.PostalCode.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.PostalCode.errors!['maxlength']['requiredLength'] + '.'}}
                        {{formGroup.controls.PostalCode.errors!['pattern'] && 'Postal Code may only contain alphanumeric characters. '}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="storeId != '0'" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Id</mat-label>
                    <input matInput formControlName="Id" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="32" fxFlex.lt-md="auto">
                    <mat-label>Client Key</mat-label>
                    <input matInput type="text" formControlName="ClientKey" />
                    <mat-error *ngIf="formGroup.controls.ClientKey.hasError('required')">
                        Client key is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.ClientKey.hasError('invalid')">
                        {{formGroup.controls.ClientKey.getError('invalid')}}
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.ClientKey.status=='INVALID'">
                        {{formGroup.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be' + formGroup.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
                    </mat-error>
                </mat-form-field>

            </div>
        </mat-card-content>
        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="storeId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="storeId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="reset" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid"
                        color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>