<form *ngIf="assets$ | async as AssetDetails; else loading" [formGroup]="formGroup" novalidate>
    <mat-card appearance="outlined">
        <mat-card-content fxLayout="column">
            <h2 class="title">{{AssetDetails?.AssetName}}</h2>

            <div formGroupName="detail">
                <mat-accordion class="AssetDetails" multi>
                    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                        <mat-card appearance="outlined" fxFlex="15" fxFlexFill fxLayoutAlign="center" class="marginTop">
                            <!-- <img mat-card-md-image
                                *ngIf="AssetDetails?.Detail?.FileName && assetsUrl$ | async as assetsUrl; else productIcon"
                                src="{{assetsUrl}}/{{AssetDetails?.Detail?.FileName }}" alt="product image">
                            <ng-template #productIcon>
                                <mat-icon>image</mat-icon>
                            </ng-template> -->
                            <ng-container *ngIf="AssetDetails?.Detail?.FileName && assetsUrl$ | async as assetsUrl">
                                <img mat-card-md-image [src]="getImageSrc(assetsUrl, AssetDetails)" alt="Offer image">
                            </ng-container>
                            <img mat-card-md-image *ngIf="!AssetDetails.Detail.FileName"
                                src="./assets/images/default-image.png" alt="Offer image">
                        </mat-card>
                        <div fxLayout="row" fxFlex="auto" fxLayoutGap="30px" class="marginTop">
                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>Asset Name</mat-label>
                                <input matInput type="text" formControlName="assetName" />
                                <mat-error *ngIf="formGroup.controls.detail.controls.assetName.hasError('required')">
                                    Asset Name is required
                                </mat-error>
                                <mat-error *ngIf="formGroup.controls.detail.controls.assetName.status=='INVALID'">
                                    {{formGroup.controls.detail.controls.assetName.errors!['maxlength'] && 'Maximum length
                                    can be
                                    '+formGroup.controls.detail.controls.assetName.errors!['maxlength']['requiredLength']+
                                    '. '}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33">
                                <mat-label>File Name</mat-label>
                                <input matInput readonly type="text" formControlName="fileName" />
                                <mat-error *ngIf="formGroup.controls.detail.controls.fileName.hasError('required')">
                                    File Name is required
                                </mat-error>
                                <!-- <mat-error *ngIf="formGroup.controls.detail.controls.fileName.status=='INVALID'">
                                    {{formGroup.controls.detail.controls.fileName.errors!['maxlength'] && 'Maximum length
                                    can be
                                    '+formGroup.controls.detail.controls.fileName.errors!['maxlength']['requiredLength']}}
                                    {{formGroup.controls.detail.controls.fileName.errors!['pattern'] && 'Offer Name may
                                    only contain alphanumeric and -#&*\'/characters'}}
                                </mat-error> -->
                            </mat-form-field>
                            <div fxFlex="33" class="previeBtnMarginTop">
                                <button mat-raised-button color="primary" (click)="openPreviewDialog()">Preview</button>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
                        <mat-form-field appearance="outline" fxFlex="33">
                            <mat-label>Asset Group</mat-label>
                            <mat-select formControlName="assetGroup">
                                <mat-option *ngFor="let group of assetGroups$" [value]="group.Id" >
                                    {{group.AssetGroupName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>Date Uploaded</mat-label>
                            <input matInput type="text" formControlName="dateAdded" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.dateAdded.status=='INVALID'">
                            </mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Tags</mat-label>
                            <mat-chip-grid #chipList aria-label="Tags selection" [formControl]="tagCtrl">
                                <mat-chip-row *ngFor="let tag of assetTagIds" (removed)="removeTag(tag)">
                                    {{tag.AssetTagName}}
                                    <button matChipRemove type="button">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                <input placeholder="Type a Tag and Press Enter" [matChipInputFor]="chipList" [matAutocomplete]="auto"
                                    (matChipInputTokenEnd)="addTag($event)" [formControl]="tagCtrl">
                            </mat-chip-grid>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
                                <mat-option *ngFor="let tag of assetTags$ | async" [value]="tag">
                                    {{tag.AssetTagName}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="tagCtrl.status=='INVALID'">
                                {{tagCtrl.errors!['maxlength'] && 'Maximum length can be ' + tagCtrl.errors!['maxlength']['requiredLength']}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>Client Key</mat-label>
                            <input matInput type="text" formControlName="clientKey" />
                            <mat-error *ngIf="formGroup.controls.detail.controls.clientKey.hasError('required')">
                                Client Key is required
                            </mat-error>
                            <mat-error *ngIf="formGroup.controls.detail.controls.clientKey.status=='INVALID'">
                                {{formGroup.controls.detail.controls.clientKey.errors!['maxlength'] && 'Maximum length can be ' + formGroup.controls.detail.controls.clientKey.errors!['maxlength']['requiredLength'] + "."}}
                            </mat-error>
                        </mat-form-field>
                    </div>



                </mat-accordion>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <div fxLayout="row" [fxLayoutAlign]="assetId != '0'?'space-between':'end'" class="mat-card-actions-details">
                <button mat-raised-button type="button" color="warn" (click)="deleteRecord()" *ngIf="assetId != '0'">
                    Delete
                </button>
                <div>
                    <button mat-stroked-button type="button" (click)="cancel()">
                        Cancel
                    </button>
                    <button mat-raised-button type="button" (click)="save() " [disabled]="formGroup.invalid" color="accent">
                        Save
                    </button>
                </div>
            </div>
        </mat-card-actions>

    </mat-card>
</form>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>
