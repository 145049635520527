<ng-container *ngIf="viewModel$ | async as viewModel">

        <!-- actions bar -->

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
        <h2>Select Product</h2>

    </div>
    <div class="grid-container">

    <table *ngIf="viewModel.products.length" mat-table [dataSource]="viewModel.products" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'"  class="mat-elevation-z8">

        <!-- ProductName Column -->
        <ng-container matColumnDef="ProductName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
               Product Name
            </th>
            <td mat-cell *matCellDef="let record"> {{ record.Detail?.ProductName }}</td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="Category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Category">
                Category
            </th>
            <td mat-cell *matCellDef="let record">{{ record.ProductCategoryName }}</td>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="Brand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Brand">
                Brand
            </th>
            <td mat-cell *matCellDef="let record">{{ record.ProductBrandName }}</td>
        </ng-container>



           <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let record">
          <mat-radio-button value="{{record.selected}}" (change)="updateSelectedProduct(record)"></mat-radio-button>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <table *ngIf="!viewModel.products.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>
  </div>
    <!-- pager -->
    <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.products.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator>

    <mat-card-actions fxLayout="row" fxLayoutAlign="end">

        <div>
            <button mat-stroked-button type="button" (click)="closeDialog()">
                Cancel
            </button>
            <button mat-raised-button type="button" color="accent" (click)="saveData()">
                Select
            </button>
        </div>
    </mat-card-actions>

</ng-container>
