import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest, Subscription, distinctUntilChanged } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { Product, ProductDomain, OfferProductService } from '../..';

const log = new Logger('OfferProductsTableComponent');

@Component({
  selector: 'app-offer-products-table',
  templateUrl: './offer-products-table.component.html',
  styleUrls: ['./offer-products-table.component.scss']
})

export class OfferProductsTableComponent<T extends Product> implements OnInit {
  viewModel$ = combineLatest([
    this.offerProductService.products$,
    this.offerProductService.isLoading$,
    this.offerProductService.totalRecords$,
    this.offerProductService.page$,
  ]).pipe(
    map(([products, isLoading, totalRecords, page]) => {
      if (products.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.offerProductService.page(page);
        this.offerProductService.reload();
      }
      return { products, isLoading, totalRecords, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'ProductName', 'ClientKey', 'ProductCategoryName', 'ProductBrandName', 'Rank', 'Actions'];
  productsList: ProductDomain<T>[] = [];
  selection = new SelectionModel<ProductDomain<T>>(true, []);


  constructor(private offerProductService: OfferProductService<T>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver) { }

  ngOnInit(): void {
    log.debug('init');
    this.offerProductService.offerId = this.route.snapshot.params['offerId'];
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'ProductName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['ProductName', 'ClientKey', 'ProductCategoryName', 'Rank', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'ProductName', 'ClientKey', 'ProductCategoryName', 'ProductBrandName', 'Rank', 'Actions'];
        }
      });

    this.offerProductService.products$.subscribe((data) => {
      this.productsList = data;
    });
  }


  onSort(sortState: Sort): void {
    this.offerProductService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.offerProductService.page(pageEvent);
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.productsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }
}
