import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { Logger, OrganizationDialogComponent, OrganizationService } from 'src/app/@shared';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

const log = new Logger('ShellComponent');

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})

export class ShellComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  viewModel$ = combineLatest([
    this.organizationService.organization$,
    this.authService.user$
  ]).pipe(
    map(([organization, user]) => {
      return {
        organization, user
      }
    }),
  );

  flexMediaWatcher!: Subscription;
  isMobile = false;
  appKey = environment.appKey;

  constructor(
    private organizationService: OrganizationService,
    public dialog: MatDialog,
    private mediaObserver: MediaObserver,
    private authService: AuthService,
    @Inject(DOCUMENT) private doc: Document) { }

  ngOnInit(): void {

    log.debug('init');

    // detect changes in viewport size to handle left menu over or side
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'lt-md')) {
          this.isMobile = true;
          if (this.sidenav) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
          }
        }
        else {
          this.isMobile = false;
          if (this.sidenav) {
            this.sidenav.mode = 'side';
          }
        }
      });
  }

  logout(): void {
    this.authService.logout({ returnTo: this.doc.location.origin });
  }

  switchOrganization(): void {
    const dialogRef = this.dialog.open(OrganizationDialogComponent);
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

}
