import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { EventWorkflowService } from '../../../services/event-workflow.service';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddWorkflowUsersComponent } from './add-workflow-users/add-workflow-users.component';

interface WorkflowUser {
  first_name: string;
  last_name: string;
  task: string;
  email_alerts: string;
}

@Component({
  selector: 'app-edit-workflow',
  templateUrl: './edit-workflow.component.html',
  styleUrls: ['./edit-workflow.component.scss'],
})
export class EditWorkflowComponent implements OnInit, OnDestroy {
  editWorkflowForm: FormGroup | undefined;
  template_header = 'Template Header';
  panelOpenState = false;

  startingTriggerDropdownData = [
    { label: 'When workflow is applied to an Event', value: 0 },
    { label: 'When workflow2 is applied to an Event', value: 1 },
    { label: 'When workflow3 is applied to an Event', value: 2 },
    { label: 'When workflow4 is applied to an Event', value: 3 },
  ];

  endingTriggerDropdownData = [
    {
      label: `When all Approvers have marked all offers as "Approved"`,
      value: 0,
    },
    {
      label: `When all Approvers have marked all offers as "Approved2"`,
      value: 1,
    },
    {
      label: `When all Approvers have marked all offers as "Approved3"`,
      value: 2,
    },
    {
      label: `When all Approvers have marked all offers as "Approved4"`,
      value: 3,
    },
  ];

  @Output() onClose = new EventEmitter();

  dataSource: MatTableDataSource<any> | undefined;
  selection = new SelectionModel<any>(true, []);
  startTriggerRefData: any;
  endTriggerRefData: any;
  subscription$ = new Subscription();

  constructor(
    private ewService: EventWorkflowService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getControlsRefData();
    this.createForm();
    this.getWorkflow();
  }

  getControlsRefData() {
    this.subscription$.add(
      this.ewService
        .getRefDataForEditWorkflowControls()
        .subscribe((data: any) => {
          this.startTriggerRefData = data.startTriggers;
          this.endTriggerRefData = data.endTriggers;
        })
    );
  }

  getWorkflow() {
    this.subscription$.add(
      this.auth.getAccessTokenSilently().subscribe((token: any) => {
        this.subscription$.add(
          this.ewService.getWorkflow(token).subscribe((data: any) => {
            for (let i = 0; i < data.workflow_stages.length; i++) {
              const workflowS = this.workflowStage;
              for (let u of data.workflow_stages[i]?.users) {
                (workflowS.get('users') as FormArray).push(
                  this.workflowUserFormControl
                );
              }
              this.availableWorkflowStages.push(workflowS);
            }
            this.editWorkflowForm?.patchValue(data);
          })
        );
      })
    );
  }

  createForm() {
    this.editWorkflowForm = new FormGroup({
      template_info: new FormGroup({
        template_name: new FormControl(),
        template_owner: new FormControl(),
        event_type: new FormControl(),
        channel: new FormControl(),
      }),
      workflow_config: new FormControl(['ATL', 'OnSite']),
      workflow_stages: new FormArray([]),
    });
  }

  get workflowStage() {
    return new FormGroup({
      stage_name: new FormControl('Stage Name'),
      stage_description: new FormControl(0),
      starting_trigger: new FormControl(0),
      ending_trigger: new FormControl(),
      deadline: new FormControl(),
      users: new FormArray([]), // Add workflow users here
    });
  }

  get workflowUserFormControl() {
    return new FormGroup({
      first_name: new FormControl(),
      last_name: new FormControl(),
      task: new FormControl(),
      email_alerts: new FormControl(),
    });
  }

  public get availableWorkflowStages() {
    return (this.editWorkflowForm?.get('workflow_stages') as FormArray)
      ?.controls;
  }

  closeEditWorkflow() {
    this.onClose.emit();
  }

  getDataSource() {
    return [
      {
        first_name: '',
        last_name: '',
        task: '',
        email_alerts: '',
      },
      {
        first_name: '',
        last_name: '',
        task: '',
        email_alerts: '',
      },
      {
        first_name: '',
        last_name: '',
        task: '',
        email_alerts: '',
      },
      {
        first_name: '',
        last_name: '',
        task: '',
        email_alerts: '',
      },
    ];
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource!.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource!.data.length;
    return numSelected === numRows;
  }

  onSave() {
    this.closeEditWorkflow();
  }

  openAddUsersToWorkflow() {
    const dialogRef = this.dialog.open(AddWorkflowUsersComponent);
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
