<h1 mat-dialog-title>
  <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon> {{ dialogData.title }}
</h1>
<div mat-dialog-content>
  <h3>
    We track these errors automatically, but if the problem persists feel free
    to contact us. In the meantime, try refreshing.
  </h3>
  <p><b>Details:</b> {{ dialogData.error?.message }}</p>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button>
</div>
