<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>
        <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
            <mat-card-content fxLayout="column">

                <!-- title -->
                <h2>{{ getPromoName(viewModel?.promos) }} </h2>

                <!-- actions bar -->
                <div fxLayout="row" class="actions-bar inner" fxLayoutGap="16px" fxLayout.lt-md="column"
                    fxLayoutGap.lt-md="4px">
                    <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent"
                        matTooltip="Add new record" (click)="openDialog()">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add Version Code
                    </button>

                    <button type="button"
                        *ngIf="getSelectedSectionRecords() && getSelectedSectionRecords().length > 0 && canEdit()"
                        mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                        <mat-icon>more_vert</mat-icon>Actions
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" mat-menu-item
                            (click)="disassociateVersions()"><mat-icon>delete</mat-icon>Delete</button>
                        <button type="button" mat-menu-item>
                            {{ getSelectedSectionRecords().length }}
                            Selected</button>
                    </mat-menu>

                    <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                        <mat-label>Search Versions</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput formControlName="search" type="text" placeholder="Search"
                            (input)="onSearch($event)" #searchInput />
                        <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                            (click)="clearSearch()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <!-- record listing table -->
                <table *ngIf="viewModel.versions && viewModel.versions.length" mat-table
                    [dataSource]="viewModel.versions" matSort (matSortChange)="onSort($event)" matSortDisableClear
                    [matSortStart]="'desc'">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th class="no-ellipsis" appAuthorizeControl="edit||offermang" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [disabled]="!viewModel.versions.length">
                            </mat-checkbox>
                        </th>
                        <td class="no-ellipsis" appAuthorizeControl="edit||offermang" mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Id Column -->
                    <ng-container matColumnDef="VersionName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Version">
                            Version Code
                        </th>
                        <td mat-cell *matCellDef="let record">{{ record.VersionName }}</td>
                    </ng-container>




                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="onRowDoubleClick(row.Id)">
                    </tr>
                </table>

                <table>
                    <tr *ngIf="!viewModel.versions.length">
                        <td class="no-records" [attr.colspan]="displayedColumns.length">
                            <h2>No Records Found</h2>
                        </td>
                    </tr>
                </table>

                <!-- pager -->
                <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
                    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)"
                    [hidden]="!viewModel.versions.length" showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
                </mat-paginator>
            </mat-card-content>

            <mat-card-actions fxLayout="row" fxLayoutAlign="end">
                <div>
                    <button mat-stroked-button type="reset" color="accent" (click)="cancel()">
                        <span *ngIf="canEdit()">Cancel</span>
                        <span *ngIf="!canEdit()">Close</span>
                    </button>

                </div>
            </mat-card-actions>

        </mat-card>
    </form>
</ng-container>