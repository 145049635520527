import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import * as dayjs from 'dayjs';
import { combineLatest, delay, map } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Division, DivisionService, Event, EventService, EventType, EventTypeService } from '../..';
import { Filter } from 'src/app/@shared/models/filter.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

const log = new Logger('EventsComponent');

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})

export class EventsComponent<TEvent extends Event, TDivision extends Division, TEventType extends EventType> implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild('eventsTable', { static: false }) eventsTable: any;
  @ViewChild('eventsExpansion', { static: false }) eventsExpansion: any;
  maxDate: Date = new Date(9999, 0, 1);
  viewModel$ = combineLatest([
    this.eventService.viewMode$,
    this.eventService.dateRange$,
    this.eventService.filters$,
    this.divisionService.divisionsList$,
    this.eventTypeService.eventTypesList$
  ]).pipe(
    map(([viewMode, dateRange, filters, divisions, eventTypes]) => {
      this.filtersForm.controls.startDate.patchValue(dateRange.startDate);
      this.filtersForm.controls.endDate.patchValue(dateRange.endDate);
      return { viewMode, dateRange, filters, divisions, eventTypes }
    }),
  );

  filtersForm = new FormGroup({
    divisions: new FormControl<Division[]>([], { nonNullable: true }),
    endDate: new FormControl<string | null>(null, [Validators.required]),
    eventTypeId: new FormControl<string | null>(null),
    search: new FormControl<string | null>(null),
    startDate: new FormControl<string | null>(null, [Validators.required]),
  });

  constructor(
    private eventService: EventService<TEvent>,
    private divisionService: DivisionService<TDivision>,
    private eventTypeService: EventTypeService<TEventType>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.eventService.reload();
    this.route.queryParams.subscribe(queryParams => {
      let searchKey = queryParams['search'];

      if (searchKey) {
        this.eventService.search(searchKey)
        this.filtersForm.controls.search.setValue(searchKey);
      } else {
        this.eventService.search('');
        this.filtersForm.controls.search.setValue('');
      }
    });
    this.eventService.dateRange$.subscribe(data => this.filtersForm.patchValue(data));
  }

  onSearch(event: any) {
    this.eventService.search(event.target.value);
  }

  onDateRangePickerOpen() {
    this.filtersForm.controls.startDate.reset();
    this.filtersForm.controls.endDate.reset();
  }

  onDateRangeChange() {
    let startDate = dayjs(this.filtersForm.controls.startDate.value).format();
    let endDate = dayjs(this.filtersForm.controls.endDate.value).format();
    let isbefore = dayjs(startDate).isBefore(endDate);
    let isSame = dayjs(startDate).isSame(endDate);

    if (isSame || !isbefore) {
      this.filtersForm.controls.endDate.reset();
    } else if (this.filtersForm.valid) {
      this.eventService.dateRange(startDate, endDate);
    }
  }

  onFilterChange(event: any) {
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'divisions':
        // get the selected division ids 
        const values: TDivision[] = event.value;
        // remove any existing division id filters
        this.eventService.removeFilterByFieldName('divisionId');
        // init the array of division filters
        const divisionFilters: Filter[] = [];
        // loop through the selected division ids and add each as a filter
        values.forEach(value => {
          // init the new filter
          const divisionFilter: Filter = { displayText: value.DivisionName, fieldName: 'divisionId', value: value.Id };
          // add the filter to the list of filters
          divisionFilters.push(divisionFilter);
        });
        // add the filters to the event service
        this.eventService.addFilters(divisionFilters);
        break;
      case 'eventtypeid':
        //remove any existing event type filter 
        this.eventService.removeFilterByFieldName('eventTypeId');

        if (event.value !== '') {
          // init the new filter
          const eventTypeFilter: Filter = { displayText: event.value.EventTypeName, fieldName: 'eventTypeId', value: event.value };
          // add the filter to the list of filters
          this.eventService.addFilters([eventTypeFilter]);
        }


        break;
    }
  }

  toggleView(mode: string) {
    this.eventService.toggleViewMode(mode);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.eventService.search('');
  }

  removeFilter(filter: Filter) {
    if (filter.fieldName === 'divisionId') {
      ///TODO: fix below
      this.filtersForm.patchValue({
        divisions: this.filtersForm.controls.divisions.value.filter((item: Division) => item.Id !== filter.value)
      });
    }
    this.eventService.removeFilter(filter);
  }

  resetFilters() {
    this.filtersForm.reset();
    this.eventService.search('');
    this.eventService.clearFilters();
    this.sidenav.toggle();
  }

  clearFilters() {
    this.filtersForm.reset();
    this.eventService.clearFilters();
  }

  addEvent() {
    this.router.navigate([`0`], { relativeTo: this.route });
  }

  deleteSelectedRecords() {
    let selectedRecords: any;
    if (this.eventsTable) {
      selectedRecords = this.eventsTable.getSelectedSectionRecords();
    } else if (this.eventsExpansion) {
      selectedRecords = this.eventsExpansion.getSelectedSectionRecords();
    }
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Event(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.eventService.deleteEvents(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Event(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.eventService.reload();
                if (this.eventsTable) {
                  this.eventsTable.clearSelection();
                } else if (this.eventsExpansion) {
                  this.eventsExpansion.clearSelection();
                }
              },
              error: (error) => {
                log.error('Error in deleting event', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.eventService.downloadEventsJSON();
  }

  downloadCSV(){
    this.eventService.downloadEventsCSV();
  }
}
