import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { map, combineLatest, Subscription, distinctUntilChanged, Observable } from 'rxjs';
import { Logger, OrganizationService } from 'src/app/@shared';
import { OfferAsset, PromoAssetsService } from '../..';

const log = new Logger('PromoAssetsTableComponent');

@Component({
  selector: 'app-promo-assets-table',
  templateUrl: './promo-assets-table.component.html',
  styleUrls: ['./promo-assets-table.component.scss']
})

export class PromoAssetsTableComponent implements OnInit {
  viewModel$ = combineLatest([
    this.promoAssetService.assets$,
    this.promoAssetService.isLoading$,
    this.promoAssetService.totalRecords$,
    this.promoAssetService.page$,
    this.organizationService.assetsUrl$,
  ]).pipe(
    map(([assets, isLoading, totalRecords, page, assetsUrl]) => {
      if (assets.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.promoAssetService.page(page);
        this.promoAssetService.reload();
      }
      return { assets, isLoading, totalRecords, page, assetsUrl }
    }),
  );
  assetsUrl$: Observable<string> = this.organizationService.assetsUrl$;
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'FileName', 'ClientKey', 'Id', 'Actions'];
  assetsList: OfferAsset[] = [];
  selection = new SelectionModel<OfferAsset>(true, []);


  constructor(private promoAssetService: PromoAssetsService<OfferAsset>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private mediaObserver: MediaObserver, private organizationService: OrganizationService) { }

  ngOnInit(): void {
    log.debug('init');
    this.promoAssetService.promoId = this.route.snapshot.params['offerId'];
    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'AssetName'];
        } else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'AssetName', 'FileName', 'ClientKey'];
        }
        else {
          this.displayedColumns = ['select', 'Image', 'AssetName', 'Type', 'FileName', 'ClientKey', 'Id', 'Actions'];
        }
      });

    this.promoAssetService.assets$.subscribe((data) => {
      this.selection.clear();
      this.assetsList = data;
    });
  }


  onSort(sortState: Sort): void {
    this.promoAssetService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.promoAssetService.page(pageEvent);
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public getImageSrc(assetsUrl: any, assets: any) {
    let imagesrc = '';
    if (assets.Type === 'application/pdf') {
      imagesrc = './assets/images/pdf.png';
    } else if (assets.Type.indexOf('audio') > -1) {
      imagesrc = './assets/images/mp3.jpg';
    } else if (assets.Type.indexOf('video') > -1) {
      imagesrc = './assets/images/mp4.png';
    } else if (assets.Type.indexOf('text') > -1) {
      imagesrc = './assets/images/text.png';
    }
    else {
      imagesrc = assetsUrl + '/' + assets.Detail.FileName;
    }
    return imagesrc;
  }
}
