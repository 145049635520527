import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subscription, tap } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger, OrganizationService } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Asset, EventService, Event, EventDomain } from 'src/app/modules/standard/v1';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';

const log = new Logger('CloneEventComponent');

interface DialogData {
  eventId: string;
}

@Component({
  selector: 'app-clone-event',
  templateUrl: './clone-event.component.html',
  styleUrls: ['./clone-event.component.scss']
})

export class CloneEventComponent<TEvent extends Event> implements OnInit {

  viewModel$ = combineLatest([
    this.eventService.events$,
    this.eventService.isLoading$,
    this.eventService.totalRecords$,
    this.eventService.page$,
  ]).pipe(
    map(([events, isLoading, totalRecords, page]) => {
      if (events.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.eventService.page(page);
        this.eventService.reload();
      }
      return { events, isLoading, totalRecords, page }
    }),
  );
  eventDomain$: Observable<EventDomain<TEvent>> = of({} as EventDomain<TEvent>);
  flexMediaWatcher!: Subscription;
  formGroup = new FormGroup({
    EventTypeId: new FormControl<string | null>(null),
    DivisionIds: new FormControl<string[]>([]),
    IncludeVersions: new FormControl<boolean>(true),
    CurrentStatus: new FormControl<boolean>(true),
    Detail: new FormGroup({
      ClientKey: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      EndDate: new FormControl<Date | string | null>(null, [Validators.required]),
      Id: new FormControl<string>('0', { nonNullable: true }),
      EventName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      StartDate: new FormControl<Date | string | null>(null, [Validators.required]),
      Disclaimer: new FormControl<string>(''),
    })
  });

  constructor(private mediaObserver: MediaObserver,
    private eventService: EventService<TEvent>,
    private router: Router,
    public dialogRef: MatDialogRef<CloneEventComponent<TEvent>>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private organizationService: OrganizationService) { }

  ngOnInit(): void {
    log.debug('init');
    this.eventDomain$ = this.eventService.getEventDomain(this.data.eventId).pipe(
      tap((eventDomain: any) => {
        if (eventDomain && eventDomain.Detail && eventDomain.Detail.StartDate) {
          eventDomain.Detail.StartDate = new Date(eventDomain.Detail.StartDate);
        }
        if (eventDomain && eventDomain.Detail && eventDomain.Detail.EndDate) {
          eventDomain.Detail.EndDate = new Date(eventDomain.Detail.EndDate);
        }
        if (eventDomain && eventDomain.Detail && eventDomain.Detail.ClientKey) {
          eventDomain.Detail.ClientKey = '';
        }
        if (eventDomain && eventDomain.Detail && eventDomain.Detail.EventName) {
          eventDomain.Detail.EventName = eventDomain.Detail.EventName + '_Copy';
        }
        this.formGroup.patchValue(eventDomain);
      })
    );

  }

  cloneForm = new FormGroup({
    search: new FormControl(),
  });


  closeDialog() {
    this.dialogRef.close();
  }

  cloneEvent() {
    const startDate = this.formatDate(this.formGroup.controls.Detail.controls.StartDate.value);
    const endDate = this.formatDate(this.formGroup.controls.Detail.controls.EndDate.value);
    const record = this.formGroup.getRawValue();
    record.Detail.StartDate = startDate;
    record.Detail.EndDate = endDate;
    this.eventService.cloneEvent(record.Detail.Id, record.IncludeVersions as boolean, record.CurrentStatus as boolean, record.Detail).subscribe({
      next: (res) => {
        this.matSnackBar.open(`Cloned Successfully!`, 'OK', DEFAULT_SNACKBAR_CONFIG);
        this.dialogRef.close(res);
        const url = (`${this.organizationService.organization?.apiPath}`).toLowerCase();
        let currentUrl = `${url}/events/${res.Id}/offers`;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }, error: (error) => {
        log.error('Error in cloning event', error);

        if (error.error.value) {
          throw new Error(error.error.value);
        } else {
          throw new Error(error.message);
        }
      }
    })
  }

  formatDate(date: Date | string | null) {
    if (date) {
      const newDate = dayjs(date).format('MM/DD/YYYY');
      return newDate;
    } else {
      return '';
    }
  }
}
