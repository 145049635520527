<!-- page title -->
<div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">
    <h1 class="mt-18">Offer Management</h1>
    <div fxLayout="row" class="arrows">
        <span class="nextButtons statusAPPROVED">
            <span class="number">{{statusCount.Approved}}</span> <br /> Approved
        </span>
        <span class="nextButtons statusPENDING">
            <span class="number">{{statusCount.Pending}}</span> <br /> Pending
        </span>        
        <span class="nextButtons statusSUPPRESSED">
            <span class="number">{{statusCount.Suppressed}}</span> <br /> Suppressed
        </span>
       
        <!-- <button mat-button class="nextButtons statusPENDING" title="Pending">  </button>
        <button mat-button class="nextButtons statusAPPROVED" title="Approved">Approved
            {{statusCount.Approved}}</button>
        <button mat-button class="nextButtons statusSUPPRESSED" title="Suppressed">Suppressed
            {{statusCount.Suppressed}}</button> -->
    </div>
</div>


<ng-container *ngIf="viewModel$ | async as viewModel; else loading">

    <h2>{{viewModel.event.EventName}}</h2>
    <form [formGroup]="filtersForm" novalidate>

        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent" matTooltip="Add new record"
                [routerLink]="[0, 'details']">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Offer
            </button>

            <button type="button"
                *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)"
                mat-raised-button [matMenuTriggerFor]="menu" matTooltip="Actions">
                <mat-icon>more_vert</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item
                    [matMenuTriggerFor]="Download"><mat-icon>arrow_circle_down</mat-icon>Download</button>
                <!-- <button type="button" mat-menu-item><mat-icon>file_copy</mat-icon>Copy</button> -->
                <button type="button" mat-menu-item appAuthorizeControl="edit||offermang"
                    (click)="deleteSelectedRecords()"><mat-icon>delete</mat-icon>Delete</button>
                <button type="button" mat-menu-item
                    *ngIf="(viewModel.viewMode === 'TABLE' && offerPromoTable?.getSelectedSectionRecords()?.length > 0) || (viewModel.viewMode === 'CARDS' && offerPromoCards?.getSelectedSectionRecords()?.length > 0)">
                    {{ viewModel.viewMode === 'TABLE' ? offerPromoTable?.getSelectedSectionRecords()?.length :
                    offerPromoCards?.getSelectedSectionRecords()?.length}} Selected
                </button>
                <button type="button" mat-menu-item (click)="openVersionsDialog()">Add Version Codes</button>
                <button appAuthorizeControl="edit||offermang" type="button" mat-menu-item [matMenuTriggerFor]="Select">Status</button>
            </mat-menu>
            <mat-menu #Download="matMenu">
                <button mat-menu-item (click)="downloadOffersJSON()">Offer JSON</button>
                <button mat-menu-item (click)="downloadPromosJSON()">Promo JSON</button>
                <button mat-menu-item (click)="downloadEventJSON()">Event JSON</button>
                <button mat-menu-item (click)="downloadCSV()">CSV</button>
            </mat-menu>
            <mat-menu #Select="matMenu">
                <button mat-menu-item (click)="updateStatus(1)">Pending</button>
                <button mat-menu-item (click)="updateStatus(2)">Approve</button>
                <button mat-menu-item (click)="updateStatus(3)">Suppress</button>
            </mat-menu>

            <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent" matTooltip="Add new record"
                [routerLink]="[0, 'promos','promodetails']">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Promo
            </button>

            <button appAuthorizeControl="edit||offermang" type="button" mat-raised-button color="accent" matTooltip="Clone Event" (click)="openDialog()">
                Clone
            </button>

            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Search Offers</mat-label>
                <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search"
                    #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <span class="menu-spacer"></span>

            <!--only display the sort by dropdown for the cards view(table view sorts on columns)-->
            <button type="button" mat-raised-button matTooltip="Sort" (click)="onSort()">
                <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>Sort
            </button>

            <mat-button-toggle-group #group="matButtonToggleGroup" value="cards">
                <mat-button-toggle value="cards" matTooltip="Card view" aria-label="Card view"
                    (click)="toggleView('CARDS')">
                    <mat-icon>grid_view</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" matTooltip="Table view" aria-label="Table view"
                    (click)="toggleView('TABLE')">
                    <mat-icon>table_view</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

            <button type="button" mat-raised-button matTooltip="Show/hide filters" (click)="filterSidenav.toggle()">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>Filter
            </button>
        </div>

        <mat-sidenav-container>
            <!--filter sidenav-->
            <mat-sidenav #filterSidenav mode="over" position="end">
                <mat-toolbar>
                    Filter
                    <span class="menu-spacer"></span>
                    <button type="button" mat-icon-button aria-label="Close filter menu" matTooltip="Close filter menu"
                        (click)="filterSidenav.toggle()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
                <div style="margin: 16px">
                    <div fxLayout="column">

                        <mat-form-field appearance="outline">
                            <mat-label>Versions</mat-label>
                            <mat-select formControlName="versions" multiple (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let version of viewModel.versions" [value]="version">
                                    {{version.VersionName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Offer tags</mat-label>
                            <mat-select formControlName="offerTags" multiple (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let tag of viewModel.offerTags" [value]="tag">
                                    {{tag.OfferTagName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Offer + Promos</mat-label>
                            <mat-select formControlName="offerPromos" multiple
                                (selectionChange)="onFilterChange($event)">
                                <mat-option *ngFor="let offerPromos of offerpromos" [value]="offerPromos">
                                    {{offerPromos.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
                        <button type="reset" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()">
                            Reset
                        </button>
                        <button type="button" mat-raised-button matTooltip="Apply filters" color="accent"
                            (click)="filterSidenav.toggle()" [disabled]="filtersForm.invalid">
                            Apply
                        </button>
                    </div>
                </div>

            </mat-sidenav>

            <!--filter chips -->
            <div class="filter-chips" *ngIf="viewModel.filters && viewModel.filters.length > 0" fxLayout="row">
                <mat-chip-listbox aria-label="Selected filters">
                    <mat-chip-option *ngFor="let filter of viewModel.filters" (removed)="removeFilter(filter)">
                        {{filter.displayText}}
                        <button type="button" matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-option>
                </mat-chip-listbox>
                <button type="button" mat-flat-button matTooltip="Remove all filters" (click)="clearFilters()">
                    Clear all
                </button>
            </div>

            <!--main content-->
            <!--table view-->
            <app-offer-list-cards #offerPromoCards *ngIf="viewModel.viewMode === 'CARDS'"></app-offer-list-cards>
            <!--expansion view-->
            <app-offer-list-table #offerPromoTable *ngIf="viewModel.viewMode === 'TABLE'"></app-offer-list-table>

        </mat-sidenav-container>

    </form>

</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>