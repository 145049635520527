<div class="edit-workflow-container">
  <div class="flex-space-between">
    <h1>{{ template_header }}</h1>
    <span class="close-icon" (click)="closeEditWorkflow()">
      <fa-icon [icon]="['fas', 'x']"></fa-icon>
    </span>
  </div>

  <form *ngIf="editWorkflowForm" [formGroup]="editWorkflowForm">
    <!-- Template Details -->
    <div
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
      fxFlexFill
      formGroupName="template_info"
    >
      <mat-form-field appearance="outline" fxFlex="22">
        <mat-label>Template Name</mat-label>
        <input matInput required formControlName="template_name" />
        <br />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="22">
        <mat-label>Template Owner</mat-label>
        <input matInput required formControlName="template_owner" />
        <br />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="22">
        <mat-label>Event Type</mat-label>
        <input matInput required formControlName="event_type" />
        <br />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="22">
        <mat-label>Channel</mat-label>
        <input matInput formControlName="channel" />
      </mat-form-field>
    </div>

    <!-- Workflow Config -->
    <mat-accordion class="config-accordion">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>Workflow Configuration</mat-panel-title>
        </mat-expansion-panel-header>

        <div
          fxLayout="row wrap"
          fxLayoutGap.gt-sm="16px"
          fxLayout.lt-md="column"
          fxFlexFill
          formGroupName="template_info"
        >
          <mat-stepper [linear]="true" labelPosition="bottom" #stepper>
            <mat-step label="ATL"> </mat-step>
            <mat-step label="Onsite"> </mat-step>
          </mat-stepper>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Workflow Stages -->
    <div formArrayName="workflow_stages">
      <div
        style="margin-top: 1rem !important"
        *ngFor="let w of availableWorkflowStages; let i = index"
      >
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title
                style="justify-content: space-between !important"
              >
                <span>
                  {{ availableWorkflowStages[i].value?.stage_name }} -
                  <a>Remove Stage</a>
                </span>

                <span>
                  <a>Skip Stage ></a>
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div
              fxLayout="row wrap"
              fxLayoutGap.gt-sm="16px"
              fxLayout.lt-md="column"
              fxFlexFill
              formGroupName="{{ i }}"
            >
              <!-- First Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
              >
                <mat-form-field appearance="outline" fxFlex="47">
                  <mat-label>Stage Name</mat-label>
                  <input matInput required formControlName="stage_name" />
                  <br />
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="47">
                  <mat-label>Description</mat-label>
                  <input
                    matInput
                    required
                    formControlName="stage_description"
                  />
                  <br />
                </mat-form-field>
              </div>

              <!-- Second Row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
              >
                <mat-form-field appearance="outline" fxFlex="35">
                  <mat-label data-testid="StageStartingTrigger"
                    >Stage Starting Trigger</mat-label
                  >
                  <mat-select formControlName="starting_trigger">
                    <mat-option
                      *ngFor="let s of startTriggerRefData"
                      [value]="s.value"
                    >
                      <span>{{ s.label }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="35">
                  <mat-label data-testid="StageEndingTrigger"
                    >Stage Ending Trigger</mat-label
                  >
                  <mat-select formControlName="ending_trigger">
                    <mat-option
                      *ngFor="let e of endTriggerRefData"
                      [value]="e.value"
                    >
                      <span>{{ e.label }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="24">
                  <mat-label>Deadline</mat-label>
                  <input
                    matInput
                    [matDatepicker]="deadlineDatePicker"
                    formControlName="deadline"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="deadlineDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #deadlineDatePicker></mat-datepicker>
                </mat-form-field>
              </div>

              <!-- Third row -->
              <div
                fxLayout="row wrap"
                fxLayoutGap.gt-sm="16px"
                fxLayout.lt-md="column"
                fxFlexFill
                style="margin-top: 2.5rem !important"
              >
                <!-- Users Heading -->
                <div
                  fxLayout="row wrap"
                  fxLayoutGap.gt-sm="16px"
                  fxLayout.lt-md="column"
                  class="users-table-heading"
                >
                  <h3 class="users-heading">Users</h3>

                  <button
                    type="button"
                    mat-raised-button
                    color="accent"
                    matTooltip="Add User"
                    (click)="openAddUsersToWorkflow()"
                  >
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>Add User
                  </button>
                </div>

                <!-- Users Table -->
                <div class="users-table">
                  <workflow-users-table
                    [tableData]="availableWorkflowStages[i].value?.users"
                  ></workflow-users-table>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="add-stage-link-container">
      <b><a>+ Add Stage</a></b>
    </div>

    <div class="edit-workflow-actions">
      <button mat-button (click)="closeEditWorkflow()">Cancel</button>
      <button mat-raised-button color="accent" (click)="onSave()">Save</button>
    </div>
  </form>
</div>
