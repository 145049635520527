<ng-container *ngIf="viewModel$ | async as viewModel">
  <form [formGroup]="form">
    <div formArrayName="versions">
      <table *ngIf="viewModel.offers.length" mat-table [dataSource]="viewModel.offers" matSort
      (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'">

       <!-- Checkbox Column -->
     <ng-container matColumnDef="select">
       <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
           <mat-checkbox (change)="$event ? masterToggle() : null"
               [checked]="selection.hasValue() && isAllSelected()"
               [indeterminate]="selection.hasValue() && !isAllSelected()">
           </mat-checkbox>
       </th>
       <td class="no-ellipsis" mat-cell *matCellDef="let row">
           <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
               [checked]="selection.isSelected(row)">
           </mat-checkbox>
       </td>
   </ng-container>

 <!--  Image Column -->
 <ng-container matColumnDef="Image">
   <th mat-header-cell *matHeaderCellDef>
     Preview
   </th>
   <td mat-cell *matCellDef="let record">
     <!-- <img src="{{record?.FileName }}"> -->
     <!-- <img *ngIf="record.FileName" src="{{viewModel.assetsUrl}}/{{record.FileName}}" alt="Offer image">
     <img *ngIf="!record.FileName" src="./assets/images/default-image.png" alt="Offer image"> -->
     <ng-container *ngIf="record.FileName">
       <img mat-card-md-image [src]="getImageSrc(record)" alt="Offer image">
   </ng-container>
   <img mat-card-md-image *ngIf="!record.FileName"
       src="./assets/images/default-image.png" alt="Offer image">
   </td>
 </ng-container>

 <!-- OfferName Column -->
 <ng-container matColumnDef="Name">
   <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
     Name
   </th>
   <td mat-cell *matCellDef="let record"> {{ record.Detail?.Name }}</td>
 </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="Version">
     <th mat-header-cell *matHeaderCellDef>
       Version
     </th>
     <td mat-cell *matCellDef="let record; let i = index;">
       <mat-form-field class="versions" appearance="outline">
         <mat-select [disabled]="true" [formControlName]="i">
           <mat-option [value]="record">
             {{record.Detail.Version}}
           </mat-option>
         </mat-select>
       </mat-form-field>
     </td>
   </ng-container>

 <!-- Headline Column -->
 <ng-container matColumnDef="Headline">
   <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Headline">
     Headline
   </th>
   <td mat-cell *matCellDef="let record">{{ record.Detail?.Headline }}</td>
 </ng-container>

 <!-- BodyCopy Column -->
 <ng-container matColumnDef="BodyCopy">
   <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by BodyCopy">
     Body Copy
   </th>
   <td mat-cell *matCellDef="let record">{{ record.Detail?.BodyCopy }}</td>
 </ng-container>

 <!-- EventType Column -->
 <ng-container matColumnDef="EventType">
   <th mat-header-cell *matHeaderCellDef>
     Type
   </th>
   <td mat-cell *matCellDef="let record">{{ record.EventType }}</td>
 </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="Status">
   <th style="width:120px" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">
     Status
   </th>
 <td  [matMenuTriggerFor]="canEdit() ? menu: null" mat-cell *matCellDef="let record">
   <div class="offer-status column-status">
    <span class="{{record?.Detail?.Status === '0' ? 'statusPENDING' :  'status' + record?.Detail?.Status}}">
   {{ record.Detail?.Status === '0' ? 'PENDING' :  record.Detail?.Status}}
   <mat-icon *ngIf="canEdit()"  class="icon">arrow_drop_down</mat-icon>
   <mat-menu #menu="matMenu">
     <button type="button" class="statusAPPROVED" mat-menu-item (click)="updateTableStatus(2, record)" [disabled]="record?.Detail?.Status === 'APPROVED'">APPROVED</button>
     <button type="button" mat-menu-item class="statusPENDING" (click)="updateTableStatus(1, record)" [disabled]="record?.Detail?.Status === 'PENDING'">PENDING</button>
     <button type="button" class="statusSUPPRESSED" mat-menu-item (click)="updateTableStatus(3, record)" [disabled]="record?.Detail?.Status === 'SUPPRESSED'">SUPPRESSED</button>
   </mat-menu>
  </span>
</div>
 </td>
 </ng-container>

 <!-- Rank Column -->
 <ng-container matColumnDef="Rank">
   <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rank">
     Rank
   </th>
   <td mat-cell *matCellDef="let record">{{ record.Detail?.Rank }}</td>
 </ng-container>

 <!-- VersionCodes Column -->
 <ng-container matColumnDef="VersionCodes">
  <th mat-header-cell *matHeaderCellDef>
    VersionCodes
  </th>
  <td mat-cell *matCellDef="let record">{{ record.VersionCodes }}</td>
</ng-container>

 <!-- Actions Column -->
 <ng-container matColumnDef="Actions">
   <th mat-header-cell *matHeaderCellDef style="padding-left: 20px;">Actions</th>
   <td mat-cell *matCellDef="let record">
     <button class="offer-button" type="button" mat-button
             color="accent">
           <span *ngIf="canEdit()" (click)="onEdit(record.Detail.Id)">Edit</span>
           <span *ngIf="!canEdit()">View Details</span>
     </button>
     <!-- <button *ngIf="record.EventType === 'PROMO'" class="offer-button" type="button" mat-button
             color="accent" [routerLink]="[record.Detail.Id, 'promos', 'promodetails']">
           <span *ngIf="canEdit()">Edit</span>
           <span *ngIf="!canEdit()">View Details</span>
     </button> -->
   </td>
 </ng-container>

 <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
 <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
    </div>
  </form>


    <table *ngIf="!viewModel.offers.length">
        <tr>
            <td class="no-records" [attr.colspan]="displayedColumns.length">
                <h2>No Records Found</h2>
            </td>
        </tr>
    </table>

    <!-- pager -->
    <!-- <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.offers.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
    </mat-paginator> -->

</ng-container>
