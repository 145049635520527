<ng-container *ngIf="viewModel$ | async as viewModel">

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px">
    <h2>Select Event</h2>
  </div>
  <div class="grid-container">

  <table *ngIf="viewModel.allevents.length" mat-table [dataSource]="viewModel.allevents" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'asc'"  class="mat-elevation-z8">



    <!-- EventName Column -->
    <ng-container matColumnDef="EventName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
        Event Name
      </th>
      <td mat-cell *matCellDef="let record">{{ record.Detail.EventName}}</td>
    </ng-container>

    <!-- StartDate Column -->
    <ng-container matColumnDef="StartDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by StartDate">
        Start Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.Detail.StartDate | date: "MM/dd/yyyy" }}
      </td>
    </ng-container>

    <!-- EndDate Column -->
    <ng-container matColumnDef="EndDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by EndDate">
        End Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.Detail.EndDate | date: "MM/dd/yyyy" }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let record">
        <mat-radio-button value="{{record.selected}}" (change)="updateSelectedEvent(record)"></mat-radio-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="onRowDoubleClick(row.Detail.Id)"></tr>
  </table>

  <table *ngIf="!viewModel.allevents.length">
    <tr>
      <td class="no-records" [attr.colspan]="displayedColumns.length">
        <h2>No Records Found</h2>
      </td>
    </tr>
  </table>
</div>
  <!-- pager -->
  <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
    [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" [hidden]="!viewModel.allevents.length"
    showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
  </mat-paginator>

  <mat-card-actions fxLayout="row" fxLayoutAlign="end">

    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
      <button mat-stroked-button type="button" (click)="closeDialog()">
        Cancel
      </button>
      <button mat-raised-button type="button" color="accent" (click)="saveData()">
        Select
      </button>
    </div>
  </mat-card-actions>

</ng-container>
