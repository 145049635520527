<ng-container *ngIf="viewModel$ | async as viewModel">
    <form *ngIf="eventDomain$ | async as TEvent; else loading" [formGroup]="formGroup" novalidate>
        <div fxLayout="column" formGroupName="Detail">
            <h2>Clone Event</h2>
            <mat-form-field appearance="outline" fxFlex="16">
                <mat-label data-testid="EventName">Event Name</mat-label>
                <input matInput type="text" formControlName="EventName" />
                <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.hasError('required')">
                    Name is required
                </mat-error>
                <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.hasError('invalid')">
                    {{formGroup.controls.Detail.controls.EventName.getError('invalid')}}
                </mat-error>
                <mat-error *ngIf="formGroup.controls.Detail.controls.EventName.status=='INVALID'">
                    {{formGroup.controls.Detail.controls.EventName.errors!['maxlength'] && 'Maximum length can be' +
                    formGroup.controls.Detail.controls.EventName.errors!['maxlength']['requiredLength'] + '.'}}
                </mat-error>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="StartDate">Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="StartDate">
                    <mat-error *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('required')">
                        Start date is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.StartDate.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.StartDate.getError('invalid')}}
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50">
                    <mat-label data-testid="EndDate">End Date</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="EndDate">
                    <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('required')">
                        End date is required
                    </mat-error>
                    <mat-error *ngIf="formGroup.controls.Detail.controls.EndDate.hasError('invalid')">
                        {{formGroup.controls.Detail.controls.EndDate.getError('invalid')}}
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" fxFlex="32">
                <mat-label>Client Key</mat-label>
                <input matInput type="text" formControlName="ClientKey" />
                <mat-error>
                    Client key is required
                </mat-error>
                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.hasError('invalid')">
                    {{formGroup.controls.Detail.controls.ClientKey.getError('invalid')}}
                </mat-error>
                <mat-error *ngIf="formGroup.controls.Detail.controls.ClientKey.status=='INVALID'">
                    {{formGroup.controls.Detail.controls.ClientKey.errors!['maxlength'] && 'Maximum length can be' +
                    formGroup.controls.Detail.controls.ClientKey.errors!['maxlength']['requiredLength'] + '.'}}
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="column">
            <mat-checkbox class="check-box" (click)="$event.stopPropagation()" formControlName="IncludeVersions"> Include
                Versions Assigned
            </mat-checkbox>
            <mat-checkbox class="check-box" (click)="$event.stopPropagation()" formControlName="CurrentStatus"> Keep Current
                Offer Status
            </mat-checkbox>
        </div>
        


        <mat-card-actions fxLayout="row" fxLayoutAlign="end">

            <div>
                <button mat-stroked-button type="button" (click)="closeDialog()" style="margin-right: 10px;">
                    Cancel
                </button>
                <button mat-raised-button type="button" color="accent" [disabled]="formGroup.invalid" (click)="cloneEvent()">
                    Clone
                </button>
            </div>
        </mat-card-actions>
    </form>
</ng-container>

<ng-template #loading>
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</ng-template>