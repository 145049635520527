<ng-container *ngIf="viewModel$ | async as viewModel">
  <mat-card appearance="outlined" [class.mat-elevation-z0]="true">
    <mat-card-content fxLayout="column">

      <!-- actions bar -->
      <form [formGroup]="filtersForm" novalidate>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
          <h2>Add Stores</h2>
          <mat-form-field appearance="outline" class="mat-form-field-no-padding">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input matInput formControlName="search" type="text" (input)="onSearch($event)" placeholder="Search" #searchInput/>
            <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput.value" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
          </button>
          </mat-form-field>
        </div>
      </form>
      <div class="grid-container">

      <table *ngIf="viewModel.stores.length" mat-table [dataSource]="viewModel.stores" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [disabled]="!viewModel.stores.length">
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- StoreName Column -->
        <ng-container matColumnDef="StoreName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
            Name
          </th>
          <td mat-cell *matCellDef="let record"> {{ record.StoreName }}</td>
        </ng-container>

        <!-- ClientKey Column -->
        <ng-container matColumnDef="ClientKey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey">
            Client Key
          </th>
          <td mat-cell *matCellDef="let record">{{ record.ClientKey }}</td>
        </ng-container>

        <!-- Address1 Column -->
        <ng-container matColumnDef="Address1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address1">
            Address1
          </th>
          <td mat-cell *matCellDef="let record">{{ record.Address1 }}</td>
        </ng-container>

        <!-- Address2 Column -->
        <ng-container matColumnDef="Address2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address2">
            Address2
          </th>
          <td mat-cell *matCellDef="let record">{{ record.Address2 }}</td>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="City">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by City">
            City
          </th>
          <td mat-cell *matCellDef="let record">{{ record.City }}</td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="State">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by State">
            State
          </th>
          <td mat-cell *matCellDef="let record">{{ record.State }}</td>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="Country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Country">
            Country
          </th>
          <td mat-cell *matCellDef="let record">{{ record.Country }}</td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="PostalCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by PostalCode">
            PostalCode
          </th>
          <td mat-cell *matCellDef="let record">{{ record.PostalCode }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <table *ngIf="!viewModel.stores.length">
        <tr>
          <td class="no-records" [attr.colspan]="displayedColumns.length">
            <h2>No Records Found</h2>
          </td>
        </tr>
      </table>
    </div>
      <!-- pager -->
      <mat-paginator [length]="viewModel.totalRecords" [pageSize]="viewModel.page.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)" *ngIf="viewModel.stores.length"
        showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
      </mat-paginator>
    </mat-card-content>

    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between" gdAlignRows="end" *ngIf="viewModel.stores.length">
      <div>
        <button mat-stroked-button type="button" (click)="closeDialog()">
          Cancel
        </button>
        <button mat-raised-button type="button" color="accent" (click)="associateStore()"
          [disabled]="!selection.selected.length">
          Select
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
