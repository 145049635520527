import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventWorkflowService } from '../../../../services/event-workflow.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'workflow-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy {
  dataSource: any;
  selection = new SelectionModel<any>(true, []);

  displayedColumns: string[] = [
    'select',
    'first_name',
    'last_name',
    'task',
    'email_alerts',
  ];
  tasksRefData: any;
  emailAlertsRefData: any;

  @Input() set tableData(val: any[]) {
    this.dataSource = val;
    this.setProperLabelsInDataSourceIfAvailable(val);
  }

  subscription$ = new Subscription();

  constructor(public ewService: EventWorkflowService) {}

  ngOnInit(): void {
    this.getRefDataForControls();
  }

  setProperLabelsInDataSourceIfAvailable(val: any[]) {
    const labelsInterval = setInterval(() => {
      if (this.tasksRefData && this.emailAlertsRefData) {
        // Tasks
        val.forEach((v, i) => {
          const ti = (this.tasksRefData as any[]).findIndex(
            (t) => t.Id == v.task
          );
          if (ti > -1) {
            val[i].task = this.tasksRefData[ti].label;
          }
        });

        // Email alerts
        val.forEach((v, i) => {
          const ei = (this.emailAlertsRefData as any[]).findIndex(
            (e) => e.Id == v.email_alerts
          );
          if (ei > -1) {
            val[i].email_alerts = this.tasksRefData[ei].label;
          }
        });

        this.dataSource = val;
        clearInterval(labelsInterval);
      }
    }, 500);
  }

  getRefDataForControls() {
    this.subscription$.add(
      this.ewService.getRefDataForWorkflowUsersTable().subscribe((data) => {
        this.tasksRefData = data.tasks;
        this.emailAlertsRefData = data.emailAlerts;
      })
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onTaskSelect(t: any, e: any) {
    const i = (this.dataSource as any[]).findIndex((d: any) => {
      return d.first_name == e.first_name && d.last_name == e.last_name;
    });
    if (i > -1) {
      this.dataSource[i].task = t;
      this.dataSource = [...this.dataSource];
    }
  }

  onEmailAlertSelect(e: any, x: any) {
    const i = (this.dataSource as any[]).findIndex((d: any) => {
      return d.first_name == x.first_name && d.last_name == x.last_name;
    });
    if (i > -1) {
      this.dataSource[i].emailAlerts = e;
      this.dataSource = [...this.dataSource];
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
