import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddWorkflowComponent, EventService, EventType } from '../..';
import { EventWorkflowService } from '../../services/event-workflow.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-event-workflow',
  templateUrl: './event-workflow.component.html',
  styleUrls: ['./event-workflow.component.scss'],
})
export class EventWorkflowComponent<
  TEvent extends Event,
  TEventType extends EventType
> implements OnInit, OnDestroy
{
  editWorkflow = false;
  dataSource = [];
  displayedColumns: string[] = [
    'select',
    'workflow-template',
    'template-owner',
    'channel',
    'actions',
  ];

  public subscription$ = new Subscription();
  eventId: any;
  showActions = false;
  selection = new SelectionModel<any>(true, []);

  constructor(
    public ewService: EventWorkflowService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private eventService: EventService<any>
  ) {}

  ngOnInit(): void {
    this.getEventName();
    this.subscription$.add(
      this.ewService.templatesSelected.subscribe((data: any) => {
        this.dataSource = data;
      })
    );
  }

  getEventName() {
    // get the event id from the parent route
    this.subscription$.add(
      this.route.params.subscribe((params) => {
        this.eventId = params['eventId'];

        if (this.eventId !== '0') {
          this.subscription$.add(
            this.eventService
              .getEventDomain(this.eventId)
              .pipe(
                tap((eventDomain) => {
                  this.ewService.eventName = eventDomain?.Detail?.EventName;
                })
              )
              .subscribe()
          );
        }
      })
    );
  }

  openAddTemplateViewDialog() {
    const dialogRef = this.dialog.open(AddWorkflowComponent);
    this.subscription$.add(
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      })
    );
  }

  onTemplatesSelected(selectedTemplates: any) {
    this.dataSource = selectedTemplates;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
    this.handleShowActions();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  selectionToggle(row: any) {
    this.selection.toggle(row);
    this.handleShowActions();
  }

  handleShowActions() {
    if (this.selection.selected.length > 0) {
      this.showActions = true;
    } else {
      this.showActions = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
