import { Component, Inject, OnInit } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { BrandAssetsService, OfferAsset } from 'src/app/modules/standard/v1';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

const log = new Logger('ProductBrandAddAssetComponent');

interface DialogData {
  brandId: string;
}

@Component({
  selector: 'app-product-brand-add-asset',
  templateUrl: './product-brand-add-asset.component.html',
  styleUrls: ['./product-brand-add-asset.component.scss']
})
export class ProductBrandAddAssetComponent implements OnInit {

  viewModel$ = combineLatest([
    this.brandAssetsService.assetsByNotProductBrandId$,
    this.brandAssetsService.isLoading$,
    this.brandAssetsService.assetsByNotProductBrandIdTotalRecords$,
    this.brandAssetsService.dialogPage$,
  ]).pipe(
    map(([assets, isLoading, totalRecords, page]) => {
      return { assets, isLoading, totalRecords, page }
    }),
  );
  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
  assetsList: OfferAsset[] = [];
  selection = new SelectionModel<OfferAsset>(true, []);
  constructor(private mediaObserver: MediaObserver,
    private brandAssetsService: BrandAssetsService<OfferAsset>,
    public dialogRef: MatDialogRef<ProductBrandAddAssetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.brandAssetsService.assetsByNotProductBrandId$.subscribe((data) => {
      this.assetsList = data;
    })
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'AssetName'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select', 'AssetName', 'Type'];
        }
        else {
          this.displayedColumns = ['select', 'AssetName', 'Group', 'DateAdded', 'Type'];
        }
      });
  }

  filtersForm = new FormGroup({
    search: new FormControl(),
  });


  onSort(sortState: Sort): void {
    this.brandAssetsService.sort(sortState);
    this.selection.clear();
  }

  closeDialog() {
    this.dialogRef.close();
    this.clearSearch();
  }

  onSearch(event: any) {
    this.brandAssetsService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.brandAssetsService.dialogSearch('');
  }


  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let assets: any = this.selection.selected.map(x => x.Detail.Id);
      this.brandAssetsService.associateAssetToBrand(assets).subscribe({
        next: () => {
          this.matSnackBar.open(`Assets are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.brandAssetsService.resetpage();
          this.clearSearch();
          this.brandAssetsService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding asset to promo', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.brandAssetsService.dialogPage(pageEvent);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.assetsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.assetsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

}
