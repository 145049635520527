import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Subscription } from 'rxjs';
import { Logger } from 'src/app/@shared';
import { DEFAULT_SNACKBAR_CONFIG } from 'src/app/@shared/constants/site.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { VersionPromoService, Version } from 'src/app/modules/standard/v1';


const log = new Logger('PromoAddVersionsComponent');

interface DialogData {
  eventId: string;
}

@Component({
  selector: 'app-promo-add-versions',
  templateUrl: './promo-add-versions.component.html',
  styleUrls: ['./promo-add-versions.component.scss'],
})

export class PromoAddVersionsComponent implements OnInit {
  filtersForm = new FormGroup({
    search: new FormControl(),
  });
  viewModel$ = combineLatest([
    this.versionPromoService.versionsNotInPromo$,
    this.versionPromoService.versionsNotInPromoisLoading$,
    this.versionPromoService.totalVersionsNotInPromo$,
    this.versionPromoService.versionsNotInPromoPage$
  ]).pipe(
    map(([eventversions, isLoading, totalVersionsNotInPromo, page]) => {
      return { eventversions, isLoading, totalVersionsNotInPromo, page }
    }),
  );

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'VersionName'];
  versionsList: Version[] = [];
  selection = new SelectionModel<Version>(true, []);
  constructor(
    private versionPromoService: VersionPromoService,
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PromoAddVersionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    log.debug('init');
    this.clearSearch();
    this.versionPromoService.eventId = this.data.eventId
    this.versionPromoService.versionsNotInPromoReload();
    this.versionPromoService.versionsNotInPromoReload();
    this.versionPromoService.versionsNotInPromo$.subscribe((data) => {
      this.versionsList = data;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveData() {
    if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
      let versions: string[] = this.selection.selected.map(x => x.Id);
      this.versionPromoService.associateVersionToPromo(versions).subscribe({
        next: () => {
          this.matSnackBar.open(`Versions are added`, 'OK', DEFAULT_SNACKBAR_CONFIG);
          this.versionPromoService.resetpage();
          this.versionPromoService.reload();
          this.dialogRef.close(true);
        },
        error: (error) => {
          log.error('Error adding store to version', error);

          if (error.error.value) {
            throw new Error(error.error.value);
          } else {
            throw new Error(error.message);
          }
        }
      });
    }
  }

  onSearch(event: any) {
    this.versionPromoService.dialogSearch(event.target.value);
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.versionPromoService.dialogSearch('');
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.versionPromoService.versionsNotInPromoPage(pageEvent);
  }

  onSort(sortState: Sort): void {
    this.versionPromoService.sort(sortState);
    this.selection.clear();
  }

  onRowDoubleClick(id: string) {
    this.router.navigate([`${id}/details`], { relativeTo: this.route });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.versionsList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.versionsList.forEach(row => this.selection.select(row));
  }

}
