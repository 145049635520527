import { Component, OnInit } from '@angular/core';
import { Logger } from 'src/app/@shared';
import * as dayjs from 'dayjs';
import { combineLatest, map } from 'rxjs';
import { CloneEventComponent, Event, EventDomain, EventService } from '../..';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';

const log = new Logger('EventListExpansionPanelComponent');

@Component({
  selector: 'app-event-list-expansion-panel',
  templateUrl: './event-list-expansion-panel.component.html',
  styleUrls: ['./event-list-expansion-panel.component.scss']
})

export class EventListExpansionPanelComponent<TEvent extends Event> implements OnInit {

  startDate: Date = dayjs().toDate();

  viewModel$ = combineLatest([
    this.eventService.adWeeks$,
    this.eventService.events$,
    this.eventService.isLoading$,
  ]).pipe(
    map(([adWeeks, events, isLoading]) => {
      return {
        adWeeks,
        events,
        isLoading
      }
    }),
  );
  eventsList: EventDomain<TEvent>[] = [];
  selection = new SelectionModel<EventDomain<TEvent>>(true, []);
  constructor(private eventService: EventService<TEvent>, private dialog: MatDialog) { }

  ngOnInit(): void {

    log.debug('init');
    this.eventService.events$.subscribe((data) => {
      this.eventsList = data;
    })

    this.eventService.page({
      previousPageIndex: 0,
      pageIndex: 0,
      pageSize: 1000,
      length: 0,
    });
  }

  openDialog(record: any): void {
    const dialogRef = this.dialog.open(CloneEventComponent, {
      width: '40%',
      height: '90%',
      data: { eventId:  record.Detail.Id}
    });
  }
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.eventsList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.eventsList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

}
