<div class="event-workflow-container">
  <div
    *ngIf="!editWorkflow"
    fxLayout="row wrap"
    fxLayoutGap.gt-sm="16px"
    fxLayout.lt-md="column"
  >
    <h2>{{ ewService.eventName }}</h2>
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <button
        type="button"
        mat-raised-button
        color="accent"
        matTooltip="New Template"
        (click)="openAddTemplateViewDialog()"
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>New Template
      </button>
    </div>
    <div fxLayout="row wrap" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column">
      <!-- Actions drop down -->
      <button
        mat-raised-button
        [matMenuTriggerFor]="actionsMenu"
        *ngIf="showActions == true"
      >
        <mat-icon>more_vert</mat-icon>
        Actions
      </button>
      <mat-menu class="actions-mat-menu" #actionsMenu="matMenu">
        <button mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>

        <!-- <button mat-menu-item (click)="activateUserToggle()">
              {{
                selectedUser?.selectedUserIds[0]?.isActive == true
                  ? 'De-activate'
                  : 'Activate'
              }}
            </button> -->
        <button mat-menu-item style="margin-top: 2px">
          <span>{{ selection.selected.length }} Selected</span>
        </button>
      </mat-menu>
    </div>

    <div
      id="exisiting-templates"
      fxLayout="row wrap"
      fxLayoutGap.gt-sm="16px"
      fxLayout.lt-md="column"
    >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td class="no-ellipsis" mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectionToggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="workflow-template">
          <th mat-header-cell *matHeaderCellDef>Workflow Template</th>
          <td mat-cell *matCellDef="let element">{{ element.templateName }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="template-owner">
          <th mat-header-cell *matHeaderCellDef>Template Owner</th>
          <td mat-cell *matCellDef="let element">{{ element.user }}</td>
        </ng-container>

        <ng-container matColumnDef="channel">
          <th mat-header-cell *matHeaderCellDef>Channel</th>
          <td mat-cell *matCellDef="let element">{{ element.channel }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <a (click)="editWorkflow = true">Edit</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr>
          <td *ngIf="!dataSource || dataSource.length == 0">
            <p><b>No Templates Added Yet</b></p>
          </td>
        </tr>
      </table>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!dataSource || dataSource.length == 0"
        style="width: 100%; padding-top: 1rem"
      >
        <p><b>No Templates Added Yet</b></p>
      </div>

      <mat-paginator
        style="width: 100%"
        [length]="dataSource.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>

  <app-edit-workflow
    *ngIf="editWorkflow"
    (onClose)="editWorkflow = false"
  ></app-edit-workflow>
</div>
