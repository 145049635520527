<ng-container *ngIf="viewModel$ | async as viewModel">
    <form [formGroup]="filtersForm" novalidate>
        <!-- actions bar -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start start" fxLayoutGap.lt-md="4px" class="actions-bar">
            <h2>Add Products</h2>
            <mat-form-field appearance="outline" class="mat-form-field-no-padding">
                <mat-label>Search Products</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput formControlName="search" type="text" placeholder="Search" (input)="onSearch($event)" #searchInput />
                <button type="button" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchInput?.value" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="grid-container">
        <table mat-table *ngIf="viewModel.notOfferproducts?.length" [dataSource]="viewModel.notOfferproducts" matSort (matSortChange)="onSort($event)" matSortDisableClear [matSortStart]="'desc'" class="mat-elevation-z8">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th class="no-ellipsis" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [disabled]="!viewModel.notOfferproducts.length">
                    </mat-checkbox>
                </th>
                <td class="no-ellipsis" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>


            <!-- ProductName Column -->
            <ng-container matColumnDef="ProductName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
                    Name
                </th>
                <td mat-cell *matCellDef="let record"> {{ record.Detail.ProductName }}</td>
            </ng-container>

            <!-- Category Column -->
            <ng-container matColumnDef="ProductCategoryName">
                <th mat-header-cell *matHeaderCellDef >
                    Category
                </th>
                <td mat-cell *matCellDef="let record">{{ record.ProductCategoryName }}</td>
            </ng-container>

            <!-- Brand Column -->
            <ng-container matColumnDef="ProductBrandName">
                <th mat-header-cell *matHeaderCellDef >
                    Brand
                </th>
                <td mat-cell *matCellDef="let record">{{ record.ProductBrandName }}</td>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">
                    Id
                </th>
                <td mat-cell *matCellDef="let record">{{ record.Detail.Id }}</td>
            </ng-container>

            <!-- ClientKey Column -->
            <ng-container matColumnDef="ClientKey">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ClientKey">
                    Client Key
                </th>
                <td mat-cell *matCellDef="let record">{{ record.Detail.ClientKey }}</td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let record">
                    <button type="button" mat-button color="accent" (click)="onProductEdit(record.Detail.Id)">Edit</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <table *ngIf="searchInput?.value && !viewModel.notOfferproducts?.length">
            <tr>
                <td class="no-records" [attr.colspan]="displayedColumns.length">
                    <h2>No Records Found</h2>
                </td>
            </tr>
        </table>
        </div>
        <!-- pager -->
        <mat-paginator [length]="viewModel.totalProductRecords" [pageSize]="viewModel.page.pageSize"
            [pageSizeOptions]="[5, 10, 20, 50, 100,500]" (page)="onPage($event)"
            *ngIf="searchInput?.value && viewModel.notOfferproducts?.length" showFirstLastButtons [pageIndex]="viewModel.page.pageIndex">
        </mat-paginator>

        <mat-card-actions fxLayout="row" fxLayoutAlign="end">

            <div>
                <button mat-stroked-button type="button" (click)="closeDialog()">
                    Cancel
                </button>
                <button mat-raised-button type="button" color="accent" (click)="saveData()">
                    Select
                </button>
            </div>
        </mat-card-actions>
    </form>
</ng-container>
