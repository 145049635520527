import { Injectable } from '@angular/core';
import { Product, ProductDomain } from "..";
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap, } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizationService } from 'src/app/@shared';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGING } from 'src/app/@shared/constants/site.constants';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})

export class OfferProductService<T extends Product> {

  // initialize behavior subjects
  private productOfferIdBehaviorSubject = new BehaviorSubject<string>('');
  private pageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private notOfferProductPageBehaviorSubject = new BehaviorSubject(DEFAULT_PAGING);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private notOfferProductLoadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  private searchBehaviorSubject = new BehaviorSubject<string>('');
  private dialogSearchBehaviorSubject = new BehaviorSubject<string>('');
  private sortBehaviorSubject = new BehaviorSubject({ active: 'ProductName', direction: 'asc', });
  private productsortBehaviorSubject = new BehaviorSubject({ active: 'ProductName', direction: 'asc', });
  private reloadBehaviorSubject = new BehaviorSubject<string>('');
  private notOfferProductReloadBehaviorSubject = new BehaviorSubject<string>('');
  private viewModeBehaviorSubject = new BehaviorSubject<string>('CARDS');

  // we do not wish to expose our behavior subjects.  create public observables
  public page$ = this.pageBehaviorSubject.asObservable();
  public notOfferProductPage$ = this.pageBehaviorSubject.asObservable();
  public search$ = this.searchBehaviorSubject.asObservable();
  public dialogSearch$ = this.dialogSearchBehaviorSubject.asObservable();
  public sort$ = this.sortBehaviorSubject.asObservable();
  public productsort$ = this.productsortBehaviorSubject.asObservable();
  public isLoading$ = this.loadingBehaviorSubject.asObservable();
  public notOfferProductisLoading$ = this.notOfferProductLoadingBehaviorSubject.asObservable();
  public viewMode$ = this.viewModeBehaviorSubject.asObservable();
  public productOfferId$ = this.productOfferIdBehaviorSubject.asObservable();

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private dialog: MatDialog) { }

  // create the parameters observable that looks for changes in page, startDate, endDate, etc
  public params$ = combineLatest([
    this.productOfferIdBehaviorSubject,
    this.pageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.searchBehaviorSubject.pipe(debounceTime(300)),
    this.reloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([productOfferId, page, sort, search, reload]) => {

      let orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'ProductBrandName' || sort.active == 'ProductCategoryName') {
        orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        $expand: 'Detail',
        offerId: this.productOfferIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: orderby,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  public dialogParams$ = combineLatest([
    this.productOfferIdBehaviorSubject,
    this.notOfferProductPageBehaviorSubject, // add debounce if we need to wait for user input ex: .pipe(debounceTime(300)), 
    this.sortBehaviorSubject,
    this.dialogSearchBehaviorSubject.pipe(debounceTime(300)),
    this.notOfferProductReloadBehaviorSubject,
  ]).pipe(
    distinctUntilChanged((previous, current) => {
      // if the values coming down this pipe are the same, don't continue the pipe
      return JSON.stringify(previous) === JSON.stringify(current);
    }),
    map(([productOfferId, page, sort, search, reload]) => {

      let orderby = `Detail/${sort.active} ${sort.direction}`;
      if (sort.active == 'ProductBrandName' || sort.active == 'ProductCategoryName') {
        orderby = `${sort.active} ${sort.direction}`;
      }

      // set the query string odata parameters
      let params: HttpParams = new HttpParams({
        fromObject: {
        $expand: 'Detail',
        offerId: this.productOfferIdBehaviorSubject.value,
        $skip: page.pageIndex * page.pageSize,
        $top: page.pageSize,
        $orderby: orderby,
        $count: true,
      }
    });

      // if there is a search, add the search to the parameters
      if (search.length) {
        params = params.append('$search', `"${search}"`);
      }

      return params;
    })
  );

  set offerId(productOfferId: string) {
    this.productOfferIdBehaviorSubject.next(productOfferId);
  }

  //get the list of products by offerId
  private offerProductsResponse$ = this.params$.pipe(
    tap(() => this.loadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>
      this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/ByOfferId`,
        { params: _params })
    ),
    tap(() => this.loadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  //get the list of all products (not based on particular OfferId)
  private allProductsOfferResponse$ = this.dialogParams$.pipe(
    tap(() => this.notOfferProductLoadingBehaviorSubject.next(true)), // set isLoading to true
    switchMap((_params) =>{
      if(_params.has('$search')){
      return this.httpClient.get(
        `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/ByNotOfferId`,
        { params: _params })
      } else {
        return of([])
      }
    }),
    tap(() => this.notOfferProductLoadingBehaviorSubject.next(false)), // set isLoading to false
    shareReplay(1) // make sure all subscriptions share the same http call (otherwise there will be a http call for each subscription)
  );

  associateProductToOffer(offerIds : string[], product: ProductDomain<T>) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/AddToOffer?${this.prepareOfferIdString(offerIds)}`;
    return this.httpClient.post(url, product);
  }

  prepareOfferIdString(offerIds: any) {
    let urlParams = '';
    if (offerIds && offerIds.length > 0) {
      for (let index = 0; index <= offerIds.length - 1; index++) {
        urlParams += `offerIds=${offerIds[index]}`;
        if (index != offerIds.length - 1) {
          urlParams += '&'
        }
      }
    }
    return urlParams;
  }

  disassociateProductToOffer(offerIds:string[],  product: any) {
    let url = `${environment.pr1ApiUrl}/${this.organizationService.organization?.apiPath}/${this.organizationService.organization?.version}/ProductDomains/RemoveEdgeOfferHasProducts?${this.prepareOfferIdString(offerIds)}`;
    return this.httpClient.post(url, product);
  }

  //products listing by offerId
  public products$: Observable<ProductDomain<T>[]> = this.offerProductsResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

  public notOfferProduct$: Observable<ProductDomain<T>[]> = this.allProductsOfferResponse$.pipe(
    map((res: any) => res ? res.value : [])
  );

  public totalRecords$: Observable<number> = this.offerProductsResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );

  public totalProductRecords$: Observable<number> = this.allProductsOfferResponse$.pipe(
    map((res: any) => res ? res['@odata.count'] : 0)
  );
  // set the current page
  page(page: any) {
    this.pageBehaviorSubject.next(page);
  }
  // set the current page
  notOfferProductPage(page: any) {
    this.notOfferProductPageBehaviorSubject.next(page);
  }
  // reset the current page
  resetpage() {
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }
  // reset the current page
  notOfferProductResetpage() {
    this.notOfferProductPageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the sort property and order
  sort(sort: any) {
    this.sortBehaviorSubject.next(sort);
  }

  // sets the search phrase
  search(search: string) {
    this.searchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // sets the search phrase
  dialogSearch(search: string) {
    this.dialogSearchBehaviorSubject.next(search);
    this.pageBehaviorSubject.next(DEFAULT_PAGING);
  }

  // reloads/refreshes the offer listing
  reload() {
    // reload the Offer data
    this.reloadBehaviorSubject.next(uuidv4());
  }
  // reloads/refreshes the offer listing
  notOfferProductReload() {
    // reload the Offer data
    this.notOfferProductReloadBehaviorSubject.next(uuidv4());
  }

  // changes the view mode of the products listing
  toggleViewMode(mode: string) {
    this.viewModeBehaviorSubject.next(mode);
  }

}
