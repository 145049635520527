import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, distinctUntilChanged, map, Observable, startWith, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ConfirmDialogComponent, DEFAULT_SNACKBAR_CONFIG, Logger } from 'src/app/@shared';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Store, StoreService } from '../..';
import { MatSidenav } from '@angular/material/sidenav';
import { Filter } from 'src/app/@shared/models/filter.model';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const log = new Logger('StoresComponent');

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})

export class StoresComponent<T extends Store> implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  viewModel$ = combineLatest([
    this.storeService.stores$,
    this.storeService.isLoading$,
    this.storeService.totalRecords$,
    this.storeService.page$,
    this.storeService.cities$,
    this.storeService.states$,
    this.storeService.filters$
  ]).pipe(
    map(([stores, isLoading, totalRecords, page, cities, states, filters]) => {
      if (stores.length == 0 && page.pageIndex > 0) {
        page.previousPageIndex = 0
        page.pageIndex = 0
        this.storeService.page(page);
        this.storeService.reload();
      }
      this.cityNames = cities;
      return { stores, isLoading, totalRecords, page, cities, states, filters }
    }),
  );

  filtersForm = new FormGroup({
    search: new FormControl<string | null>(null),
    state: new FormControl<string>(''),
    city: new FormControl<string>(''),
  });

  flexMediaWatcher!: Subscription;
  displayedColumns = ['select', 'StoreName', 'Address1', 'City', 'State', 'PostalCode', 'ClientKey', 'Actions'];
  cityNames: string[] = [];
  filteredCityNames$: Observable<string[]> | undefined;
  storeList: Store[] = [];
  selection = new SelectionModel<Store>(true, []);


  constructor(private storeService: StoreService<T>, private mediaObserver: MediaObserver,private dialog: MatDialog,
    private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
    log.debug('init');
    this.storeService.stores$.subscribe((data) => {
      this.storeList = data;
    })
    this.clearSearch();
    this.storeService.reload();

    this.filtersForm.controls.search.setValue(this.storeService.search);

    this.filteredCityNames$ = this.filtersForm.controls.city.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCityNames(value ? value : '')),
    );

    // detect changes in viewport size to handle show/hide of table columns
    const getAlias = (MediaChange: MediaChange[]) => {
      return MediaChange[0].mqAlias;
    };

    this.flexMediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
        ))
      .subscribe((change) => {
        if (change.some(x => x.mqAlias === 'xs')) {
          this.displayedColumns = ['select', 'StoreName', 'Actions'];
        }
        else if (change.some(x => x.mqAlias === 'sm')) {
          this.displayedColumns = ['select','StoreName', 'ClientKey', 'City', 'State', 'Actions'];
        }
        else {
          this.displayedColumns = ['select', 'StoreName', 'ClientKey', 'Address1', 'City', 'State', 'PostalCode', 'Actions'];
        }
      });
  }

  onSearch(event: any) {
    this.storeService.search = event.target.value;
  }

  clearSearch() {
    this.filtersForm.controls.search.setValue('');
    this.storeService.search = '';
  }

  onFilterChange(event: any) {
    switch (event.source.ngControl.name.toLowerCase()) {
      case 'state':
        // get the selected states 
        const statesValues: string[] = event.value;
        //remove any existing states filter  
        this.storeService.removeFilterByFieldName('states');
        // init the array of states filters
        const statesFilters: Filter[] = [];
        // loop through the selected states and add each as a filter
        statesValues.forEach(selection => {
          // init the new filter
          let state = selection ? selection : '';
          const statesFilter: Filter = { displayText: 'State', fieldName: 'states', value: state };
          // add the filter to the list of filters
          statesFilters.push(statesFilter)
        });
        // add the filters to the event service
        this.storeService.addFilters(statesFilters);
        break;
    }
  }

  resetFilters() {
    this.filtersForm.reset();
    this.storeService.search = '';
    this.storeService.clearFilters();
    this.sidenav.toggle();
  }

  
  removeFilter(filter: Filter) {
    this.storeService.removeFilter(filter);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.storeService.clearFilters();
  }

  onSort(sortState: Sort): void {
    this.storeService.sort(sortState);
    this.selection.clear();
  }

  onPage(pageEvent: PageEvent): void {
    this.selection.clear();
    this.storeService.page(pageEvent);
  }

  onCityChange(event: MatOptionSelectionChange, city: string): void {
    // remove any existing cities id filters
    event.source.selected && this.storeService.removeFilterByFieldName('cities');

    if (event.source.selected && city.length > 0) {
      // init the array of cities filters
      const citiesFilters: Filter[] = [];
      // init the city filter
      const citiesFilter: Filter = { displayText: 'City', fieldName: 'cities', value: city };
      // add the filter to the list of filters
      citiesFilters.push(citiesFilter)
      // add the filters to the event service
      this.storeService.addFilters(citiesFilters);
    }
  }

  filterCityNames(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.cityNames.filter(option => option.toLowerCase().includes(filterValue));
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.storeList.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.storeList.forEach(row => this.selection.select(row));
  }

  public getSelectedSectionRecords() {
    return this.selection.selected;
  }

  public clearSelection() {
    this.selection.clear();
  }

  deleteSelectedRecords() {
    let selectedRecords: any;   
    selectedRecords = this.getSelectedSectionRecords();    
    if (selectedRecords && selectedRecords.length > 0) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Delete',
          message: `Do you want to remove Store(s)?`,
        },
        disableClose: true,
      });

      confirmDialog.afterClosed().subscribe(
        confirmResult => {
          if (confirmResult) {
            this.storeService.deleteStores(selectedRecords).subscribe({
              next: () => {
                this.matSnackBar.open(`Store(s) deleted`, 'OK', DEFAULT_SNACKBAR_CONFIG);
                this.storeService.reload();               
                  this.clearSelection();                
              },
              error: (error) => {
                log.error('Error in deleting Store', error);

                if (error.error.value) {
                  throw new Error(error.error.value);
                } else {
                  throw new Error(error.message);
                }
              }
            });
          }
        });
    }
  }

  downloadJSON(){
    this.storeService.downloadStoresJSON();
  }

  downloadCSV(){
    this.storeService.downloadStoresCSV();
  }
}
